import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { ApiService } from '../../shared/service/api.service';
import { ProductimageService } from '../../shared/service/fileupload.service';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {
  sellerdata: any;
  admindata: any;
  subscriptiontype: string;
  pricingPlans: [any];
  messageColor: String = "green"
  message: String = ''

  constructor(private apiService: ApiService, private router: Router, private imageuploadService: ProductimageService) {
    this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"))
    this.admindata = JSON.parse(window.localStorage.getItem("admin-info"))
  }

  ngOnInit(): void {
    this.getPricingPlans()
  }

  getPricingPlans() {
    this.apiService.getPricingPlans().subscribe((res: any) => {
      this.pricingPlans = res.plans
    })
  }

  async getsubscription(data) {

    if (this.sellerdata == null || this.sellerdata == undefined) {
      this.router.navigateByUrl("/auth/login")
    }

    const plan = data.title;
    let price = data.amount;
    const description = `${data.title} plan has been selected by the User`;
    const type = `1 month ${plan} plan`;
    const email = this.sellerdata[0].email
    const manufacturer = this.sellerdata[0].manufacturer
    const startDate = new Date();
    let endDate = new Date(startDate);
    endDate.setMonth(endDate.getMonth() + 1);
    if (endDate.getDate() < startDate.getDate()) {
      endDate.setDate(0); // Set to the last day of the previous month
    }
    if (price === 0) {

      const subscriptionPayload = {
        plan: plan,
        price: price,
        description: `${plan} Plan`,
        type,
        email,
        startDate,
        endDate,
        app_name: "Jesssu Seller Central",
        paymentStatus: true,
        manufacturer
      };
      const addSubscriptionData: any = await this.apiService.addSubscription(subscriptionPayload).toPromise();
      this.messageColor = 'green'
      this.message = `${plan} plan activated successsfully. Redirecting to dashboard.`
      localStorage.setItem("SUBSCRIPTIONPLAN", JSON.stringify(plan))
      setTimeout(() => {
        this.messageColor = ''
        this.message = ``
        this.router.navigateByUrl('/dashboard/default');
      }, 2000);

    } else {
      try {
        const razorpayPayload = {
          amount: price * 100, // Amount in paisa
          description: description
        };

        const razorpayData: any = await this.apiService.createRazorpayOrder(razorpayPayload).toPromise();

        if (razorpayData.orderId) {
          const options = {
            amount: price * 100, // Amount in paisa
            currency: 'INR',
            name: 'eDgeWrapper',
            description: description,
            order_id: razorpayData.orderId,
            handler: async (response) => {
              const paymentStatus = true;
              const subscriptionPayload = {
                plan,
                price,
                description,
                type,
                email,
                startDate,
                endDate,
                app_name: "Jesssu Seller Central",
                paymentStatus,
                manufacturer
              };

              try {
                const addSubscriptionData: any = await this.apiService.addSubscription(subscriptionPayload).toPromise();
                if (addSubscriptionData.success) {
                  const paymentPayload = {
                    plan: addSubscriptionData.subscription.plan,
                    price: addSubscriptionData.subscription.price,
                    email: addSubscriptionData.subscription.email,
                    app_name: addSubscriptionData.subscription.app_name,
                    razorpay_payment_id: response.razorpay_payment_id,
                    razorpay_order_id: response.razorpay_order_id,
                    razorpay_signature: response.razorpay_signature
                  };

                  const addData: any = await this.apiService.addPayment(paymentPayload).toPromise();
                  if (addData.success) {
                    this.messageColor = 'green'
                    this.message = `${plan} plan activated successsfully. Redirecting to dashboard.`
                    localStorage.setItem("SUBSCRIPTIONPLAN", JSON.stringify(plan))
                    setTimeout(() => {
                      this.messageColor = ''
                      this.message = ``
                      window.location.href = '/dashboard/default';
                    }, 2000);
                  }
                } else {
                  throw new Error('Failed to save subscription data');
                }
              } catch (error) {
                console.error('Error while saving subscription data:', error);
                this.messageColor = 'red'
                this.message = `Error while saving subscription data. Please try again.`
              }
            },
            modal: {
              ondismiss: function () {
                this.messageColor = 'red'
                this.message = `Payment canceled.`
              }
            }
          };

          const rzp = new Razorpay(options);
          rzp.open(); // Open the Razorpay payment modal
        } else {
          throw new Error('Failed to create Razorpay order');
        }
      } catch (error) {
        console.error('API request error:', error);
      }
    }
  }

}
