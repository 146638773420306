import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from 'src/app/shared/service/api.service';
import { ProductimageService } from 'src/app/shared/service/fileupload.service';

@Component({
  selector: 'app-productimport',
  templateUrl: './productimport.component.html',
  styleUrls: ['./productimport.component.scss']
})
export class ProductimportComponent implements OnInit {

  [x: string]: any;
  public payload: {};
  public channelname: String = "No Channel Selected";
  public brandSelectionForm: FormGroup;
  public brand: "All";
  public productmaindata: any;
  public productvaritationdata: any;
  public FileUploadStatus: any;
  public currentFileUpload: any;
  public selectedFiles: any
  public res: any;
  public loading = true;
  public button = false;
  public loadingfordataimport = true;
  public blankproductstatuspopup: any = false;
  public varitationdata = true;
  public admindata: any;
  public sellerdata: any;
  public offset: number = 0;
  public count: number = 10;
  public uploaddetailsdata = [];
  public isLoading = false
  public error = false;
  public errortext = ""
  public productstatuspopupsucess: Boolean = false;
  public brandstatuspopupsucess: Boolean = false;
  public chanelstatuspopupsuccess: Boolean = false;
  public idforproductstatuspopup: any;


  channels = [
    { id: "Amazon", name: "Amazon" }
  ];

  constructor(private http: HttpClient, private apiService: ApiService, public fb: FormBuilder, public productimageservice: ProductimageService, public router: Router) {
    this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"))

    this.token = JSON.parse(window.localStorage.getItem("TOKEN"))
    this.payload = {
      channel: null,
    }
  }

  ngOnInit() {
    this.admindata = JSON.parse(window.localStorage.getItem("admin-info"));
    this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"))
    this.getproductuploaddetailsall()
    this.channels[0].id = this.sellerdata[0].manufacturer
    this.channels[0].name = this.sellerdata[0].manufacturer;
    this.getsaledata()
  }

  selectFile(event: any) {
    if (event == null) {

      // this.error = true
      // this.errortext = "Please upload file"
      // alert("Please upload file")
    } else {
      this.selectedFiles = event.target.files;
    }
  }

  getsaledata() {
    const payload = {
      manufacturer: this.sellerdata[0].manufacturer
    }
    this.apiService.getsaledataallforexport(payload).subscribe((res: any[]) => {
      const saledata = []
      res.forEach(i => {
        const newdata = {
          invoiceno: i.invoiceno,
          type: i.type,
          customer: i.customer.customername,
          orderno: i.orderno,
          courierno: i.courierno,
          channel: i.channel,
          item_sku: i.products[0].item_sku,
          item_name: i.products[0].item_name,
          invoice_date: i.invoice_date,
          paymentamount: i.payment.supplieramount,
          paymentdate: i.payment.supplieramount.date,
          mode: i.payment.mode
        }
        saledata.push(newdata)
      })
      localStorage.setItem("Saledata", JSON.stringify(saledata));
    })
  }

  onScroll1() {
    this.isLoading = true;
    this.offset++;
    this.getproductuploaddetailsall()
  }

  getproductuploaddetailsall() {
    this.apiService.getuploadproductdetails(this.offset, this.count).subscribe((res: any[]) => {
      if (this.uploaddetailsdata.length > 0) {
        this.uploaddetailsdata = this.uploaddetailsdata.concat(res)
        this.isLoading = false;
        console.log(this.uploaddetailsdata)
      }
      else {
        this.uploaddetailsdata = res
        this.isLoading = false;
        console.log(this.uploaddetailsdata)
      }
    });
  }

  onSelectedFile(event) {
    this.selectedFile = <File>event.target.files[0]
  }

  onblankproductstatuspopupsucess() {
    this.blankproductstatuspopup = false;
  }

  upload() {

    this.brandSelectionForm
    this.brand
    if (this.brand == "All" || this.brand == null) {
      this.chanelstatuspopup = true
      // alert("Please select a channel for brand sync for your product's")

    }
    else {

      this.currentFileUpload = this.selectedFile;
      if (this.currentFileUpload == undefined) {
        //popup for empty file
        this.blankproductstatuspopup = true;
        this.loadingfordataimport = true;

      }
      else {
        console.log(this.currentFileUpload);

        this.varitationdata = false
        this.loadingfordataimport = false

        const wait = function (ms) {
          var start = new Date().getTime();
          var end = start;
          while (end < start + ms) {
            end = new Date().getTime();
          }
        }
        console.log(this.sellerdata)
        const email = this.sellerdata[0].email
        const token = this.token
        this.productimageservice.ImportProduct(this.currentFileUpload, email, token).subscribe(res => {
          wait(1000)
          this.productstatuspopup = true
          this.idforproductstatuspopup = null
          // alert("Products uploaded successfully");
          // this.brandsync(this.brand);

        });
      }
    }
  }
  onproductstatuspopupsucess() {
    this.productstatuspopup = false
    this.idforproductstatuspopup = null
    this.brandsync(this.brand);

  }


  brandsync(brandSelectionForm) {
    var obj =
    {
      brand: brandSelectionForm
    }
    this.apiService.brandSync(obj)
      .subscribe((res: any) => {
        this.brandstatuspopup = true
        // alert("Brands Sync successfully");
        // this.loadingfordataimport = true
        // location.reload()
      });
  }

  productCount(brandSelectionForm) {
    this.brand = brandSelectionForm.value.channel;
    var payload =
    {
      brand: this.brand
    }
  }

  getdata(brandSelectionForm) {
    this.brand = brandSelectionForm;
    if (this.brand == "All" || this.brand == null) {
      this.chanelstatuspopupsuccess = true
      // this.error = true
      // this.errortext = "Please select a channel"
      // alert("Please select a channel")
      this.button = true
    }
    else {
      this.loading = false;
      this.button = false;
      this.getexportdata(brandSelectionForm);
      // this.getvariationdata(brandSelectionForm)
    }
  }

  getexportdata(brandSelectionForm) {
    localStorage.removeItem("MainData")
    const sellertoken = JSON.parse(window.localStorage.getItem("TOKEN"));
    this.brand = brandSelectionForm;
    var obj =
    {
      brand: this.brand,
      token: sellertoken
    }
    this.apiService.getproductmaindata(obj)
      .subscribe((res: any) => {
        this.productmaindata = res;
        localStorage.setItem("MainData", JSON.stringify(this.productmaindata));
        this.loading = true;
        this.button = true;
      });
  }

  getvariationdata(brandSelectionForm) {
    localStorage.removeItem("Data2")
    this.brand = brandSelectionForm
    var obj =
    {
      brand: this.brand,
      email: this.sellerdata[0].email,
      token: this.sellerdata0[0].token

    }
    this.apiService.getproductvariationdata(obj)
      .subscribe((res: any) => {
        this.productvaritationdata = res;
        localStorage.setItem("Data2", JSON.stringify(this.productvaritationdata));
      });
  }



  exportforflipkart() {

    const objectToCsv = function (data) {
      const csvRows = [];
      const headers = Object.keys(data[0]);
      csvRows.push(headers.join(','));
      for (const row of data) {
        const values = headers.map(header => {
          const val = row[header];
          const escaped = ('' + row[header]).replace(/"/g, '\\"');
          return `"${escaped}"`;
        });
        csvRows.push(values.join(','));
      }
      return csvRows.join('\n');
    };
    const download = function (data) {
      const blob = new Blob([data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('hidden', '');
      a.setAttribute('href', url);
      a.setAttribute('download', 'flipkart.csv');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };
    const getReport = async function () {
      const res = JSON.parse(window.localStorage.getItem("MainData"));
      if (res.length > 1) {
        const json = res;
        const data = json.map(row => ({
          "Seller SKU ID": row.SKU,
          "Listing ID": "",
          "Reason of Deactivation": "",
          "Listing Status": "",
          "MRP (INR)": row.RetailPrice,
          "Your selling price (INR)": row.SalePrice,
          "Fullfilment by": row.Manufacturer,
          "Procurement type": "",
          "Procurement SLA (DAY)": "",
          "Stock": row.number_of_items,
          "Local delivery charge (INR)": "",
          "Zonal delivery charge (INR)": "",
          "National delivery charge (INR)": "",
          "Package Weight (KG)": "",
          "Package Length (CM)": "",
          "Package Breadth (CM)": "",
          "Package Height (CM)": "",
          "HSN": "",
          "Luxury Cess": "",
          "Country of Origin": row.COO,
          "Manufacturer Details": row.Manufacturer,
          "Packer Details": row.Manufacturer,
          "Importer Details": row.Manufacturer,
          "Tax Code": "",
          "Brand": row.Brand,
          "Size": row.Size,
          "Size - Measuring Unit": "",
          "Style Code": "",
          "Occasion": "",
          "Fabric": "",
          "Pattern": "",
          "Type": "",
          "Sari Purity": "",
          "Color": row.Color,
          "Brand Color": "",
          "Ideal For": "",
          "Pack of": "",
          "Blouse Piece Type": "",
          "Fabric Care": "",
          "Sari Length": "",
          "Blouse Piece Length (m)": "",
          "Sari Style": "",
          "Main Image URL": row.MainImage,
          "Other Image URL 1": row.other_image_url2,
          "Other Image URL 2": row.other_image_url3,
          "Other Image URL 3": row.other_image_url4,
          "Other Image URL 4": row.other_image_url5,
          "Other Image URL 5": row.other_image_url6,
          "Other Image URL 6": row.other_image_url7,
          "Other Image URL 7": "",
          "Main Palette Image URL": row.MainImage,
          "Group ID": "",
          "EAN/UPC": "",
          "EAN/UPC - Measuring Unit": "",
          "Weight (kg)": "",
          "Other Details": "",
          "Sales Package": "",
          "Description": row.Long,
          "Search Keywords": "",
          "Video URL": "",
          "Domestic Warranty": "",
          "Domestic Warranty - Measuring Unit": "",
          "International Warranty": "",
          "International Warranty - Measuring Unit": "",
          "Warranty Service Type": "",
          "Covered in Warranty": "",
          "Not Covered in Warranty": "",
          "Uniform": "",
          "Product Title": row.Name,
          "Trend": "",
          "Construction Type": "",
          "Handloom Product": "",
          "Hand Embroidery": "",
          "Pattern/Print Type": "",
          "Border Length": "",
          "Border Details": "",
          "Blouse Pattern": "",
          "Decorative Material": "",
          "Blouse Fabric": "",
          "Type of Embroidery": "",
          "Embroidery Method": "",
          "Secondary Color": "",
          "Trend AW 16": ""
        }))

        console.log(data);

        const csvData = objectToCsv(data)
        download(csvData);
      }
      else {
        this.error = true
        this.errortext = "No data found";
        // alert("No data found")
      }
    };
    //main app
    (function () {
      //button click
      const button = document.getElementById('flipkart');
      button.addEventListener('click', getReport)
    })();
  }

  exportforamazon() {
    const objectToCsv = function (data) {
      const csvRows = [];
      const headers = Object.keys(data[0]);
      csvRows.push(headers.join(','));
      for (const row of data) {
        const values = headers.map(header => {
          const val = row[header];
          const escaped = ('' + row[header]).replace(/"/g, '\\"');
          return `"${escaped}"`;
        });
        csvRows.push(values.join(','));
      }
      return csvRows.join('\n');
    };
    const download = function (data) {
      const blob = new Blob([data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('hidden', '');
      a.setAttribute('href', url);
      a.setAttribute('download', 'amazon.csv');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };
    const getReport = async function () {
      const res = JSON.parse(window.localStorage.getItem("MainData"));
      if (res.length > 1) {
        const json = res;
        const data = json.map(row => ({

          "feed_product_type": "",
          "item_sku": row.SKU,
          "brand_name": row.Brand,
          "part_number": "",
          "external_product_id": "",
          "external_product_id_type": "",
          "item_name": row.Name,
          "recommended_browse_nodes": "",
          "care_instructions": "",
          "design_name": "",
          "occasion_type1": "",
          "occasion_type2": "",
          "occasion_type3": "",
          "occasion_type4": "",
          "occasion_type5": "",
          "color_map": row.Color,
          "pattern_type": "",
          "material_type": "",
          "color_name": row.Color,
          "weave_type": "",
          "department_name": row.Primary,
          "included_components": "",
          "item_length_description": "",
          "country_of_origin": row.COO,
          "fabric_type": "",
          "external_product_information": "",
          "standard_price": row.SalePrice,
          "quantity": row.number_of_items,
          "condition_type": "New",
          "maximum_retail_price": row.RetailPrice,
          "main_image_url": row.ProductImage,
          "age_range_description": "",
          "target_gender": row.Gender,
          "other_image_url1": row.other_image_url2,
          "other_image_url2": row.other_image_url3,
          "other_image_url3": row.other_image_url4,
          "other_image_url4": row.other_image_url5,
          "other_image_url5": row.other_image_url6,
          "other_image_url6": row.other_image_url7,
          "other_image_url7": row.other_image_url8,
          "other_image_url8": "",
          "swatch_image_url": "",
          "parent_child": "",
          "parent_sku": row.UPC,
          "relationship_type": "",
          "variation_theme": "",
          "update_delete": "",
          "product_description": "",
          "manufacturer": row.Manufacturer,
          "closure_type": "",
          "model_name": "",
          "model": "",
          "bullet_point1": "",
          "bullet_point2": "",
          "bullet_point3": "",
          "bullet_point4": "",
          "bullet_point5": "",
          "generic_keywords": "",
          "style_name": "",
          "is_autographed": "",
          "packer_contact_information": "",
          "item_type_name": "",
          "duration_unit_of_measure": "",
          "sport_type1": "",
          "sport_type2": "",
          "subject_character": "",
          "seasons": "",
          "athlete": "",
          "team_name": "",
          "collection_name": "",
          "importer_contact_information": "",
          "duration": "",
          "lifestyle": "",
          "manufacturer_contact_information": "",
          "fur_description": "",
          "fit_type": "",
          "theme": "",
          "league_name": "",
          "special_size_type": "",
          "sleeve_type": "",
          "size_name": "",
          "lifecycle_supply_type": "",
          "item_booking_date": "",
          "sub_brand_name": "",
          "fabric_wash": "",
          "item_width_unit_of_measure": "",
          "item_width": row.breadth,
          "item_height": row.height,
          "unit_count": row.number_of_items,
          "item_height_unit_of_measure": "",
          "item_length_unit_of_measure": "",
          "item_length": row.length,
          "unit_count_type": "",
          "fulfillment_center_id": "",
          "package_weight": "",
          "package_weight_unit_of_measure": "",
          "package_height": "",
          "package_height_unit_of_measure": "",
          "package_length": "",
          "package_length_unit_of_measure": "",
          "package_width": "",
          "package_width_unit_of_measure": "",
          "item_weight": "",
          "item_weight_unit_of_measure": "",
          "batteries_required": "",
          "are_batteries_included": "",
          "battery_cell_composition": "",
          "battery_type1": "",
          "battery_type2": "",
          "battery_type3": "",
          "number_of_batteries1": "",
          "number_of_batteries2": "",
          "number_of_batteries3": "",
          "battery_weight": "",
          "battery_weight_unit_of_measure": "",
          "number_of_lithium_metal_cells": "",
          "number_of_lithium_ion_cells": "",
          "lithium_battery_packaging": "",
          "lithium_battery_energy_content": "",
          "lithium_battery_energy_content_unit_of_measure": "",
          "lithium_battery_weight": "",
          "lithium_battery_weight_unit_of_measure": "",
          "supplier_declared_dg_hz_regulation1": "",
          "supplier_declared_dg_hz_regulation2": "",
          "supplier_declared_dg_hz_regulation3": "",
          "supplier_declared_dg_hz_regulation4": "",
          "supplier_declared_dg_hz_regulation5": "",
          "hazmat_united_nations_regulatory_id": "",
          "safety_data_sheet_url": "",
          "item_volume": "",
          "item_volume_unit_of_measure": "",
          "flash_point": "",
          "supplier_declared_material_regulation1": "",
          "supplier_declared_material_regulation2": "",
          "supplier_declared_material_regulation3": "",
          "ghs_classification_class1": "",
          "ghs_classification_class2": "",
          "ghs_classification_class3": "",
          "legal_disclaimer_description": "",
          "safety_warning": "",
          "condition_note": "",
          "fulfillment_latency": "",
          "merchant_release_date": "",
          "restock_date": "",
          "product_tax_code": "",
          "sale_price": "",
          "sale_from_date": "",
          "sale_end_date": "",
          "max_order_quantity": "",
          "number_of_items": "",
          "offering_can_be_gift_messaged": "",
          "offering_can_be_giftwrapped": "",
          "map_price": "",
          "list_price_with_tax": "",
          "currency": "",
          "offering_end_date": "",
          "merchant_shipping_group_name": "",
          "offering_start_date": "",
          "business_price": "",
          "quantity_price_type": "",
          "quantity_price1": "",
          "quantity_lower_bound1": "",
          "quantity_price2": "",
          "quantity_lower_bound2": "",
          "quantity_price3": "",
          "quantity_lower_bound3": "",
          "quantity_price4": "",
          "quantity_lower_bound4": "",
          "quantity_price5": "",
          "quantity_lower_bound5": "",
          "pricing_action": "",
          "unspsc_code": "",
          "national_stock_number": "",
        }))

        console.log(data);

        const csvData = objectToCsv(data)
        download(csvData);
      }
      else {
        this.error = true
        this.errortext = "No data found";
        // alert("No data found")
      }
    };
    //main app
    (function () {
      //button click
      const button = document.getElementById('amazon');
      button.addEventListener('click', getReport)
    })();
  }

  exportformeesho() {
    // 
    const objectToCsv = function (data) {
      const csvRows = [];
      const headers = Object.keys(data[0]);
      csvRows.push(headers.join(','));
      for (const row of data) {
        const values = headers.map(header => {
          const val = row[header];
          const escaped = ('' + row[header]).replace(/"/g, '\\"');
          return `"${escaped}"`;
        });
        csvRows.push(values.join(','));
      }
      return csvRows.join('\n');
    };
    const download = function (data) {
      const blob = new Blob([data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('hidden', '');
      a.setAttribute('href', url);
      a.setAttribute('download', 'meesho.csv');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };
    const getReport = async function () {
      const res = JSON.parse(window.localStorage.getItem("MainData"));
      if (res.length > 1) {
        const json = res;
        const data = json.map(row => ({
          "Product Name": row.Name,
          "Variation": "",
          "Meesho Price": row.SalePrice,
          "Wrong/Defective Returns Price": "",
          "MRP": row.RetailPrice,
          "GST%": "",
          "HSN ID": "",
          "Net Weight (gms)": row.weight,
          "Inventory": row.number_of_items,
          "Country of Origin": row.COO,
          "Manufacturer Details": row.Manufacturer,
          "Packer Details": row.Manufacturer,
          "Blouse": "",
          "Blouse Fabric": "",
          "Border": "",
          "Color": row.Color,
          "Net Quantity (N)": row.number_of_items,
          "Saree Fabric": "",
          "Transparency": "",
          "Type": "",
          "Image 1 (Front)": row.ProductImage,
          "Image 2": row.other_image_url2,
          "Image 3": row.other_image_url3,
          "Image 4": row.other_image_url4,
          "Product ID / Style ID": "",
          "SKU ID": row.SKU,
          "Brand Name": row.Brand,
          "Group ID": "",
          "Product Description": row.Long,
          "Importer Details": "",
          "Blouse Color": "",
          "Blouse Pattern": "",
          "Border Width": "",
          "Brand": row.Brand,
          "Loom Type": "",
          "Occasion": "",
          "Ornamentation": "",
          "Pallu Details": "",
          "Pattern": "",
          "Print or Pattern Type": "",
          "Blouse Length Size": "",
          "Saree Length Size": ""
        }))

        console.log(data);

        const csvData = objectToCsv(data)
        download(csvData);
      }
      else {
        this.error = true
        this.errortext = "No data found";
        // alert("No data found")
      }
    };
    //main app
    (function () {

      //button click
      const button = document.getElementById('meesho');
      button.addEventListener('click', getReport)
    })();
  }

  exportforjesssu() {
    // 
    const objectToCsv = function (data) {
      const csvRows = [];
      const headers = Object.keys(data[0]);
      csvRows.push(headers.join(','));
      for (const row of data) {
        const values = headers.map(header => {
          const val = row[header];
          const escaped = ('' + row[header]).replace(/"/g, '\\"');
          return `"${escaped}"`;
        });
        csvRows.push(values.join(','));
      }
      return csvRows.join('\n');
    };
    const download = function (data) {
      const blob = new Blob([data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('hidden', '');
      a.setAttribute('href', url);
      a.setAttribute('download', 'jesssu.csv');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };
    const getReport = async function () {
      const res = JSON.parse(window.localStorage.getItem("MainData"));
      this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"))
      if (res.length > 1) {
        const json = res;
        const data = json.map(row => ({

          "Name": row.Name,
          "SKU": row.SKU,
          "Manufacturer": row.Manufacturer,
          "Brand": row.Brand,
          "Keywords": row.Keywords,
          "UPC": row.UPC,
          "M1": row.M1,
          "Gender": row.Gender,
          "Primary": row.Primary,
          "Secondary": row.Secondary,
          "ProductImage": row.ProductImage,
          "Short": row.Short,
          "PriceCurrency": row.PriceCurrency,
          "SalePrice": row.SalePrice,
          "RetailPrice": row.RetailPrice,
          "Availability": row.Availability,
          "Size": row.Size,
          "Color": row.Color,
          "COO": row.COO,
          "MainImage": row.MainImage,
          "PartNumber": row.PartNumber,
          "Secondary1": row.Secondary1,
          "Long": row.Long,
          "DiscountCurrency": row.DiscountCurrency,
          "DiscountType": row.DiscountType,
          "ProductCurrency": row.ProductCurrency,
          "ShippingCurrency": row.ShippingCurrency,
          "ShippingAmount": row.ShippingAmount,
          "ClassId": row.ClassId,
          "Misc": row.Misc,
          "ProductType": row.ProductType,
          "other_image_url1": row.other_image_url1,
          "other_image_url2": row.other_image_url2,
          "other_image_url3": row.other_image_url3,
          "other_image_url4": row.other_image_url4,
          "other_image_url5": row.other_image_url5,
          "other_image_url6": row.other_image_url6,
          "other_image_url7": row.other_image_url7,
          "status": row.status,
          "number_of_items": row.number_of_items,
          "positionplace": row.positionplace,
          "Shopifyproductid": row.Shopifyproductid,
          "Shopifyproductvarientid": row.Shopifyproductvarientid,
          "userid": this.sellerdata[0]._id,
          "firstname": this.sellerdata[0].firstname,
          "lastname": this.sellerdata[0].lastname,
          "Barcode": row.Barcode,
          "height": row.height,
          "breadth": row.breadth,
          "material_type": row.material_type,
          "weave_type": row.weave_type,
          "length": row.length,
          "item_weight": row.item_weight,
          "design_name": row.design_name,
          "raw_main_image_url": row.raw_main_image_url,
          "raw_other_image_url": row.raw_other_image_url,
          "condition_type": row.condition_type,
          "variation_theme": row.variation_theme,
          "safely_warning": row.safely_warning


        }))

        console.log(data);

        const csvData = objectToCsv(data)
        download(csvData);
      }
      else {
        this.error = true
        this.errortext = "No data found";
        // alert("No data found")
      }
    };
    //main app
    (function () {

      //button click
      const button = document.getElementById('jesssu');
      button.addEventListener('click', getReport)
    })();
  }

  exportforsaledata() {
    const objectToCsv = function (data) {
      const csvRows = [];
      const headers = Object.keys(data[0]);
      csvRows.push(headers.join(','));
      for (const row of data) {
        const values = headers.map(header => {
          const val = row[header];
          const escaped = ('' + row[header]).replace(/"/g, '\\"');
          return `"${escaped}"`;
        });
        csvRows.push(values.join(','));
      }
      return csvRows.join('\n');
    };
    const download = function (data) {
      const blob = new Blob([data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('hidden', '');
      a.setAttribute('href', url);
      a.setAttribute('download', 'saledata.csv');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };
    const getReport = async function () {
      const res = JSON.parse(window.localStorage.getItem("Saledata"));
      console.log(res)
      if (res.length >= 1) {
        const json = res;
        const data = json.map(row => ({
          invoiceno: row.invoiceno,
          type: row.type,
          customer: row.customer,
          orderno: row.orderno,
          courierno: row.courierno,
          channel: row.channel,
          item_sku: row.item_sku,
          item_name: row.item_name,
          invoice_date: row.invoice_date,
          paymentamount: row.paymentamount,
          paymentdate: row.paymentdate,
          mode: row.mode

        }))

        const csvData = objectToCsv(data)
        download(csvData);
      }
      else {
        this.error = true
        this.errortext = "No data found";
        // alert("No data found")
      }
    };
    //main app
    (function () {
      //button click
      const button = document.getElementById('saledata');
      button.addEventListener('click', getReport)
    })();
  }














































  onbrandstatuspopupsucess() {
    this.brandstatuspopup = false
    this.loadingfordataimport = true
    location.reload()
  }
  onchanelstatuspopupsucess() {
    this.chanelstatuspopup = false;
    this.chanelstatuspopupsuccess = false
    this.loadingfordataimport = true
    this.button = true

  }









}
