<div class="jesssu-seller-banner-wrap">
    <div class="jesssu-seller-img">
        <img src="../../../../assets/images/Rectangle 1493.svg" />
    </div>
    <div class="jesssu-seller-contant">
        <div class="jesssu-header">
            <div class="jesssu-header-row">
                <div>
                </div>
                <div class="rightside-profile">

                </div>

            </div>
        </div>
        <div class="jesssu-heading">
            <div class="heading-row">
            </div>

        </div>

        <div class="import-applications-box">
            <div class="applications-table-row">

                <div class="cv-uploading">
                    <h5>Select Channel for download product & sale data</h5>
                </div>

                <div class="form-group">
                    <select class="import-product-form-control" (change)="getdata($event.target.value)">
                        <option [value]="0" copy (change)="getdata($event.target.value)" class> Select Channel for
                            download
                            product
                            data </option>
                        <option *ngFor="let channel of channels" [value]="channel.id">
                            {{ channel.name }}
                        </option>
                    </select>
                </div>

                <div class="cv-uploading">
                    <h5>Export your products on excel</h5>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <!-- <div class="form-group">
                            <input type="file" accept=".csv" class="product-form-control"
                                (change)="onSelectedFile($event)" (OnCancel)="handleCancel()">
                        </div> -->
                        <div class="form-group">
                            <label class="choose_file">
                                <img src="../../../../assets/images/product-list/download.png" /> Choose files 
                              <input type="file" accept=".csv" class="d-none product-form-control"
                              (change)="onSelectedFile($event)" (OnCancel)="handleCancel()"/>
                            </label>
                          </div>
                    </div>
                    <div class="d-flex justify-content-center" *ngIf=!loadingfordataimport>
                        <div class="spinner-border" role="status">
                            <span class="sr-only" id="loading"></span>
                        </div>
                    </div>
                    <div class="col-md-4" *ngIf="loadingfordataimport">
                        <div class="product-feed-action">
                            <button (click)="upload()" type="button" class="btn-action-link"
                                value="Submit">Upload</button>
                        </div>
                    </div>
                </div>
                <div class="cv-downloading">
                    <h5>Click <a href="../../../../assets/product.csv" download="product.csv" class="download-btn">
                            Download <span>
                            </span>
                        </a> for download blank excel file format for upload products</h5>
                </div>

                <div class="row">

                    <div class="col-xl-3" width="20%">
                        <button (click)="exportforflipkart()" class="btn-action-link" id="flipkart" *ngIf="button">
                            Export
                            for Flipkart</button>
                    </div>
                    <div class="col-xl-3" width="20%">
                        <button (click)="exportforamazon()" class="btn-action-link" id="amazon" *ngIf="button"> Export
                            for Amazon</button>
                    </div>
                    <div class="col-xl-3" width="20%">
                        <button (click)="exportformeesho()" class="btn-action-link" id="meesho" *ngIf="button"> Export
                            for Meesho</button>
                    </div>
                    <div class="col-xl-3" width="20%">
                        <button (click)="exportforjesssu()" class="btn-action-link" id="jesssu" *ngIf="button"> Export
                            for Jesssu</button>
                    </div>



                </div>
                <br/>
                <div class="row">

                   
                    <div class="col-xl-3" width="20%">
                        <button (click)="exportforsaledata()" class="btn-action-link" id="saledata" *ngIf="button"> Export sale data</button>
                    </div>



                </div>
            </div>
        </div>

        <div class="d-flex justify-content-center" *ngIf=!loading>
            <div class="spinner-border" role="status">
                <span class="sr-only" id="loading"></span>
            </div>
        </div>

    </div>
</div>
<div *ngIf="productstatuspopup" class="loginbox">
    <div class="paste-source">
        <div class="paste-source-box">
            <h4>Product has been Uploaded successfully</h4>
            <div class="save-btn">
                <div class="featured-action">
                    <button type="button" class="save-upload-cli-primary-btn" (click)="onproductstatuspopupsucess()">
                        Ok
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="brandstatuspopup" class="loginbox">
    <div class="paste-source">
        <div class="paste-source-box">
            <h4>Brand has been synce successfully</h4>
            <div class="save-btn">
                <div class="featured-action">
                    <button type="button" class="save-upload-cli-primary-btn" (click)="onbrandstatuspopupsucess()">
                        Ok
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="chanelstatuspopup" class="loginbox">
    <div class="paste-source">
        <div class="paste-source-box">
            <h4>Please select a channel for brand sync for your product's</h4>
            <div class="save-btn">
                <div class="featured-action">
                    <button type="button" class="save-upload-cli-primary-btn" (click)="onchanelstatuspopupsucess()">
                        Ok
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="blankproductstatuspopup" class="loginbox">
    <div class="paste-source">
        <div class="paste-source-box">
            <h4>Please upload a file for Import your product's</h4>
            <div class="save-btn">
                <div class="featured-action">
                    <button type="button" class="save-upload-cli-primary-btn"
                        (click)="onblankproductstatuspopupsucess()">
                        Ok
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="jesssu-seller-contant">
<div class="import-applications-box recent_data_uploads">
    <div>
        <h3>Recent Uploads</h3>
        <div class="table-responsive">
            <table>
                <tr>
                    <th>Uploaded By</th>
                    <th>Uploaded Date</th>
                    <th>Upload Details</th>
                </tr>
                <tr>
                    <td>
                        <div class="name_email">
                        <p class="text-bold">Pritam Nandi</p>
                        <p>pritam.nandi88@gmail.com</p>
                        </div>
                    </td>
                    <td>
                        <div class="name_email">
                        <p>20/03/2020</p>
                        </div>
                    </td>
                    <td>
                        <div class="name_email">
                        <p>Main Data: <span class="text-bold">91</span></p>
                        <p>Variation Data: 0</p>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="name_email">
                        <p class="text-bold">Pritam Nandi</p>
                        <p>pritam.nandi88@gmail.com</p>
                        </div>
                    </td>
                    <td>
                        <div class="name_email">
                        <p>20/03/2020</p>
                        </div>
                    </td>
                    <td>
                        <div class="name_email">
                        <p>Main Data: <span class="text-bold">91</span></p>
                        <p>Variation Data: 0</p>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="name_email">
                        <p class="text-bold">Pritam Nandi</p>
                        <p>pritam.nandi88@gmail.com</p>
                        </div>
                    </td>
                    <td>
                        <div class="name_email">
                        <p>20/03/2020</p>
                        </div>
                    </td>
                    <td>
                        <div class="name_email">
                        <p>Main Data: <span class="text-bold">91</span></p>
                        <p>Variation Data: 0</p>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="name_email">
                        <p class="text-bold">Pritam Nandi</p>
                        <p>pritam.nandi88@gmail.com</p>
                        </div>
                    </td>
                    <td>
                        <div class="name_email">
                        <p>20/03/2020</p>
                        </div>
                    </td>
                    <td>
                        <div class="name_email">
                        <p>Main Data: <span class="text-bold">91</span></p>
                        <p>Variation Data: 0</p>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="name_email">
                        <p class="text-bold">Pritam Nandi</p>
                        <p>pritam.nandi88@gmail.com</p>
                        </div>
                    </td>
                    <td>
                        <div class="name_email">
                        <p>20/03/2020</p>
                        </div>
                    </td>
                    <td>
                        <div class="name_email">
                        <p>Main Data: <span class="text-bold">91</span></p>
                        <p>Variation Data: 0</p>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
        <nav class="bl_pager">
            <ul class="bl_pager_inner">
              <li>
                <a class="bl_pager_link" href="#"><img src="../../../../assets/images/product-list/left.png" /></a>
              </li>
             <li>
                <span class="bl_pager_link is_active">1</span>
              </li>
              <li>
                <a class="bl_pager_link" href="#">2</a>
              </li>
              <li>
                <a class="bl_pager_link" href="#">3</a>
              </li>
              <li>
                <a class="bl_pager_link" href="#">4</a>
              </li>
              <li>
                <a class="bl_pager_link" href="#"><img src="../../../../assets/images/product-list/right.png" /></a>
              </li>
            </ul>
          </nav>

    </div>
</div>
</div>