<div class="image-upload-row" *ngFor="let category of subcategory[0].categorydetails">
    <br>
    <h4>
        Gender: {{category.gender}}</h4>
    <br>

    <div class="sub-category-box">
        <div class="sub-img-box">
            <div class="image-upload-row" style="height: 100%" *ngFor="let subcategory of category.subcategories">
                <li class="clearfix">
                    <div class="image-upload-box">
                        <input type="file" id="img01" (change)="selectFile($event,'main')" />
                        <label class="upload-imgbox" for="img01">
                            <img src={{subcategory.subcategoryimage}} />
                        </label>
                        <div class="text">
                            <h4>{{subcategory.subcategoryname}}</h4>
                        </div>
                    </div>
                </li>
            </div>
        </div>
    </div>
</div>

