import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NavService } from '../../service/nav.service';
import { ActivatedRoute, Router } from "@angular/router";
import { CustomCookieService } from  '../../service/cookie.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public right_sidebar: boolean = false;
  public open: boolean = false;
  public openNav: boolean = false;
  public isOpenMobile : boolean;

  @Output() rightSidebarEvent = new EventEmitter<boolean>();
  public sellerdata= [];
  admindata: any;

  constructor(public navServices: NavService, private router: Router,  private cookieService: CustomCookieService) {
    this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"));
    this.admindata = JSON.parse(window.localStorage.getItem("admin-info"));

   }

  collapseSidebar() {
    this.open = !this.open;
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar
  }
  right_side_bar() {
    this.right_sidebar = !this.right_sidebar
    this.rightSidebarEvent.emit(this.right_sidebar)
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }


  ngOnInit() {  }
  logout(){
    localStorage.clear();
    this.cookieService.deleteCookie("UserDetails")
    this.cookieService.deleteCookie("SubscriptionData")
    this.cookieService.deleteCookie("Subscription")
    this.router.navigateByUrl("/auth/login")
  }
}
