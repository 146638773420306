import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { BrandModel } from 'src/app/shared/models/datamodel';
import { ApiService } from '../../../shared/service/api.service';


@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss']
})
export class BrandsComponent implements OnInit {
  public text: String
  public id: any;
  public brandname: any;
  public sellerdata: any;
  public admindata = [];
  public payloadforgetbrands: any;
  public brandlist: any;
  public selected = [];
  public res: any;
  public status: Boolean;
  public offset: number = 0;
  public count: number = 50;
  BrandModel = new BrandModel();
  constructor(private apiService: ApiService, private router: Router) { }

  ngOnInit(): void {
    this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"));
    this.admindata = JSON.parse(window.localStorage.getItem("admin-info"));
    this.getbrandlist();
  }

  onScroll() {
    this.offset++;
    this.getbrandlist();
  }

  getbrandlist() {
    let payload = {}

    this.apiService.getbrands(payload, this.offset, this.count)
      .subscribe((res: any[]) => {

        if (this.brandlist != null) {
          this.brandlist = this.brandlist.concat(res)
        }
        else {
          this.brandlist = res
        }
      });
  }

  onEdit(brandname) {
    localStorage.removeItem("brandname");
    localStorage.setItem("brandname", JSON.stringify(brandname));
    if (confirm("Are you want to edit the brand details")) {
      this.router.navigate(['/media/brands/' + brandname]);
    }
  }


  myFunction1() {
    var userid;
    var input, filter, table, tr, td, i, txtValue;
    input = document.getElementById("myInput");
    filter = input.value;
    var payload = { "search": input.value }
    if (filter.length > 1) {
      this.apiService.getbrandsearch(payload)
        .subscribe((response: any[]) => {
          if (response.length >= 0) {

          }
          this.brandlist = response
        })
    }
    else {
      this.getbrandlist()
    }
  }


  changeStatus(brandname) {
    this.brandname = brandname.brandname;
    if (brandname.status == true) {
      this.status = false
    } else {
      this.status = true
    }

    const payload = {
      "data":
        [{
          "brandname": this.brandname,
          "status": this.status
        }]
    }

    if (this.status == true) {
      this.text = "Activated"
    } else {
      this.text = "Deactivated"
    }

    this.apiService.updatebrand(payload)
      .subscribe((res: any) => {
        alert(`Brand has been ${this.text}.`)
        location.reload()
      },
      )
  }

}
