import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class WebService {
  readonly ROOT_URL: string;
  readonly ROOT_URL_SCRAPPER: string;
  constructor(private http: HttpClient) {
    //  this.ROOT_URL = "http://localhost:3500"
    // this.ROOT_URL = "http://68.183.90.47:3501"
    // this.ROOT_URL_SCRAPPER = "http://localhost:4001"
    this.ROOT_URL = "https://seller.api.jesssu.com";

  }
  post(uri: string, payload: Object) {
    return this.http.post(`${this.ROOT_URL}/${uri}`, payload);
  }
  // postScrapper(uri: string, payload: Object) {
  //   return this.http.post(`${this.ROOT_URL_SCRAPPER}/${uri}`, payload);
  // }
  put(uri: string, payload: Object) {
    return this.http.put(`${this.ROOT_URL}/${uri}`, payload);
  }
  post1(uri: string, payload: Object) {
    return this.http.post(`${this.ROOT_URL}/${uri}`, payload);
  }
  postImage(uri: string, payload: Object) {
    return this.http.post(`${this.ROOT_URL}/${uri}`, payload, { responseType: 'text' });
  }
  postImage1(uri: string, payload: Object) {
    return this.http.post(`${this.ROOT_URL}/${uri}`, payload, { responseType: 'text' });
  }
  delete(uri: string) {
    return this.http.delete(`${this.ROOT_URL}/${uri}`);
  }
  get(uri: string) {
    return this.http.get(`${this.ROOT_URL}/${uri}`);
  }
  get1(uri: string) {
    return this.http.get(`${this.ROOT_URL}/${uri}`);
  }
  patch(uri: string, payload: Object) {
    return this.http.patch(`${this.ROOT_URL}/${uri}`, payload);
  }
}
