import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from '../../../shared/service/api.service';
import { ProductimageService } from '../../../shared/service/fileupload.service';

import { ActivatedRoute, Router } from "@angular/router";
import { UserModel, Accounts } from 'src/app/shared/models/datamodel';

@Component({
  selector: 'app-userpermission',
  templateUrl: './userpermission.component.html',
  styleUrls: ['./userpermission.component.scss']
})
export class UserpermissionComponent implements OnInit {
  public errortext = ""
  public successtext = ""
  public error = false
  public success = false
  public permissionForm: FormGroup;
  public user_list = []
  public usertstatuspopup: Boolean = false;
  public idforusertstatuspopup: any;

  sellerdata: any;
  admindata: any;
  checkboxesDataList: { id: string; label: string; isChecked: boolean }[];
  selectedItemsList: { id: string; label: string; isChecked: boolean }[];
  checkedIDswithURL: any[];
  checkedIDs: any[];
  public secondaryuserdata = new UserModel()
  subscriptionplan: any;
  token: any;
  constructor(private formBuilder: FormBuilder, private apiService: ApiService, private router: Router, private imageuploadService: ProductimageService, private activeroute: ActivatedRoute) {
    this.createPermissionForm();
    this.checkboxesDataList = [
      {
        id: 'Product',
        label: 'Product',
        isChecked: false
      },
      {
        id: 'Sale',
        label: 'Sale',
        isChecked: false
      },
      {
        id: 'Purchase',
        label: 'Purchase',
        isChecked: false
      },
      {
        id: 'Purchase-Order',
        label: 'Purchase-Order',
        isChecked: false
      },
      {
        id: 'User',
        label: 'User',
        isChecked: false
      },
      {
        id: 'Sale-Order',
        label: 'Sale-Order',
        isChecked: false
      },
      {
        id: 'Customer',
        label: 'Customer',
        isChecked: false
      },
      {
        id: 'Supplier',
        label: 'Supplier',
        isChecked: false
      },

    ]
    this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"))
    this.admindata = JSON.parse(window.localStorage.getItem("admin-info"))
    this.subscriptionplan = JSON.parse(window.localStorage.getItem("SUBSCRIPTIONPLAN"));
    this.token = JSON.parse(window.localStorage.getItem("TOKEN"))
    if (this.admindata == null && this.sellerdata == null) {
      this.router.navigate(["'/auth/login'"])
    }
    else {
      if (this.sellerdata != null) {
        if (this.sellerdata[0].accountstatus == "Secondery") {
          let status = this.sellerdata[0].roleaccess.includes("User")
          if (status == false) {
            this.router.navigate(['/page-not-found'])
          }else{
            this.getuserdata()
          }
        }
        else if (this.subscriptionplan == "Free") {
          this.router.navigate(['/page-not-found'])
        }
        else if (this.subscriptionplan == "Starter") {
          this.router.navigate(['/page-not-found'])
        }
        else if (this.subscriptionplan == "Professional") {
          this.getuserdata()
        }
        else if (this.subscriptionplan == "Enterprise") {
          this.getuserdata()
        }
        else {
          this.router.navigate(['/page-not-found'])
        }
      }
      else {
        this.errortext = "Please select a seller"
        this.error = true
        this.router.navigate(['/all/users-sellers'])
      }
    }

  }

  ngOnInit(): void {
  }


  getuserdata() {
    if (this.admindata == null) {
      const payload = {
        token: this.sellerdata[0].token,
      email: this.sellerdata[0].email,
        manufacturer: this.sellerdata[0].manufacturer
      }
      let userid = this.activeroute.snapshot.url[1].path;
      console.log(userid)

      this.apiService.getuserdetails(userid, payload).subscribe((res: UserModel) => {
        this.secondaryuserdata = res

        this.checkboxesDataList.forEach(i => {
          this.secondaryuserdata.roleaccess.forEach(j => {
            if (i.id == j) {
              i.isChecked = true;
            }
          })
        });
      })
    }
    else {
      const payload = {
        userid: this.sellerdata[0]._id,
        token: this.admindata[0].token,
        manufacturer: this.sellerdata[0].manufacturer
      }
      let userid = this.activeroute.snapshot.url[1].path;
      console.log(userid)

      this.apiService.getuserdetails(userid, payload).subscribe((res: UserModel) => {
        this.secondaryuserdata = res

        this.checkboxesDataList.forEach(i => {
          this.secondaryuserdata.roleaccess.forEach(j => {
            if (i.id == j) {
              i.isChecked = true;
            }
          })
        });
      })
    }
  }



  getselectdata(productModel1) {
    if (this.checkboxesDataList.indexOf(productModel1.channellist))
      this.checkboxesDataList.filter((value, index) => {
        return value.isChecked
      });
  }
  createPermissionForm() {
    this.permissionForm = this.formBuilder.group({
    })
  }
  fetchSelectedItems() {
    this.selectedItemsList = this.checkboxesDataList.filter((value, index) => {
    });
  }
  changeSelection() {
    this.fetchSelectedItems();
    this.fetchCheckedIDs();
  }

  fetchCheckedIDs() {

    this.checkedIDs = [];
    this.checkedIDswithURL = [];

    this.checkboxesDataList.forEach((value, index) => {
      if (value.isChecked) {
        var channel = {
          channel_name: value.id,
          // url: value.url
        }
        this.checkedIDs.push(value.id);
        this.checkedIDswithURL.push(channel)
      }
    });
  }
  updateUserPermission() {
    // console.log(this.checkedIDs)

    // const payload = {
    //   userid: this.sellerdata[0]._id,
    //   token: this.token,
    //   roleaccess: this.checkedIDs
    // }
    this.usertstatuspopup = true;
    this.idforusertstatuspopup = null
    // let userid = this.activeroute.snapshot.url[1].path;

 
    // if (confirm("Are you sure to update access for this user")) {
    //   this.apiService.updateSecondaryUserPermission(userid, payload).subscribe((res) => {
    //     this.success = true
    //     this.successtext = "User permission updated"
    //     this.router.navigate(['/users/list-user'])
    //     // alert("User permission updated")
    //   }, (err) => {
    //     alert(err.error)
    //   })
    // }
  }
  onuserstatuspopupsucess() {
    this.usertstatuspopup = false
    this.idforusertstatuspopup = null
    console.log(this.checkedIDs)
    const payload = {
      userid: this.sellerdata[0]._id,
      token: this.token,
      email:this.sellerdata[0].email,
      roleaccess: this.checkedIDs
    }
    let userid = this.activeroute.snapshot.url[1].path;
    if (this.usertstatuspopup = true) {
        this.apiService.updateSecondaryUserPermission(userid, payload).subscribe((res) => {
          this.success = true
          this.successtext = "User permission updated"
          this.router.navigate(['/users/list-user'])
          // alert("User permission updated")
        }, (err) => {
          alert(err.error)
        })
      }
  }
  onuserstatuscancel() {

    this.usertstatuspopup = false;
    location.reload()  
  }
  
}




