<p>Category Details</p>
<main class="container">
    <div class="left-column img">
        <label class="upload-imgbox" for="img01">
            <img src={{category[0].categorydetails[0].categoryimage}} />
        </label>
        <h4> {{category[0].categorydetails[0].categoryname}} </h4>
        <div class="image-upload-box">
            <input type="file" id="img01" (change)="selectFile($event,'main')" />
            <label class="upload-imgbox" for="img01">
            </label>
        </div>
        <button class="btn-action-link" (click)="onSubmit(category[0].categorydetails[0])">Update</button>
    </div>
</main>