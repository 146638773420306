<div class="jesssu-seller-banner-wrap">
    <!-- <div class="jesssu-seller-img">
        <img src="../../../../assets/images/Rectangle 1493.svg" />
    </div> -->
    <!-- <div style="display: block;">
        <canvas id="categoryStatsChart"></canvas>
      </div>
       -->
    <div class="jesssu-seller-contant">
        <!-- <div class="jesssu-header">
            <div class="jesssu-header-row">
                <div>
                </div>
                <div class="rightside-profile">

                </div>

            </div>
        </div> -->
        <!-- <div class="jesssu-heading">
            <div class="heading-row">
            </div>

        </div> -->

        <!-- Container-fluid starts-->
        <!-- <div class="container-fluid" *ngIf="flag"> -->
        <div class="container-fluid">
            <!-- <ngb-accordion [closeOthers]="true">
                <ngb-panel title="View Filter">
                    <ng-template ngbPanelContent>
                        <div class="filter">
                            <div class="filter-section">
                                <div id="action-description">
                                    <div class="date-dashboard-heading">
                                        <h5>Get order summary and sell earning data by year/month Wise</h5>
                                    </div>

                                    <form [formGroup]="yearlyform" id="yearlyform" novalidate="">
                                        <div class="filter-row">


                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <select formControlName="year" class="product-form-control">
                                                        <option [ngValue]="null" disabled>Select Year</option>
                                                        <option *ngFor="let year of years" [ngValue]="year.id">
                                                            {{year.name}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>



                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <select formControlName="month" class="product-form-control">
                                                        <option [ngValue]="null" disabled>Select Month</option>
                                                        <option *ngFor="let month of months" [ngValue]="month.id">
                                                            {{month.name}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <button type="submit" (click)="onSubmit1()"
                                                        class="filter-btn-action-link">Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div class="date-dashboard-heading">
                                        <h5>Get order summary and sell earning data by selected date</h5>
                                    </div>
                                    <form [formGroup]="dateform" id="dateform" novalidate="">

                                        <div class="filter-row">
                                            <div class="col-md-4">

                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <div class="dashboard-filter-row">
                                                            <input class="product-form-control" ngbDatepicker
                                                                #c="ngbDatepicker" [(ngModel)]="yymmwise"
                                                                formControlName="date1">
                                                            <div class="input-group-append">
                                                                <button class="btn calendar" (click)="c.toggle()"
                                                                    type="button"><i
                                                                        class="fa fa-calendar"></i></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <div class="dashboard-filter-row">
                                                            <input class="product-form-control" ngbDatepicker
                                                                #d="ngbDatepicker" [(ngModel)]="yymmwise.todate"
                                                                formControlName="date2">
                                                            <div class="input-group-append">
                                                                <button class="btn calendar" (click)="d.toggle()"
                                                                    type="button"><i
                                                                        class="fa fa-calendar"></i></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <button type="submit" (click)="onSubmit(dateform)"
                                                        class="filter-btn-action-link">Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion> -->
            <!-- <h6 class="center">***{{displayvalue}}***</h6> -->
            <h1>Dashboard</h1>
            <div class="chart-container" style="padding: 20px;" [ngClass]="{'hidden': !categorychartReady}">
                <h3 class="chart-title">Category Engagement Breakdown</h3>
                <canvas #chartCanva width="400"></canvas>
              </div>
              
           
            
              <div class="chart-container" [ngClass]="{'hidden': !productchartReady}" style="padding: 20px;">
                <h3 class="chart-title">Product Performance Timeline</h3>
              
                <div id="date-range-selector">
                  <label for="dateRange" class="styled-label">Select Date Range:</label>
                  <select id="dateRange" [(ngModel)]="selectedOption" (change)="onIntervalChange()" class="styled-select">
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                  </select>
                </div>
              
                <div id="date-inputs" *ngIf="selectedOption !== 'daily'" style="margin-top: 10px;">
                  <label for="startDate" class="styled-label">Start Date:</label>
                  <input type="date" id="startDate" [(ngModel)]="startDate" (ngModelChange)="updateChart()" class="styled-input">
                  
                  <label for="endDate" class="styled-label">End Date:</label>
                  <input type="date" id="endDate" [(ngModel)]="endDate" (ngModelChange)="updateChart()" class="styled-input">
                </div>
              
                <div id="daily-date-input" *ngIf="selectedOption === 'daily'" style="margin-top: 10px;">
                  <label for="startDate" class="styled-label">Date:</label>
                  <input type="date" id="startDate" [(ngModel)]="startDate" (ngModelChange)="onIntervalChange()" class="styled-input">
                </div>
              <!-- Always displayed -->
              <canvas #chartCanvas width="400"></canvas>
            </div>
              

  
            <div class="tab-section-wrap d-flex align-items-center">
                <div (click)="selectedTab = 'Tab1'"
                    [class.tabactive]="selectedTab === 'Tab1'"
                    class="tab-box">Most Viewed Products</div>
                <div (click)="selectedTab = 'Tab2'"
                    [class.tabactive]="selectedTab === 'Tab2'"
                    class="tab-box">Recently Shared Products</div>

            </div>

            <!-- <h3 class="popular_product_banner">Most Viewed Products</h3> -->

            <div *ngIf="selectedTab === 'Tab1'" class="invoice-container">
                <div *ngIf="isLoading === false">
                    <div *ngIf="popularproducts.length > 0; else noDataText">
                        <div class="invoice-header sm:grid sm:grid-cols-6">
                            <div
                                class="text-start text-gray-900 font-bold text-sm">Product</div>
                            <div
                                class="text-start text-gray-900 font-bold text-sm">Likes</div>
                            <div
                                class="text-start text-gray-900 font-bold text-sm">Dislikes</div>
                            <div
                                class="text-start text-gray-900 font-bold text-sm">Views</div>
                        </div>

                        <div *ngFor="let product of popularproducts"
                            class="invoice-row">
                            <div>
                                <h5
                                    class="hidden">Product</h5>
                                <div class="invoice-row-wrap">
                                    <img
                                        [src]="product.productdetails.MainImage"
                                        alt="Product Image">
                                    <p
                                        class="text-gray-800">{{
                                        product.productdetails.Name }}</p>
                                </div>
                            </div>
                            <div>
                                <h5
                                    class="hidden">Likes</h5>
                                <p
                                    class="text-gray-800 px-1">{{
                                    product.productLikesDislikes[0]?.likes?.length
                                    ?? 0
                                    }}</p>
                            </div>

                            <div>
                                <h5
                                    class="hidden">Dislikes</h5>
                                <p
                                    class="text-gray-800 px-1">{{
                                    product.productLikesDislikes[0]?.dislikes?.length
                                    ??
                                    0 }}</p>
                            </div>
                            <div>
                                <h5
                                    class="hidden">Views</h5>
                                <div class="d-flex align-items-center">
                                    <p
                                        class="text-gray-800 px-1">{{product?.viewcount
                                        ?? 0
                                        }}</p>
                                    <button type="button" class="preview"
                                        (click)="newtogglePopup(product)">
                                        <img
                                            src="../../../../assets/images/preview.svg"
                                            class />
                                    </button>
                                </div>
                            </div>
                            <div *ngIf="product?.openPopup==true"
                                class="showpreivew">

                                <div
                                    class="invoice-header sm:grid sm:grid-cols-6">
                                    <!-- <div
                                class="text-start text-gray-900 font-bold text-sm">User
                                Name</div> -->
                                    <div
                                        class="text-start text-gray-900 font-bold text-sm">Email
                                        Id</div>
                                    <div
                                        class="text-start text-gray-900 font-bold text-sm">Views</div>
                                    <!-- <div
                                    class="text-start text-gray-900 font-bold text-sm">Activity</div> -->

                                </div>
                                <!-- <div class="invoice-row"
                            *ngFor="let userId of selectedProduct.usersid; let i = index"> -->
                                <!-- <div class="invoice-row"
                            *ngFor="let userId of product?.usersid; let i = index"> -->
                                <div class="invoice-row"
                                    *ngFor="let userId of removeDuplicates(product?.usersid); let i = index">
                                    <!-- <p
                                class="text-gray-800 px-1">{{product?.firstname}}{{product?.lastname}}</p> -->
                                    <div>
                                        <!-- <div
                                    *ngFor="let userId of product?.usersid; let i = index"> -->
                                        <p>{{ userId }}</p>
                                        <!-- </div> -->
                                    </div>
                                    <!-- <p class="text-gray-800 px-1">0

                                </p> -->
                                    <!-- <div
                                *ngFor="let userId of selectedProduct.usersid; let i = index"> -->
                                    <p
                                        style="color: rgb(51, 51, 226);cursor: pointer;"
                                        (click)="togglePopup(product,userId); getUserData(userId,1);">viewes
                                        activity</p>
                                    <!-- </div> -->
                                </div>

                            </div>

                        </div>
                    </div>
                    <ng-template #noDataText>
                        <div class="no-data-text">
                            <img
                                src="../../../../assets/images/noproduct.png" />
                            <!-- Data is not available. -->
                        </div>
                    </ng-template>
                </div>
                <!-- loader -->
                <div class="loader-overlay" [class.showloader]="isLoading">
                    <div class="loader"></div>
                </div>

            </div>

            <!-- <div class="product-list">
                <div *ngFor="let product of popularproducts"
                    class="product-item">
                    <img [src]="product.productdetails.MainImage"
                        alt="Product Image">
                    <div class="product-name">
                        <h4>{{ product.productdetails.Name }}</h4>
                    </div>
                    <p class="price">Price: {{
                        product.productdetails.PriceCurrency }} {{
                        product.productdetails.SalePrice }}</p>
                    Add more details as needed
                    <div
                        *ngFor="let channel of product.productdetails.channels">
                        <a [href]="channel.url">{{ channel.channel_name }}</a>
                    </div>
                    <div class="social">
                        <p class="social_child">Likes: {{
                            product.productLikesDislikes[0]?.likes?.length ?? 0
                            }}</p>
                        <p class="social_child">Dislikes: {{
                            product.productLikesDislikes[0]?.dislikes?.length ??
                            0 }}</p>
                        <button class="social_child"
                            (click)="togglePopup(product)">Views:
                            {{product?.viewcount ?? 0 }}</button>
                    </div>
                </div>
            </div> -->

            <!-- shared products -->
            <div *ngIf="selectedTab === 'Tab2'" class="invoice-container">
                <!-- <h3 class="shared_product_banner">Recently Shared Products</h3> -->
                <div *ngIf="isSharedLoading === false">
                    <div *ngIf="sharedproduct.length > 0; else noSharedData">
                        <div class="invoice-header sm:grid sm:grid-cols-6">
                            <div
                                class="text-start text-gray-900 font-bold text-sm">Product</div>
                            <div
                                class="text-start text-gray-900 font-bold text-sm">Likes</div>
                            <div
                                class="text-start text-gray-900 font-bold text-sm">Dislikes</div>
                            <div
                                class="text-start text-gray-900 font-bold text-sm">Shares</div>
                        </div>

                        <div *ngFor="let product of sharedproduct"
                            class="invoice-row">
                            <div>
                                <h5
                                    class="hidden">Product</h5>
                                <div class="invoice-row-wrap">
                                    <img
                                        [src]="product.productdetails.MainImage"
                                        alt="Product Image">
                                    <p
                                        class="text-gray-800">{{
                                        product.productdetails.Name }}</p>
                                </div>
                            </div>
                            <div>
                                <h5
                                    class="hidden">Likes</h5>
                                <p
                                    class="text-gray-800 px-1">{{
                                    product.productLikesDislikes[0]?.likes?.length
                                    ?? 0
                                    }}</p>
                            </div>

                            <div>
                                <h5
                                    class="hidden">Dislikes</h5>
                                <p
                                    class="text-gray-800 px-1">{{
                                    product.productLikesDislikes[0]?.dislikes?.length
                                    ??
                                    0 }}</p>
                            </div>
                            <div>
                                <h5
                                    class="hidden">Share</h5>
                                <p
                                    class="text-gray-800 px-1">{{
                                    product.shareCount }}</p>
                            </div>
                        </div>
                    </div>
                    <ng-template #noSharedData>
                        <div class="no-data-text">
                            <img
                                src="../../../../assets/images/noproduct.png" />
                            <!-- Data is not available. -->
                        </div>
                    </ng-template>
                </div>
                <!-- loader -->
                <div class="loader-overlay"
                    [class.showloader]="isSharedLoading">
                    <div class="loader"></div>
                </div>
            </div>

            <div class="tab-section-wrap d-flex align-items-center mt-5">
                <div
                    class="seller-tabactive tab-box">Product Scrape
                    Status</div>
            </div>

            <div class="invoice-container">
                <div *ngIf="isScrapeLoading === false">
                    <div *ngIf="sellerscrapedata.length > 0; else noScrapeData">
                        <div
                            class="seller-scrapper-header sm:grid sm:grid-cols-6">
                            <div
                                class="text-start text-gray-900 font-bold text-sm">Company/Store
                                Name</div>
                            <div
                                class="text-start text-gray-900 font-bold text-sm">Status</div>
                            <div
                                class="text-start text-gray-900 font-bold text-sm">Time</div>
                            <div
                                class="text-start text-gray-900 font-bold text-sm">Success</div>
                            <div
                                class="text-start text-gray-900 font-bold text-sm">Failed</div>
                        </div>

                        <div *ngFor="let data of sellerscrapedata"
                            class="seller-scrapper-row">
                            <div>
                                <h5
                                    class="hidden">Channel</h5>
                                <div class="invoice-row-wrap">
                                    <p
                                        class="text-gray-800">{{
                                        data.channel }}</p>
                                </div>
                            </div>
                            <div>
                                <h5
                                    class="hidden">Status</h5>
                                <p
                                    class="text-gray-800 px-1">{{
                                    data.success
                                    }}</p>
                            </div>

                            <div>
                                <h5
                                    class="hidden">Time</h5>
                                <p
                                    class="text-gray-800 px-1">{{
                                    data.createdAt |
                                    date:
                                    'dd/MM/yyyy HH:mm:ss'}}</p>
                            </div>
                            <div>
                                <h5
                                    class="hidden">Success</h5>
                                <p
                                    class="text-gray-800 px-1">{{
                                    data.totalScraped }}</p>
                            </div>
                            <div>
                                <h5
                                    class="hidden">Failed</h5>
                                <p
                                    class="text-gray-800 px-1">{{
                                    data.failedCount }}</p>
                            </div>
                        </div>
                    </div>
                    <ng-template #noScrapeData>
                        <div class="no-data-text">
                            <img
                                src="../../../../assets/images/nodata.jpg" />
                            <!-- Data is not available. -->
                        </div>
                    </ng-template>
                </div>
                <!-- loader -->
                <div class="loader-overlay"
                    [class.showloader]="isScrapeLoading">
                    <div class="loader"></div>
                </div>
            </div>

            <div class="tab-section-wrap d-flex align-items-center mt-5" >
                <div
                    class="seller-tabactive tab-box">Product Import
                    Status</div>
            </div>

            <div class="invoice-container">
                <div *ngIf="isScrapeLoading === false">
                    <div *ngIf="sellerimportdata.length > 0; else noScrapeData">
                        <div
                            class="seller-scrapper-header sm:grid sm:grid-cols-6">
                            <div
                                class="text-start text-gray-900 font-bold text-sm">Email/
                                Name</div>
                                <div
                                class="text-start text-gray-900 font-bold text-sm"></div>
                            
                            <div
                                class="text-start text-gray-900 font-bold text-sm">Status</div>
                            <div
                                class="text-start text-gray-900 font-bold text-sm">Time</div>
                            <div
                                class="text-start text-gray-900 font-bold text-sm">Details</div>
                        </div>

                        <div *ngFor="let data of sellerimportdata"
                            class="seller-scrapper-row">
                            <div>
                                <h5
                                    class="hidden">Email</h5>
                                <div class="invoice-row-wrap">
                                    <p
                                        class="text-gray-800">{{
                                        data.email }}</p>
                                </div>
                            </div>
                            <div>
                                <h5
                                    class="hidden">Email</h5>
                                <!-- <div class="invoice-row-wrap">
                                    <p
                                        class="text-gray-800">{{
                                        data.email }}</p>
                                </div> -->
                            </div>
                            <div>
                                <h5
                                    class="hidden">Status</h5>
                                <p
                                    class="text-gray-800 px-1">{{
                                    data.type
                                    }}</p>
                            </div>

                            <div>
                                <h5
                                    class="hidden">Time</h5>
                                <p
                                    class="text-gray-800 px-1">{{
                                    data.createdAt |
                                    date:
                                    'dd/MM/yyyy HH:mm:ss'}}</p>
                            </div>
                            <div>
                                <h5
                                    class="hidden">Success</h5>
                                <p
                                    class="text-gray-800 px-1">{{
                                    data.activitydetails }}</p>
                            </div>
                        </div>
                    </div>
                    <ng-template #noScrapeData>
                        <div class="no-data-text">
                            <img
                                src="../../../../assets/images/nodata.jpg" />
                            <!-- Data is not available. -->
                        </div>
                    </ng-template>
                </div>
                <!-- loader -->
                <div class="loader-overlay"
                    [class.showloader]="isImportLoading">
                    <div class="loader"></div>
                </div>
            </div>

            <!-- <div class="shared-product-list">
                <div *ngFor="let product of sharedproduct"
                    class="shared-product-item">
                    <img [src]="product.productdetails.MainImage"
                        alt="Product Image">
                    <div class="shared-product-name">
                        <h4>{{ product.productdetails.Name }}</h4>
                    </div>
                    <p class="shared-product-price">Price: {{
                        product.productdetails.PriceCurrency }} {{
                        product.productdetails.SalePrice }}</p>
                     Add more details as needed -->
            <!-- <div
                        *ngFor="let channel of product.productdetails.channels">
                        <a [href]="channel.url">{{ channel.channel_name }}</a>
                    </div>
                    <div class="sp_social">
                        <p class="sp_social_child">Likes: {{
                            product.productLikesDislikes[0]?.likes?.length ?? 0
                            }}</p>
                        <p class="sp_social_child">Dislikes: {{
                            product.productLikesDislikes[0]?.dislikes?.length ??
                            0 }}</p>
                        <p class="sp_social_child">Shares: {{ product.shareCount
                            }}</p>
                    </div>
                </div>
            </div> -->

            <!-- <div class="row">
                <div class="col-md-8">
                    <div class="dashboard-row">

                        <div class="applications-box">
                            <div class="applications-table-row">
                                <div class="order-row">
                                    <div class="order-summary">
                                        <img
                                            src="../../../assets/images/dashboard/Group 17.png"
                                            alt="Group 17.png">
                                    </div>
                                    <h4>ORDER SUMMARY</h4>
                                </div>
                                <div class="applications-lis-item"
                                    *ngFor="let channel of ordercount">
                                    <div class="applications-col">
                                        <h3>{{channel._id}}</h3>
                                    </div>
                                    <div class="applications-col1">
                                        <h3>{{channel.Count}}</h3>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="applications-box">
                            <div class="applications-table-row">
                                <div class="order-row">
                                    <div class="order-summary">
                                        <img
                                            src="../../../assets/images/dashboard/Group 18.png"
                                            alt="Group 18.png">
                                    </div>
                                    <h4>SELL EARNINGS</h4>
                                </div>
                                <div class="applications-lis-item"
                                    *ngFor="let channel of saleamountchannelwise">
                                    <div class="applications-col">
                                        <h3>{{channel._id}} </h3>
                                    </div>
                                    <div class="applications-col1">
                                        <h3>{{channel.Amount|
                                            number:'1.0-0'}}</h3>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="applications-box4">
                        <div class="applications-table-row">
                            <div class="order-row">
                                <div class="order-summary">
                                    <img
                                        src="../../../assets/images/dashboard/Group 21.png"
                                        alt="Group 21.png">
                                </div>
                                <h4>ORDER DETAILS</h4>
                            </div>
                            <div class="applications-lis-item1">
                                <div class="applications-col4">
                                    <h3 class="Orderh3">Today's Order </h3>
                                </div>
                                <div class="order">
                                    <h2>{{todayordercount}} </h2>
                                </div>
                                <div class="applications-col4">
                                    <h3 class="Orderh3">Last 30 Day Order </h3>
                                </div>
                                <div class="order">
                                    <h2>{{monthordercount}} </h2>
                                </div>
                            </div>
                            <div class="jesssu-order-row">
                                <div class="applications-lis-item1">
                                    <div class="applications-col4">
                                        <h3 class="Orderh3">Last 7 Day Order
                                        </h3>
                                    </div>
                                    <div class="order">
                                        <h2>{{weeklyordercount}}</h2>
                                    </div>
                                    <div class="applications-col4">
                                        <h3 class="Orderh3">Last 6 Month
                                            Order</h3>
                                    </div>
                                    <div class="order">
                                        <h2> {{sixmonthordercount}} </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="coi-md-4">
                    <div class="product-details">
                        <div class="applications-box1">
                            <div class="applications-table-row">
                                <div class="order-row1">
                                    <h4>PRODUCTS</h4>
                                    <div class="order-summary1">
                                        <img
                                            src="../../../assets/images/dashboard/Group 19.png"
                                            alt="Group 19">
                                    </div>
                                </div>
                                <div class="product-list-section">
                                    <div class="applications-col2">
                                        <h4
                                            class="activeh3">Active<span>{{activecount}}</span><small>
                                                Product</small>
                                        </h4>
                                    </div>
                                    <div class="applications-col2">
                                        <h4 class="activeh3">In-Active
                                            <span>{{inactivecount}}</span><small>
                                                Product</small></h4>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="applications-box2">
                            <div class="applications-table-row">
                                <div class="order-row1">
                                    <h4>RECENTLY ADDED</h4>
                                    <div class="order-summary1">
                                        <img
                                            src="../../../assets/images/dashboard/Group 20.png"
                                            alt="Group 20">
                                    </div>
                                </div>
                                <div class="recently-added-section">
                                    <div class="applications-col2">
                                        <h4
                                            class="activeh3"><span>{{addproductcount30dayscount}}</span><small>
                                                Listed
                                                This Month
                                            </small></h4>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="product-added">
                        <div class="applications-box1">
                            <div class="applications-table-row">
                                <div class="order-row1">
                                    <h4>PRODUCT ADDED IN <br>LAST 30 DAYS</h4>
                                    <div class="order-summary1">
                                        <img
                                            src="../../../assets/images/dashboard/Group 19.png"
                                            alt="Group 19">
                                    </div>
                                </div>
                                <div class="applications-lis-item1">
                                    <div class="applications-col2">
                                        <h4
                                            class="activeh3">{{addproductcount30dayscount}}</h4>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="applications-box3">
                            <div class="applications-table-row">
                                <div class="applications-lis-item1">
                                    <div class="applications-col3">
                                        <h3 class="saleh3"><button
                                                (click)="viewallsale()">View All
                                                Sales</button> </h3>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                <div class="dashboard-table">
                    <div class="card">
                        <div class="card-header">
                            <h5>Latest Payments</h5>
                        </div>
                        <div class="card-body">
                            <div
                                class="user-status table-responsive latest-order-table">
                                <table class="table table-bordernone">
                                    <thead>
                                        <tr>
                                            <th scope="col">Order ID</th>
                                            <th scope="col">Channel Details</th>
                                            <th scope="col">Payment Method</th>
                                            <th scope="col">Payment Date</th>
                                            <th scope="col">Order Amount</th>
                                            <th scope="col">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let sale of latestsaledata">
                                            <td
                                                class="font-warning">{{sale.orderno}}</td>
                                            <td
                                                class="font-primary">{{sale.channel}}</td>
                                            <td
                                                class="font-secondary">{{sale.payment.mode}}</td>
                                            <td
                                                class="font-primary">{{sale.payment.date}}</td>
                                            <td
                                                class="digits">₹{{sale.payment.supplieramount}}</td>
                                            <td
                                                class="font-danger">{{sale.payment.notes}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="buy-sell-applications-box">
                        <div class="applications-table-row">
                            <div class="buy-sell">
                                <h5>Buy / Sell</h5>
                            </div>
                            <div class="sell-graph">
                                <img
                                    src="../../../assets/images/dashboard/Group 27.svg"
                                    alt>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>

    </div>
</div>

<!-- <div class="container-fluid-section" *ngIf="!flag">

    <div class="welcome-dashboard-row">
        <h2>Welcome to Jesssu Seller Central</h2>
    </div>
</div> -->

<!-- Popup section -->
<div class="popup-wrap" *ngIf="showPopup">
    <div class="popup">
        <!-- <div class="popup-content"> -->
        <!-- Display details of the selected product -->
        <!-- <p>Views: {{selectedProduct?.viewcount ?? 0 }}</p> -->
        <!-- <div
            *ngFor="let userId of selectedProduct.usersid; let i = index"
            class="user"> -->
        <!-- <button class="userid-button"
                (click)="toggleUserDetails(i); getUserData(userId)"
                [style.display]="userDetailsVisibility[i] ? 'none' : 'block'">{{
                userId }}</button> -->
        <!-- <div class="user-details-section"
                [style.display]="userDetailsVisibility[i] ? 'block' : 'none'">
                <button class="cross-button"
                    (click)="toggleUserDetails(i)">X</button> -->
        <div *ngIf="popupLoading === false">
            <div *ngIf="userdata"
                class="user-data d-flex align-items-center">
                <img [src]="userdata?.profileimage" alt="Image">
                <div>
                    <h3>{{userdata?.firstname}}
                        {{userdata?.lastname}}</h3>
                    <p>{{ userId }}</p>
                </div>
            </div>
            <div *ngIf="userviewdata">
                <!-- <div *ngif="showuserlist"> -->
                <div *ngIf="hidebox == false">
                    <h3 class="viewed-product">viewed products</h3>
                </div>
                <div *ngIf="hidebox == true">
                    <h3 class="viewed-product">Date & time</h3>
                </div>
                <div class="product-viewed-wrap">
                    <div *ngIf="hidebox == false">
                        <div
                            *ngFor="let product of userviewdata; let i = index">
                            <div
                                class="main-viewed-product-section">
                                <img
                                    [src]="product.MainImage"
                                    alt="Image">
                                <div class="viewed-product-details">
                                    <h3>{{ product.Name
                                        }}</h3>
                                    <h5>{{ product.Brand
                                        }}</h5>
                                    <!-- {{ product.lastViewed | date: 'dd/MM/yyyy HH:mm:ss' }} -->
                                    <div class="d-flex align-items-center ">
                                        <p>User viewed this product : {{
                                            product.lastViewed.length
                                            }} times</p>
                                        <button type="button"
                                            class="preview"
                                            (click)="toggleuserlist(product,i)">
                                            <img
                                                src="../../../../assets/images/preview.svg"
                                                style="height: fit-content;" />
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div *ngIf="hidebox == true">
                        <div *ngIf="userviewdata.length > selectedIndex">
                            <p class="ml-5"
                                *ngFor="let userviewdata of userviewdata[selectedIndex].lastViewed">
                                {{ userviewdata |
                                date:
                                'dd/MM/yyyy HH:mm:ss' }}
                            </p>
                        </div>
                    </div>
                    <!-- Add more details as needed -->
                </div>
                <!-- </div> -->

                <!-- <div>
                    
                        <p *ngFor="let viewedDate of product.lastViewed">{{
                            viewedDate | date: 'dd/MM/yyyy HH:mm:ss'}}</p>
                    </div>

                </div> -->

            </div>
        </div>
        <div class="loader-overlay"
            style="height: 100%; align-items: center;"
            [class.showloader]="popupLoading">
            <div class="loader"></div>
        </div>

        <!-- <div class="invoice-row"> -->
        <div *ngIf="popupLoading === false">
            <nav aria-label="Page navigation example"
                class=" flex align-items-center justify-content-end">
                <ul class="pagination justify-content-end pt-2">
                    <li class="page-item">
                        <button type="button"
                            (click)="prev(); getUserData(pageuserid,currentPage);"
                            [disabled]="currentPage === 1"
                            class="page-link p-2"
                            aria-label="Previous">
                            <span aria-hidden="true">&laquo;</span>
                            <span class="sr-only">Previous</span>
                        </button>
                    </li>
                    <li class="page-item"
                        *ngFor="let page of totalPagesArray">
                        <button type="button" class="page-link p-2"
                            [class.active]="true"
                            (click)="goToPage(currentPage)">{{ currentPage
                            }}</button>
                    </li>
                    <li class="page-item">
                        <button type="button"
                            (click)="next(); getUserData(pageuserid,currentPage);"
                            [disabled]="currentPage === totalPages"
                            class="page-link p-2" aria-label="Next">
                            <span aria-hidden="true">&raquo;</span>
                            <span class="sr-only">Next</span>
                        </button>
                    </li>
                </ul>
            </nav>
        </div>
        <!-- </div> -->
        <!-- </div> -->
        <button class="close-btn"
            (click)="closePopup()">
            <svg width="9px" aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg" fill="none"
                viewBox="0 0 14 14"><path stroke="currentColor"
                    stroke-linecap="round" stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"></path></svg>
        </button>
        <!-- </div> -->
    </div>

</div>
