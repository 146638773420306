import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import * as chartData from '../../shared/data/chart';
import { doughnutData, pieData } from '../../shared/data/chart';
import { ApiService } from '../../shared/service/api.service';
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { yymmwise, datewise, SaleOrderCount } from '../../shared/models/datamodel';
import * as Chart from 'chart.js';



@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})


export class DashboardComponent implements OnInit {
  [x: string]: any;
  public lastmonthname: any;
  public flag: boolean
  public doughnutData = doughnutData;
  public pieData = pieData;
  public name = {};
  public user = [];
  public MostSharedProduct = [];
  public RawMostSharedProduct = [];
  public MostLikedProduct = [];
  public MostLikedProductCount: any;
  public MostSharedProductCount: any;
  public RawMostLikedProduct = [];
  public Id: String;
  public lastmonth: String;
  public secondlastmonth: String;
  public sellerdata = [];
  public admindata = [];
  public latestsaledata = [];
  public latestsaleorderdata = [];
  public id: any;
  public ordercount = []
  public saleamountchannelwise = [];
  public last10orders = [];
  public todayorder = [];
  public weeklyorder = [];
  public monthorder = [];
  public monthwiseaddproduct = [];
  public monthwisesaleorder = [];
  public sixmonthorder = [];
  public todayordercount: any;
  public weeklyordercount: any;
  public monthordercount: any;
  public sixmonthordercount: any;
  public saleamountmonthwise = [];
  public amountmonthwise = [];
  public totalcount: String;
  public activecount: string;
  public inactivecount: String;
  public Amazon: any;
  public Jesssu: any;
  public Flipkart: any;
  public Meesho: any;
  public Snapdeal: any;
  public Market: any;
  public sum: Number;
  public addproductcount30dayscount: any;
  public addproductcount30days = [];
  public chartdata: any;
  public mainmonthwisesale = [];
  public mainmonthwiseaddproduct = [];
  public popularproducts = [];
  public userviewdata = [];
  public sellerscrapedata = [];
  public sellerimportdata = [];
  public userdata: any = {};
  public sharedproduct = [];
  yymmwise = new yymmwise();
  datewise = new datewise();
  public orderform: FormGroup;
  public dateform: FormGroup;
  public yearlyform: FormGroup;
  public boxproductSKU = []
  public boxwiseproductcount = [];
  showPopup: boolean = false;
  userDetailsVisibility: boolean[] = [];
  // showtab: boolean = false;
  selectedTab: string = 'Tab1';
  isLoading: boolean = false;
  isSharedLoading: boolean = false;
  isScrapeLoading: boolean = false;
  isExportLoading: boolean = false;
  popupLoading: boolean = false;
  hidebox: boolean = false;
  currentPage = 1;
  totalPages = 4;
  totalPagesArray: number[];
  categorychartReady: boolean = false;
  productchartReady = false;
  @ViewChild('chartCanva', { static: true }) chartCanva: ElementRef;
  @ViewChild('chartCanvas', { static: true }) chartCanvas: ElementRef;
  categoryData: any;

  chart: any;
  options = ['daily', 'weekly', 'monthly'];
  selectedOption = 'daily';
  // manufacturer = 'Flipkart'; // Default manufacturer
  startDate: string = '';
  endDate: string = '';
  years = [
    { id: 2010, name: "2010" },
    { id: 2011, name: "2011" },
    { id: 2012, name: "2012" },
    { id: 2013, name: "2013" },
    { id: 2014, name: "2014" },
    { id: 2015, name: "2015" },
    { id: 2016, name: "2016" },
    { id: 2017, name: "2017" },
    { id: 2018, name: "2018" },
    { id: 2019, name: "2019" },
    { id: 2020, name: "2020" },
    { id: 2021, name: "2021" },
    { id: 2022, name: "2022" },
    { id: 2023, name: "2023" },
    { id: 2024, name: "2024" },
    { id: 2025, name: "2025" },
    { id: 2026, name: "2026" },
    { id: 2027, name: "2027" },
    { id: 2028, name: "2028" },
    { id: 2029, name: "2029" },
    { id: 2030, name: "2030" },
    { id: 2031, name: "2031" },
    { id: 2032, name: "2032" },
    { id: 2033, name: "2033" },
    { id: 2034, name: "2034" },
    { id: 2035, name: "2035" },
  ];
  months = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" }
  ];

  public searchtext: any;
  public searchdata: any;
  public subscriptionplan: any;
  public datapayload = {
    todate: new Date(Date.now()),
    fromdate: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
  }
  constructor(private apiService: ApiService, private router: Router,) {

    this.yearlyform = new FormGroup({
      year: new FormControl(),
      month: new FormControl()
    });

    this.dateform = new FormGroup({
      date1: new FormControl(),
      date2: new FormControl()
    });

    Object.assign(this, { doughnutData, pieData })
    this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"));
    this.admindata = JSON.parse(window.localStorage.getItem("admin-info"));
    this.subscriptionplan = JSON.parse(window.localStorage.getItem("SUBSCRIPTIONPLAN"));
    if (this.admindata == null && this.sellerdata == null) {
      this.router.navigate(["'/auth/login'"])
    }
    else {
      if (this.admindata == null) {
        if (this.sellerdata[0].accountstatus == "Secondery") {
          let status = this.sellerdata[0].roleaccess.includes("Dashboard")
          if (status == false) {
            this.flag = false;
          } else {
            this.flag = true;
            // this.getgraphdataforproductaddvssale()
            this.getchartvalue();
            this.getcount();
            this.getreport(this.datapayload);
            // this.getchannelwisesaleamount(this.datapayload);
            // this.getnewVerisonreportforOrder(this.datapayload)
            // this.getlatestsaledata();
            // this.getlatestsaleorderdata();
            // this.getmonthwisesaleamount();
            // this.getproductlistcountdata();
          }
        }
        else if (this.subscriptionplan == "Free") {
          this.flag = false;
        }
        else if (this.subscriptionplan == "Starter") {
          this.flag = false;
        }
        else if (this.subscriptionplan == "Professional") {
          this.flag = true;
          // this.getgraphdataforproductaddvssale()
          // this.getchartvalue();
          this.getcount();
          this.getreport(this.datapayload);
          this.getchannelwisesaleamount(this.datapayload);
          this.getnewVerisonreportforOrder(this.datapayload)
          // this.getlatestsaledata();
          // this.getlatestsaleorderdata();
          // this.getmonthwisesaleamount();
          // this.getproductlistcountdata();
        }
        else if (this.subscriptionplan == "Enterprise") {
          this.flag = true;
          // this.getgraphdataforproductaddvssale()
          this.getchartvalue();
          this.getcount();
          this.getreport(this.datapayload);
          this.getchannelwisesaleamount(this.datapayload);
          this.getnewVerisonreportforOrder(this.datapayload)
          // this.getlatestsaledata();
          // this.getlatestsaleorderdata();
          // this.getmonthwisesaleamount();
          // this.getproductlistcountdata();
        }
        // else {
        //   this.flag = true;
        //   this.getgraphdataforproductaddvssale()
        //   this.getchartvalue();
        //   this.getcount();
        //   this.getreport(this.datapayload);
        //   this.getchannelwisesaleamount(this.datapayload);
        //   this.getnewVerisonreportforOrder(this.datapayload)
        //   this.getlatestsaledata();
        //   this.getlatestsaleorderdata();
        //   this.getmonthwisesaleamount();
        //   this.getproductlistcountdata();
        // }
      }

    }

  }

  // doughnut 2
  public view = chartData.view;
  public doughnutChartColorScheme = chartData.doughnutChartcolorScheme;
  public doughnutChartShowLabels = chartData.doughnutChartShowLabels;
  public doughnutChartGradient = chartData.doughnutChartGradient;
  public doughnutChartTooltip = chartData.doughnutChartTooltip;

  public chart5 = chartData.chart5;


  // lineChart
  public lineChartData = chartData.lineChartData;
  public lineChartLabels = chartData.lineChartLabels;
  public lineChartOptions = chartData.lineChartOptions;
  public lineChartColors = chartData.lineChartColors;
  public lineChartLegend = chartData.lineChartLegend;
  public lineChartType = chartData.lineChartType;

  // lineChart
  public smallLineChartData = chartData.smallLineChartData;
  public smallLineChartLabels = chartData.smallLineChartLabels;
  public smallLineChartOptions = chartData.smallLineChartOptions;
  public smallLineChartColors = chartData.smallLineChartColors;
  public smallLineChartLegend = chartData.smallLineChartLegend;
  public smallLineChartType = chartData.smallLineChartType;

  // lineChart
  public smallLine2ChartData = chartData.smallLine2ChartData;
  public smallLine2ChartLabels = chartData.smallLine2ChartLabels;
  public smallLine2ChartOptions = chartData.smallLine2ChartOptions;
  public smallLine2ChartColors = chartData.smallLine2ChartColors;
  public smallLine2ChartLegend = chartData.smallLine2ChartLegend;
  public smallLine2ChartType = chartData.smallLine2ChartType;

  // lineChart
  public smallLine3ChartData = chartData.smallLine3ChartData;
  public smallLine3ChartLabels = chartData.smallLine3ChartLabels;
  public smallLine3ChartOptions = chartData.smallLine3ChartOptions;
  public smallLine3ChartColors = chartData.smallLine3ChartColors;
  public smallLine3ChartLegend = chartData.smallLine3ChartLegend;
  public smallLine3ChartType = chartData.smallLine3ChartType;

  // lineChart
  public smallLine4ChartData = chartData.smallLine4ChartData;
  public smallLine4ChartLabels = chartData.smallLine4ChartLabels;
  public smallLine4ChartOptions = chartData.smallLine4ChartOptions;
  public smallLine4ChartColors = chartData.smallLine4ChartColors;
  public smallLine4ChartLegend = chartData.smallLine4ChartLegend;
  public smallLine4ChartType = chartData.smallLine4ChartType;

  public chart3 = chartData.chart3;
  public displayvalue = "Order summary and sell earning data is showing for last 1 month"



  // events
  public chartClicked(e: any): void {
  }
  public chartHovered(e: any): void {
  }

  ngOnInit() {
    const seller = JSON.parse(window.localStorage.getItem("seller-info"));

    if (seller && seller.length > 0) {
      const manufacturer = seller[0].manufacturer;
      this.getPopulerProduct(manufacturer);
      this.getSharedProduct(manufacturer);
      
    } else {
      console.error("User information not found in local storage.");
    }

    this.getsellerscrapeData();
    this.getsellerimportData();
    // this.smallLineChartData =
    //   [
    //     { data: this.amountmonthwise },
    //   ];

    // this.lineChartData = [
    //   { data: [2000, 5000, 2000, 7000, 3000, 2700] },
    //   { data: [0, 3000, 4000, 1000, 8600, 4000] },
    //   { data: this.amountmonthwise },
    // ];

    // // this.getmonthwisesaleamount();
    // this.sum = (2020.59 + 39393.32);
    this.user = JSON.parse(window.localStorage.getItem("seller-info"));
   
    if (this.user) {
      this.name = (this.user[0].firstname + '' + this.user[0].lastname)
    }
    else {
      this.name = "User not selected"
    }


    // this.totalPagesArray = Array(this.totalPages).fill(0).map((x, i) => i + 1);
    this.totalPagesArray = [this.currentPage];

    this.sellertoken = JSON.parse(window.localStorage.getItem("TOKEN"));
    const manufacturer = seller[0].manufacturer;

    let payload = {
      "manufacturer": manufacturer,
      "token": this.sellertoken
    }
    // this.totalPagesArray = Array.from({ length: this.totalPages }, (_, i) => i + 1);
    this.apiService.getCategoryStatus(payload).subscribe(data => {
      // this.categoryStats = data;
      this.categoryData = data;
      if (this.chartCanva) {
        this.renderChart_category(this.categoryData);
      }
      // this.categorychartReady =true;
    });
     
    let payload_data = { "manufacturer":  manufacturer,
      "startDate": "2024-08-01T00:00:00.000Z",
      "endDate": "2024-12-31T23:59:59.999Z"
    }
    
    this.apiService.getDatewiseStatus(payload_data).subscribe(data => {
      // this.datewiseStatus = data;
      this.renderChart_product(data);
    })

    
    this.setDefaultDates();
  
    
  }

  // ngAfterViewInit() {
  //   if (this.categoryData) {
  //     this.renderChart_category(this.categoryData);
  //   }
  // }
  // ngAfterViewInit() {
  //   this.chart = new Chart(this.myChart.nativeElement, {
  //     type: 'bar',
  //     data: {
  //       labels: ['The Crochet Corner', 'The Crochet Corner', 'The Crochet Corner', 'The Crochet Corner', 'The Crochet Corner', 'The Crochet Corner'],
  //       datasets: [{
  //         // label: ,
  //         data: [12, 19, 3, 5, 2, 3],
  //         backgroundColor: [
  //           'rgba(255, 99, 132, 0.2)',
  //           'rgba(54, 162, 235, 0.2)',
  //           'rgba(255, 206, 86, 0.2)',
  //           'rgba(75, 192, 192, 0.2)',
  //           'rgba(153, 102, 255, 0.2)',
  //           'rgba(255, 159, 64, 0.2)'
  //         ],
  //         borderColor: [
  //           'rgba(255, 99, 132, 1)',
  //           'rgba(54, 162, 235, 1)',
  //           'rgba(255, 206, 86, 1)',
  //           'rgba(75, 192, 192, 1)',
  //           'rgba(153, 102, 255, 1)',
  //           'rgba(255, 159, 64, 1)'
  //         ],
  //         borderWidth: 1
  //       }]
  //     },
  //     options: {
  //       scales: {
  //         yAxes: [{
  //           ticks: {
  //             beginAtZero: true
  //           }
  //         }]
  //       }
  //     }
  //   });
  // }




  prev() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  next() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }

  goToPage(page: number) {
    this.currentPage = page;
  }


  toggleuserlist(product: any, index: number) {
    this.hidebox = true;
    this.selectedProduct = product;
    this.selectedIndex = index;
    // this.specificProduct = this.userviewdata[index];

  }


  newtogglePopup(product: any) {
    this.popularproducts.forEach(i => {
      if (i.productdetails.Name == product.productdetails.Name) {
        i.openPopup = !i.openPopup
      } else {
        i.openPopup = false
      }
    })
  }

  togglePopup(product: any, userid: string) {
    this.showPopup = !this.showPopup;
    this.pageuserid = userid;
    if (this.showPopup) {
      this.selectedProduct = product;
    }
  }

  toggleUserDetails(index: number) {
    this.userDetailsVisibility[index] = !this.userDetailsVisibility[index];
  }

  closePopup() {
    this.showPopup = false;
    this.hidebox = false;

  }

  removeDuplicates(userIds: string[]): string[] {
    if (!userIds) return [];
    return Array.from(new Set(userIds));
  }

  // removeDuplicates(userIds: string[]): { userId: string, count: number }[] {
  //   if (!userIds) return [];

  //   const counts: { [userId: string]: number } = {};
  //   userIds.forEach(userId => {
  //     counts[userId] = (counts[userId] || 0) + 1;
  //   });

  //   const uniqueUserIds = Array.from(new Set(userIds));
  //   // console.log(uniqueUserIds);
  //   return uniqueUserIds.map(userId => ({ userId, count: counts[userId] }));
  // }

  // uniqueUsersData = this.removeDuplicates(product?.usersid || []);





  getUserData(userId: string, page: number) {
    this.popupLoading = true;

    const seller = JSON.parse(window.localStorage.getItem("seller-info"));
    this.sellertoken = JSON.parse(window.localStorage.getItem("TOKEN"));
    const manufacturer = seller[0].manufacturer;
    const limit = 5

    let payload = {
      "userid": userId,
      "manufacturer": manufacturer,
      "page": page,
      "limit": limit,
      "token": this.sellertoken
    }

    console.log(payload)
    this.apiService.getuserviewddata(payload)
      .subscribe((res: any) => {
        this.userviewdata = res.userdata.products;
        const totalItems = res.userdata.product_length; // Assuming totalItems is the property in the response indicating the total number of items
        console.log(totalItems);
        this.totalPages = Math.ceil(totalItems / limit);
        this.userdata = res.userdata;
        this.popupLoading = false;
      })
  }


  onSubmit(form) {

    console.log(form.value.date1.month + "." + form.value.date1.day + "." + form.value.date1.year, form.value.date2.month + "." + form.value.date2.day + "." + form.value.date2.year)
    this.displayvalue = `Order summary and sell earning data is showing form ${form.value.date1.month + "." + form.value.date1.day + "." + form.value.date1.year} to ${form.value.date2.month + "." + form.value.date2.day + "." + form.value.date2.year}`

    let payload = {
      fromdate: form.value.date1.month + "." + form.value.date1.day + "." + form.value.date1.year,
      todate: form.value.date2.month + "." + form.value.date2.day + "." + form.value.date2.year
    }
    this.getchannelwisesaleamount(payload)
    this.getnewVerisonreportforOrder(payload)
    this.getreport(payload)

  }
  onSubmit1() {

    if (this.yearlyform.value.year != null) {
      if (this.yearlyform.value.month == null) {
        console.log("01" + "." + "01" + "." + this.yearlyform.value.year, "12" + "." + "30" + "." + this.yearlyform.value.year)
        this.displayvalue = `Order summary and sell earning data is showing for the year of ${this.yearlyform.value.year}`
        let payload = {
          todate: "12" + "." + "30" + "." + this.yearlyform.value.year,
          fromdate: "01" + "." + "01" + "." + this.yearlyform.value.year
        }
        this.getchannelwisesaleamount(payload)
        this.getreport(payload)
        this.getnewVerisonreportforOrder(payload)
      }
      else {
        this.displayvalue = `Order summary and sell earning data is showing for the year ${this.yearlyform.value.year}, month ${this.yearlyform.value.month}`
        console.log(this.yearlyform.value.month + "." + "1" + "." + this.yearlyform.value.year, this.yearlyform.value.month + "." + "30" + "." + this.yearlyform.value.year)
        let payload = {
          todate: this.yearlyform.value.month + "." + "30" + "." + this.yearlyform.value.year,
          fromdate: this.yearlyform.value.month + "." + "1" + "." + this.yearlyform.value.year
        }
        this.getchannelwisesaleamount(payload)
        this.getreport(payload)
        this.getnewVerisonreportforOrder(payload)

      }
    }
    else {
      alert("Please select an year")
    }
  }

  getPopulerProduct(manufacturer) {
    this.isLoading = true;
    this.sellertoken = JSON.parse(window.localStorage.getItem("TOKEN"));
    console.log(this.sellertoken)
    let payload = {
      "manufacturer": manufacturer,
      "token": this.sellertoken
    }
    console.log(payload)
    this.apiService.getpopularproduct(payload)
      .subscribe((res: any[]) => {
        res.forEach(i => {
          i.openPopup = false
        })
        this.popularproducts = res;
        this.isLoading = false;

      },
      error => {
        this.isLoading = false;
      })

  }

  getSharedProduct(manufacturer) {
    this.isSharedLoading = true;
    this.sellertoken = JSON.parse(window.localStorage.getItem("TOKEN"));
    let payload = {
      "manufacturer": manufacturer,
      "token": this.sellertoken
    }
    console.log(payload)
    this.apiService.getsharedproduct(payload)
      .subscribe((res: any[]) => {
        this.sharedproduct = res;
        this.isSharedLoading = false;
      },
      error => {
        this.isSharedLoading = false;
      })

  }

  // getgraphdataforproductaddvssale() {
  //   this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"));
  //   this.monthwisesaleorder = [];
  //   this.monthwiseaddproduct = [];
  //   if (this.sellerdata != null) {
  //     let brandname = this.sellerdata[0].manufacturer;
  //     let payload = {
  //       "year": new Date().getFullYear(),
  //       "userid": brandname
  //     }
  //     console.log(payload)
  //     // this.apiService.getaddproductcountuserwisemonthwise(payload)
  //     //   .subscribe((res: any[]) => {
  //     //     this.mainmonthwiseaddproduct = res;
  //     //     res.forEach(i => {
  //     //       this.monthwiseaddproduct.push(i.data)
  //     //     })

  //     //     this.apiService.getordercountuserwisemonthwise(payload)
  //     //       .subscribe((res: any) => {
  //     //         this.mainmonthwisesale = res;
  //     //         res.forEach(i => {
  //     //           this.monthwisesaleorder.push(i.data)
  //     //         })
  //     //         this.getchartvalue();
  //     //         this.secondlastmonthname = this.mainmonthwisesale[this.mainmonthwisesale.length - 2];
  //     //         this.lastmonthname = this.mainmonthwisesale[this.mainmonthwisesale.length - 1];
  //     //         if (this.lastmonthname != undefined) {
  //     //           if (this.lastmonthname._id == 1) {
  //     //             this.lastmonth = "Jan";
  //     //           }
  //     //           else if (this.lastmonthname._id == 2) {
  //     //             this.lastmonth = "Feb";
  //     //           } else if (this.lastmonthname._id == 3) {
  //     //             this.lastmonth = "Mar";
  //     //           } else if (this.lastmonthname._id == 4) {
  //     //             this.lastmonth = "Apr";
  //     //           } else if (this.lastmonthname._id == 5) {
  //     //             this.lastmonth = "May";
  //     //           } else if (this.lastmonthname._id == 6) {
  //     //             this.lastmonth = "Jun";
  //     //           } else if (this.lastmonthname._id == 7) {
  //     //             this.lastmonth = "Jul";
  //     //           } else if (this.lastmonthname._id == 8) {
  //     //             this.lastmonth = "Aug";
  //     //           } else if (this.lastmonthname._id == 9) {
  //     //             this.lastmonth = "Sep";
  //     //           } else if (this.lastmonthname._id == 10) {
  //     //             this.lastmonth = "Oct";
  //     //           }
  //     //           else if (this.lastmonthname._id == 11) {
  //     //             this.lastmonth = "Nov";
  //     //           }
  //     //           else if (this.lastmonthname._id == 12) {
  //     //             this.lastmonth = "Dec";
  //     //           }
  //     //         }
  //     //         console.log(this.lastmonth)

  //     //         if (this.secondlastmonthname != undefined) {
  //     //           if (this.secondlastmonthname._id == 1) {
  //     //             this.secondlastmonth = "Jan";
  //     //           }
  //     //           else if (this.secondlastmonthname._id == 2) {
  //     //             this.secondlastmonth = "Feb";
  //     //           } else if (this.secondlastmonthname._id == 3) {
  //     //             this.secondlastmonth = "Mar";
  //     //           } else if (this.secondlastmonthname._id == 4) {
  //     //             this.secondlastmonth = "Apr";
  //     //           } else if (this.secondlastmonthname._id == 5) {
  //     //             this.secondlastmonth = "May";
  //     //           } else if (this.secondlastmonthname._id == 6) {
  //     //             this.secondlastmonth = "Jun";
  //     //           } else if (this.secondlastmonthname._id == 7) {
  //     //             this.secondlastmonth = "Jul";
  //     //           } else if (this.secondlastmonthname._id == 8) {
  //     //             this.secondlastmonth = "Aug";
  //     //           } else if (this.secondlastmonthname._id == 9) {
  //     //             this.secondlastmonth = "Sep";
  //     //           } else if (this.secondlastmonthname._id == 10) {
  //     //             this.secondlastmonth = "Oct";
  //     //           }
  //     //           else if (this.secondlastmonthname._id == 11) {
  //     //             this.secondlastmonth = "Nov";
  //     //           }
  //     //           else if (this.secondlastmonthname._id == 12) {
  //     //             this.secondlastmonth = "Dec";
  //     //           }
  //     //         }
  //     //         console.log(this.secondlastmonth)

  //     //       });
  //     //   });

  //   }
  //   else {
  //     let payload = { "year": new Date().getFullYear() }
  //     console.log(payload)
  //     this.apiService.getaddproductcountallmonthwise(payload)
  //       .subscribe((res: any) => {
  //         this.mainmonthwiseaddproduct = res;
  //         res.forEach(i => {
  //           this.monthwiseaddproduct.push(i.data)
  //         })

  //         this.apiService.getordercountallmonthwise(payload)
  //           .subscribe((res: any) => {
  //             this.mainmonthwisesale = res;
  //             res.forEach(i => {
  //               this.monthwisesaleorder.push(i.data)
  //             })
  //             this.getchartvalue();
  //             this.secondlastmonthname = this.mainmonthwiseaddproduct[this.mainmonthwiseaddproduct.length - 2];
  //             this.lastmonthname = this.mainmonthwisesale[this.mainmonthwisesale.length - 1];

  //             if (this.lastmonthname._id == 0) {
  //               this.lastmonth = "Jan";
  //             }
  //             else if (this.lastmonthname._id == 1) {
  //               this.lastmonth = "Feb";
  //             } else if (this.lastmonthname._id == 2) {
  //               this.lastmonth = "Mar";
  //             } else if (this.lastmonthname._id == 3) {
  //               this.lastmonth = "Apr";
  //             } else if (this.lastmonthname._id == 4) {
  //               this.lastmonth = "May";
  //             } else if (this.lastmonthname._id == 5) {
  //               this.lastmonth = "Jun";
  //             } else if (this.lastmonthname._id == 6) {
  //               this.lastmonth = "Jul";
  //             } else if (this.lastmonthname._id == 7) {
  //               this.lastmonth = "Aug";
  //             } else if (this.lastmonthname._id == 8) {
  //               this.lastmonth = "Sep";
  //             } else if (this.lastmonthname._id == 9) {
  //               this.lastmonth = "Oct";
  //             }
  //             else if (this.lastmonthname._id == 10) {
  //               this.lastmonth = "Nov";
  //             }
  //             else if (this.lastmonthname._id == 11) {
  //               this.lastmonth = "Dec";
  //             }
  //             console.log(this.lastmonth)


  //             if (this.secondlastmonthname._id == 0) {
  //               this.secondlastmonth = "Jan";
  //             } else if (this.secondlastmonthname._id == 1) {
  //               this.secondlastmonth = "Feb";
  //             } else if (this.secondlastmonthname._id == 2) {
  //               this.secondlastmonth = "Mar";
  //             } else if (this.secondlastmonthname._id == 3) {
  //               this.secondlastmonth = "Apr";
  //             } else if (this.secondlastmonthname._id == 4) {
  //               this.secondlastmonth = "May";
  //             } else if (this.secondlastmonthname._id == 5) {
  //               this.secondlastmonth = "Jun";
  //             } else if (this.secondlastmonthname._id == 6) {
  //               this.secondlastmonth = "Jul";
  //             } else if (this.secondlastmonthname._id == 7) {
  //               this.secondlastmonth = "Aug";
  //             } else if (this.secondlastmonthname._id == 8) {
  //               this.secondlastmonth = "Sep";
  //             } else if (this.secondlastmonthname._id == 9) {
  //               this.secondlastmonth = "Oct";
  //             }
  //             else if (this.secondlastmonthname._id == 10) {
  //               this.secondlastmonth = "Nov";
  //             }
  //             else if (this.secondlastmonthname._id == 11) {
  //               this.secondlastmonth = "Dec";
  //             }
  //             console.log(this.secondlastmonth)
  //           });
  //       });

  //   }
  // }
  getchartvalue() {
    console.log(this.monthwisesaleorder)
    console.log(this.monthwiseaddproduct)
    this.chartdata = {
      type: 'Bar',
      data: {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        series: [
          this.monthwisesaleorder,
          this.monthwiseaddproduct
        ]
      },
      options: {
        height: 303,
        seriesBarDistance: 12,
        axisX: {
          showGrid: false,
          labelInterpolationFnc: function (value) {
            return value[0];
          }
        }
      },
      events: {
        created: (data) => {

        }
      }
    }
  }



  getcount() {
    this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"));
    if (this.sellerdata != null) {
      this.id = this.sellerdata[0].manufacturer;
      // this.apiService.getactiveproductcount(this.id)
      //   .subscribe((res: any) => this.activecount = res);
      // this.apiService.getinactiveproductcount(this.id)
      //   .subscribe((res: any) => this.inactivecount = res);
    }
    else {
      this.apiService.getactiveproductcountall()
        .subscribe((res: any) => this.activecount = res);
      this.apiService.getinactiveproductcountall()
        .subscribe((res: any) => this.inactivecount = res);
    }

  }


  getreport(payload) {

    this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"));
    if (this.sellerdata != null) {
      // this.Id = this.sellerdata[0].manufacturer;
      // this.apiService.getAmazonsalescountbyId(this.Id, payload)
      //   .subscribe((data: any) => this.Amazon = data);
      // this.apiService.getFlipkartsalescountbyId(this.Id, payload)
      //   .subscribe((data: any) => this.Flipkart = data);
      // this.apiService.getSnapdealsalescountbyId(this.Id, payload)
      //   .subscribe((data: any) => this.Snapdeal = data);
      // this.apiService.getMeeshosalescountbyId(this.Id, payload)
      //   .subscribe((data: any) => this.Meesho = data);
      // this.apiService.getMarketsalescountbyId(this.Id, payload)
      //   .subscribe((data: any) => this.Market = data);
      // this.apiService.getJesssusalescountbyId(this.Id, payload)
      //   .subscribe((data: any) => this.Jesssu = data);
      // this.apiService.getlatestsaleorderdatauserwise(this.Id, payload)
      //   .subscribe((response: any) => this.last10orders = response);
    }
    else {
      // this.apiService.getAmazonsalescountall(payload)
      //   .subscribe((data: any) => this.Amazon = data);
      // this.apiService.getFlipkartsalescountall(payload)
      //   .subscribe((data: any) => this.Flipkart = data);
      // this.apiService.getSnapdealsalescountall(payload)
      //   .subscribe((data: any) => this.Snapdeal = data);
      // this.apiService.getMeeshosalescountall(payload)
      //   .subscribe((data: any) => this.Meesho = data);
      // this.apiService.getMarketsalescountall(payload)
      //   .subscribe((data: any) => this.Market = data);
      // this.apiService.getJesssusalescountall(payload)
      //   .subscribe((data: any) => this.Jesssu = data);
      // this.apiService.getlatestsaleorderdataall(payload)
      //   .subscribe((response: any) => this.last10orders = response);
    }
  }

  getnewVerisonreportforOrder(payload: any) {

    this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"));
    if (this.sellerdata != null) {
      this.Id = this.sellerdata[0].manufacturer;
      // this.apiService.getsaleCountforUser(payload, this.Id).subscribe((res: [any]) => {
      //   this.ordercount = res
      // })
    }
    else {
      // this.apiService.getsaleCountforall(payload).subscribe((res: [any]) => {
      //   this.ordercount = res
      // })

    }
  }


  getchannelwisesaleamount(payload) {
    this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"));

    if (this.sellerdata != null) {



      this.Id = this.sellerdata[0].manufacturer;
      // this.apiService.getchannelwisesaleamount(this.Id, payload)
      //   .subscribe((data: any[]) => this.saleamountchannelwise = data);
    }
    else {
      // this.apiService.getchannelwisesaleamountall(payload)
      //   .subscribe((data: any[]) => this.saleamountchannelwise = data);
    }
  }

  // getmonthwisesaleamount() {
  //   this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"));
  //   if (this.sellerdata != null) {
  //     this.Id = this.sellerdata[0].manufacturer;
  //     this.apiService.getmonthwisesaleamount(this.Id)
  //       .subscribe((data: any[]) => {
  //         this.saleamountmonthwise = data;
  //         this.saleamountmonthwise.forEach((i) => {
  //           let amount = (i.Amount).toString();
  //           this.amountmonthwise.push(amount)


  //         })
  //         // console.log(this.amountmonthwise);
  //         this.lastmonthsaleamount = this.amountmonthwise[this.amountmonthwise.length - 1];
  //         this.secondlastmonthsaleamount = this.amountmonthwise[this.amountmonthwise.length - 2];

  //       });
  //   }
  // }

  // getproductlistcountdata() {
  //   this.user = JSON.parse(window.localStorage.getItem("seller-info"));
  //   if (this.user != null) {
  //     this.Id = this.user[0].manufacturer;
  //     this.apiService.getaddproductcountuserwise(this.Id)
  //       .subscribe((response: any[]) => {
  //         this.addproductcount30days = response
  //         this.addproductcount30dayscount = response.length
  //       })
  //   }
  //   else {
  //     this.apiService.getaddproductcountall()
  //       .subscribe((response: any[]) => {
  //         this.addproductcount30days = response
  //         this.addproductcount30dayscount = response.length
  //       })
  //   }
  // }

  click1() {
    this.router.navigateByUrl("/sales/transactions");
  }


  // getlatestsaledata() {
  //   this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"));
  //   if (this.sellerdata != null) {
  //     this.id = this.sellerdata[0].manufacturer;
  //     // this.apiService.getlatestsaledata(this.id)
  //       .subscribe((salelist: any[]) => {

  //         this.latestsaledata = salelist
  //       });
  //     console.log('latestsaledata', this.latestsaledata)
  //   }
  //   else {
  //     this.apiService.getlatestsaledataall()
  //       .subscribe((salelist: any[]) => this.latestsaledata = salelist);
  //     console.log('latestsaledata else', this.latestsaledata)
  //   }
  // }
  getlatestsaleorderdata() {
    this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"));
    // this.id = this.sellerdata[0]._id;
    // this.apiService.getlatestsaleorderdata(this.id)
    //   .subscribe((saleorderlist: any[]) => this.latestsaleorderdata = saleorderlist);
    if (this.sellerdata != null) {
      this.id = this.sellerdata[0].manufacturer;
      const payloadfortoday = {
        "userid": this.id,
        "status": "Day"
      }
      const payloadforweek = {
        "userid": this.id,
        "status": "Week"

      }
      const payloadformonth = {
        "userid": this.id,
        "status": "Month"

      }
      const payloadforsixmonth = {
        "userid": this.id,
        "status": "SixMonth"

      }

      // this.apiService.getorderdatamonthlyweeklytoday(payloadfortoday)
      //   .subscribe((res: any[]) => {
      //     this.todayorder = res;
      //     this.todayordercount = this.todayorder.length
      //   });
      // this.apiService.getorderdatamonthlyweeklytoday(payloadforweek)
      //   .subscribe((res: any[]) => {
      //     this.weeklyorder = res;
      //     this.weeklyordercount = this.weeklyorder.length
      //   });
      // this.apiService.getorderdatamonthlyweeklytoday(payloadformonth)
      //   .subscribe((res: any[]) => {
      //     this.monthorder = res;
      //     this.monthordercount = this.monthorder.length
      //   });
      // this.apiService.getorderdatamonthlyweeklytoday(payloadforsixmonth)
      //   .subscribe((res: any[]) => {
      //     this.sixmonthorder = res;
      //     this.sixmonthordercount = this.sixmonthorder.length
      //   });
    }
    else {
      const payloadfortoday = {
        "status": "Day"


      }
      const payloadforweek = {
        "status": "Week"

      }
      const payloadformonth = {
        "status": "Month"

      }
      const payloadforsixmonth = {
        "status": "SixMonth"

      }

      this.apiService.getorderdataforallmonthlyweeklytoday(payloadfortoday)
        .subscribe((res: any[]) => {
          this.todayorder = res;
          this.todayordercount = this.todayorder.length
        });
      this.apiService.getorderdataforallmonthlyweeklytoday(payloadforweek)
        .subscribe((res: any[]) => {
          this.weeklyorder = res;
          this.weeklyordercount = this.weeklyorder.length
        });
      this.apiService.getorderdataforallmonthlyweeklytoday(payloadformonth)
        .subscribe((res: any[]) => {
          this.monthorder = res;
          this.monthordercount = this.monthorder.length
        });
      this.apiService.getorderdataforallmonthlyweeklytoday(payloadforsixmonth)
        .subscribe((res: any[]) => {
          this.sixmonthorder = res;
          this.sixmonthordercount = this.sixmonthorder.length
        });
    }

  }

  getsellerscrapeData() {
    this.isScrapeLoading = true;
    const seller = JSON.parse(window.localStorage.getItem("seller-info"));
    this.sellertoken = JSON.parse(window.localStorage.getItem("TOKEN"));
    const manufacturer = seller[0].manufacturer;
    const limit = 5

    let payload = {
      "pageSize ": limit,
      "page": 1,
      "channel": manufacturer,
      "token": this.sellertoken
    }
    this.apiService.getsellerscrapeData(payload).subscribe((result: any[]) => {
      this.sellerscrapedata = result;
      this.isScrapeLoading = false;
    },
    error => {
      this.isScrapeLoading = false;
    });
  }

  gotoproductlist() {
    this.router.navigateByUrl("/products/physical/product-list");
  }

  redirecttosale() {
    this.router.navigateByUrl("/sales/sale-data");
  }

  viewallsale() {
    this.router.navigate(['/sales/sale-data']);


  }

  getsellerimportData() {
    this.isExportLoading = true;
    const seller = JSON.parse(window.localStorage.getItem("seller-info"));
    this.sellertoken = JSON.parse(window.localStorage.getItem("TOKEN"));
    const email = seller[0].email;
    const limit = 10

    let payload = {
      "limit ": limit,
      "pageno": 1,
      "email": email,
      "token": this.sellertoken
    }
    this.apiService.getimportproductdetails(payload).subscribe((result: any[]) => {
      this.sellerimportdata = result;
      this.isExportLoading = false;
    },
    error => {
      this.isExportLoading = false;
    });
  }
 
  renderChart_category(data) {
    
    if (this.chart_category) {
      this.chart_category.destroy();
    }

    const labels = data.map(stat => stat.Primary);
    const likeCounts = data.map(stat => stat.likeCount);
    const shareCounts = data.map(stat => stat.shareCount);
    const viewCounts = data.map(stat => stat.viewCount);

    const ctx = this.chartCanva?.nativeElement.getContext('2d');
    if (ctx) {
      this.chart_category = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Likes',
              data: likeCounts,
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1,
            },
            {
              label: 'Shares',
              data: shareCounts,
              backgroundColor: 'rgba(153, 102, 255, 0.2)',
              borderColor: 'rgba(153, 102, 255, 1)',
              borderWidth: 1,
            },
            {
              label: 'Views',
              data: viewCounts,
              backgroundColor: 'rgba(255, 159, 64, 0.2)',
              borderColor: 'rgba(255, 159, 64, 1)',
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            xAxes: [{
              stacked: false,
            }],
            yAxes: [{
              stacked: false,
              ticks: {
                beginAtZero: true,
              },
            }],
          },
          responsive: true,
          legend: {
            position: 'top',
          },
          tooltips: {
            callbacks: {
              label: (tooltipItem, data) => {
                const datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
                return `${datasetLabel}: ${tooltipItem.yLabel}`;
              }
            }
          }
        }
      });

      this.categorychartReady = true;
    }
  }

  setDefaultDates() {
    const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
    this.startDate = today;
    this.endDate = today;
    this.updateChart(); // Initialize chart with today's date
  }

  onIntervalChange() {
    this.adjustDateRange();
    this.updateChart();
  }
  
  onDateChange() {
    const today = new Date().toISOString().split('T')[0];
  
    // Prevent the user from selecting a start date in the future
    if (this.startDate > today) {
      this.startDate = today;
    }
  
    if (this.selectedOption === 'daily') {
      this.endDate = this.startDate; // Ensure endDate matches startDate in daily mode
    }
  
    // Ensure endDate doesn't go beyond today
    if (this.endDate > today) {
      this.endDate = today;
    }
  
    this.updateChart();
  }
  
  adjustDateRange() {
    const start = new Date(this.startDate);
    const today = new Date();
  
    // Prevent the user from selecting a start date in the future
    if (start > today) {
      this.startDate = today.toISOString().split('T')[0];
    }
  
    switch (this.selectedOption) {
      case 'weekly':
        this.endDate = new Date(start.setDate(start.getDate() + 6)).toISOString().split('T')[0];
        break;
      case 'monthly':
        this.endDate = new Date(start.setMonth(start.getMonth() + 1, start.getDate() - 1)).toISOString().split('T')[0];
        break;
      case 'daily':
        this.endDate = this.startDate; // Ensure endDate is the same as startDate in daily mode
        break;
    }
  
    // If the calculated endDate is later than today, set it to today
    if (new Date(this.endDate) > today) {
      this.endDate = today.toISOString().split('T')[0];
    }
  }
  
  updateChart() {
    const seller = JSON.parse(window.localStorage.getItem("seller-info"));
    const manufacturer = seller[0].manufacturer;
  
    // Format dates to the required ISO format
    const formattedStartDate = new Date(this.startDate).toISOString().split('T')[0] + "T00:00:00.000Z";
    const formattedEndDate = new Date(this.endDate).toISOString().split('T')[0] + "T23:59:59.999Z";
  
    const payload = {
     "manufacturer": manufacturer,
      "startDate": formattedStartDate,
      "endDate": formattedEndDate
    };
  
    // Call the service method with the payload
    this.apiService.getDatewiseStatus(payload).subscribe(data => {
      this.datewiseStatus = data;
      this.renderChart_product(data);
    });
  }
  
  renderChart_product(data: any) {
    if (this.chart_product) {
      this.chart_product.destroy();
    }

    const labels = data.map((item: any) => item.Product);
    const likeCounts = data.map((item: any) => item.LikeCount);
    const shareCounts = data.map((item: any) => item.ShareCount);
    const viewCounts = data.map((item: any) => item.ViewCount);

    const ctx = this.chartCanvas.nativeElement.getContext('2d');
    if (ctx) {
      this.chart_product = new Chart(ctx, {
        type: 'line', // Line chart
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Likes',
              data: likeCounts,
              borderColor: 'rgba(75, 192, 192, 1)', // Line color for likes
              backgroundColor: 'rgba(75, 192, 192, 0.2)', // Fill color under the line
              borderWidth: 2,
              fill: true // Fill the area under the line
            },
            {
              label: 'Shares',
              data: shareCounts,
              borderColor: 'rgba(153, 102, 255, 1)', // Line color for shares
              backgroundColor: 'rgba(153, 102, 255, 0.2)', // Fill color under the line
              borderWidth: 2,
              fill: true // Fill the area under the line
            },
            {
              label: 'Views',
              data: viewCounts,
              borderColor: 'rgba(255, 159, 64, 1)', // Line color for views
              backgroundColor: 'rgba(255, 159, 64, 0.2)', // Fill color under the line
              borderWidth: 2,
              fill: true // Fill the area under the line
            }
          ]
        },
        options: {
          scales: {
            xAxes: [{
              scaleLabel: {
                display: true,
                labelString: 'Products' // Title for the x-axis
              },
              ticks: {
                beginAtZero: true, // Ensure the x-axis starts at zero
                display: false,
              }
            }],
            yAxes: [{
              scaleLabel: {
                display: true,
                labelString: 'Counts' // Title for the y-axis
              },
              ticks: {
                beginAtZero: true, // Ensure the y-axis starts at zero
              }
            }]
          },
          legend: {
            display: true // Display the legend
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem: any, data: any) {
                const datasetLabel = data.datasets[tooltipItem.datasetIndex].label;
                const value = tooltipItem.yLabel;
                return `${datasetLabel} ${value}`;
              }
            }
          }
        }
      });

      this.productchartReady = true;
    } 
    // else {
    //   console.error('Unable to acquire the chart context.');
    // }
  }
}
  

