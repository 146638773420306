import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NavService, Menu } from '../../service/nav.service';
import { ApiService } from '../../service/api.service';
import { MENUITEMS, SubscriptionModel } from '../../models/datamodel';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent {

  public menuItems: Menu[];
  public url: any;
  public fileurl: any;
  sellerdata: any;
  admindata: any;
  public menus = []
  public subscriptionplan: any;
  subscriptionData: any;

  constructor(private router: Router, public navServices: NavService, private apiService: ApiService) {
    
    this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"));
    this.admindata = JSON.parse(window.localStorage.getItem("admin-info"));
    this.subscriptionplan = JSON.parse(window.localStorage.getItem("SUBSCRIPTIONPLAN"));
    this.getUserSubscription();
    if (this.admindata != null && this.sellerdata == null) {
      this.navServices.itemsforadmin.subscribe(menuItems => {
        this.menuItems = menuItems
        console.log(menuItems)
        console.log(this.menus)
        this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            menuItems.filter(items => {
              if (items.path === event.url)
                this.setNavActive(items)
              if (!items.children) return false
              items.children.filter(subItems => {
                if (subItems.path === event.url)
                  this.setNavActive(subItems)
                if (!subItems.children) return false
                subItems.children.filter(subSubItems => {
                  if (subSubItems.path === event.url)
                    this.setNavActive(subSubItems)
                })
              })
            })
          }
        })
      })
    }
    else if (this.sellerdata[0].accountstatus == "Secondery") {
      const payload = {
        "roles": this.sellerdata[0].roleaccess
      }
      this.apiService.getselectedmenu(payload).subscribe((result: MENUITEMS[]) => {
        // this.menus = result[0].menus
        result.forEach((i: MENUITEMS) => {
          this.menus.push(i.menus[0])
        })
        // if (this.sellerdata == null) {
        // this.navServices.itemsforadmin.subscribe(menuItems => {
          this.menuItems = this.menus
          console.log(this.menuItems)
          console.log(this.menus)
          this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
              this.menuItems.filter(items => {
                if (items.path === event.url)
                  this.setNavActive(items)
                if (!items.children) return false
                items.children.filter(subItems => {
                  if (subItems.path === event.url)
                    this.setNavActive(subItems)
                  if (!subItems.children) return false
                  subItems.children.filter(subSubItems => {
                    if (subSubItems.path === event.url)
                      this.setNavActive(subSubItems)
                  })
                })
              })
            }
          })
        // })
      })
    }
    else if (this.subscriptionplan == "Free") {
        this.navServices.itemsforbasicfree.subscribe(menuItems => {
          this.menuItems = menuItems
          this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
              menuItems.filter(items => {
                if (items.path === event.url)
                  this.setNavActive(items)
                if (!items.children) return false
                items.children.filter(subItems => {
                  if (subItems.path === event.url)
                    this.setNavActive(subItems)
                  if (!subItems.children) return false
                  subItems.children.filter(subSubItems => {
                    if (subSubItems.path === event.url)
                      this.setNavActive(subSubItems)
                  })
                })
              })
            }
          })
        })
    }
    else if (this.subscriptionplan == "Starter") {
      this.navServices.itemsforbasicstarter.subscribe(menuItems => {
        this.menuItems = menuItems
        this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            menuItems.filter(items => {
              if (items.path === event.url)
                this.setNavActive(items)
              if (!items.children) return false
              items.children.filter(subItems => {
                if (subItems.path === event.url)
                  this.setNavActive(subItems)
                if (!subItems.children) return false
                subItems.children.filter(subSubItems => {
                  if (subSubItems.path === event.url)
                    this.setNavActive(subSubItems)
                })
              })
            })
          }
        })
      })
    }
    else if (this.subscriptionplan == "Professional") {
      this.navServices.itemsforbasicprofessional.subscribe(menuItems => {
        this.menuItems = menuItems
        this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            menuItems.filter(items => {
              if (items.path === event.url)
                this.setNavActive(items)
              if (!items.children) return false
              items.children.filter(subItems => {
                if (subItems.path === event.url)
                  this.setNavActive(subItems)
                if (!subItems.children) return false
                subItems.children.filter(subSubItems => {
                  if (subSubItems.path === event.url)
                    this.setNavActive(subSubItems)
                })
              })
            })
          }
        })
      })
    }
    else if (this.subscriptionplan == "Enterprise") {
      this.navServices.itemsforbasicenterprise.subscribe(menuItems => {
        this.menuItems = menuItems
        this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            menuItems.filter(items => {
              if (items.path === event.url)
                this.setNavActive(items)
              if (!items.children) return false
              items.children.filter(subItems => {
                if (subItems.path === event.url)
                  this.setNavActive(subItems)
                if (!subItems.children) return false
                subItems.children.filter(subSubItems => {
                  if (subSubItems.path === event.url)
                    this.setNavActive(subSubItems)
                })
              })
            })
          }
        })
      })
    }
  }

  backtoadminview(){
    localStorage.removeItem("seller-info");
    window.location.href="/all/users-sellers"
  }
  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter(menuItem => {
      if (menuItem != item)
        menuItem.active = false
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true
            submenuItems.active = true
          }
        })
      }
    })
  }
  getUserSubscription(){
    const payload = {
      manufacturer: this.sellerdata[0].manufacturer,
      token: this.sellerdata[0].token
    }
    this.apiService.getuserSubscriptiondetails(payload).subscribe((res: SubscriptionModel[]) => {
      this.subscriptionData = res[0]
      this.subscriptionplan = res[0]?.plan
    })
  }
  calculateDaysLeft(endDate: string | Date): number {
    const currentDate = new Date();
    const targetDate = new Date(endDate);
    const timeDiff = targetDate.getTime() - currentDate.getTime(); // Difference in milliseconds
    const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
    return daysDiff;
  }
  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach(a => {
        if (this.menuItems.includes(item))
          a.active = false
        if (!a.children) return false
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false
          }
        })
      });
    }
    item.active = !item.active
  }

  //Fileupload
  readUrl(event: any) {
    if (event.target.files.length === 0)
      return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url = reader.result;
    }
  }

}
