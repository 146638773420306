import { Injectable } from '@angular/core';
import { WebService } from './web.service';
import { Newcustomer, Newsupplier, NotificationModel, ProductModel, Saleorder, Sale_Status, User, UserModel } from '../models/datamodel';


@Injectable({
  providedIn: 'root'
})

export class ApiService {

  constructor(private webservice: WebService) { }

  //Chart1 details
  getCategoryStatus(payload:Object){
    return this.webservice.post('api/getcategorystatus', payload)
  }

  //Chart2 details
  getDatewiseStatus(payload:Object){
    return this.webservice.post('api/getdatewisestatus', payload)
  }

  // Menu details
  getmenu(payload: Object) {
    return this.webservice.post('menu/getmenu', payload)
  }

  getselectedmenu(payload: Object) {
    return this.webservice.post('menu/getselectedmenu', payload)
  }

  // Add a user component

  addaUser(userModel: UserModel) {
    return this.webservice.post('api/addauser', userModel)
  }

  getownusers(payload: Object) {
    return this.webservice.post('api/getownusers', payload)
  }

  updateSecondaryUserPermission(userId: string, payload: Object) {
    return this.webservice.post(`api/userdetails/update/${userId}`, payload)
  }
  // Login component

  login(userModel: UserModel) {
    return this.webservice.post('api/login', userModel)
  }

  register(userModel: UserModel) {
    return this.webservice.post('api/register', userModel)
  }

  setpassword(userModel: UserModel) {
    return this.webservice.post('api/setpassword', userModel)
  }

  forgotpassword(userModel: UserModel) {
    return this.webservice.put('api/forgotpassword', userModel)
  }

  resetpassword(userModel: UserModel) {
    return this.webservice.put('api/resetpassword', userModel)
  }

  // GST upload Component

  checkManufacturerAvailibility(manufacturerName: Object) {
    return this.webservice.post('api/checkavailibility/manufacturer', manufacturerName)
  }

  getuserdetails(id: string, payload: Object) {
    return this.webservice.post(`api/userdetails/${id}`, payload)
  }

  getPricingPlans() {
    return this.webservice.get(`subscription/getpricingplans`)
  }

  createRazorpayOrder(payload: any) {
    return this.webservice.post(`subscription/addrazorpayorder`, payload)
  }

  addSubscription(payload: any) {
    return this.webservice.post(`subscription/addsubscription`, payload)
  }
  
  addPayment(payload: any) {
    return this.webservice.post(`subscription/addpayment`, payload)

  }
  getuserSubscriptiondetails(payload: Object) {
    return this.webservice.post(`subscription/checkactiveplan`, payload)
  }

  updateuserpaymentaddress(payload: Object) {
    return this.webservice.post(`api/user/updatepaymentaddress`, payload)
  }

  updateusersubscriptiondetails(payload: Object) {
    return this.webservice.post(`api/user/addsubscription`, payload)
  }

  // List User Component
  getsellertoken() {
    return this.webservice.get('api/tokenforseller');
  }

  getusertoken() {
    return this.webservice.get('api/tokenforuser');
  }

  sendnotification(payload: NotificationModel) {
    return this.webservice.post('api/sendnotification', payload)
  }

  sendnotificationuser(payload: NotificationModel) {
    return this.webservice.post1('api/sendnotification', payload)
  }

  getUsers(token) {
    return this.webservice.get(`api/sellers/list/${token}`)
  }

  getjessuplatformUsers(token) {
    return this.webservice.get1(`api/jesssu/app/users/${token}`)
  }

  getadminUsers(payload: Object) {
    return this.webservice.post(`api/user/list`, payload)
  }

  updateuser(id: String, userModel: UserModel) {
    return this.webservice.patch(`api/users/${id}`, userModel)

  }

  getuseractivitydetailsappuser(id: String) {
    return this.webservice.get1(`api/details/${id}`)
  }

  onboardingscreen(payload: Object) {
    return this.webservice.post1('api/getuserwisedetails/onboardingscreen', payload)
  }

  getactiveproductcountall() {
    return this.webservice.get(`jesssu/products/activeproductcountall`)
  }
  getinactiveproductcountall() {
    return this.webservice.get(`jesssu/products/inactiveproductcountall`)
  }

  getmostShareproduct() {
    return this.webservice.get('api/mostshareproduct')
  }

  getmostLikeproduct() {
    return this.webservice.get('api/mostlikedproduct')
  }

  getlatestsaleorderdatauserwise(id: any, payload: Object) {
    return this.webservice.post(`api/salesorder/user/getlast10/${id}`, payload)
  }

  getlatestsaleorderdataall(payload: Object) {
    return this.webservice.post(`api/salesorder/user/getlast10/all/1234/1234/1234`, payload)
  }
  getlatestsaledataall() {
    return this.webservice.get(`api/sales/user/getlast10/all/1/2/3/4`)
  }

  getorderdataforallmonthlyweeklytoday(payload: Object) {
    return this.webservice.post(`api/salesorder/dashboardforall/orderforall`, payload)

  }
  getordercountuserwisemonthwise(payload: Object) {
    return this.webservice.post('api/salesordermonthwiseuserwise', payload)
  }

  getaddproductcountallmonthwise(payload: Object) {
    return this.webservice.post('jesssu/products/addproductcount', payload)
  }
  getordercountallmonthwise(payload: Object) {
    return this.webservice.post('api/salesordermonthwise', payload)
  }
  getmonthwisesaleamount(id: any) {
    return this.webservice.get(`api/sales/user/channelwiseamount/view/${id}`)
  }

  getaddproductcountuserwise(id: any) {
    return this.webservice.get(`jesssu/products/last30daysaddproductstatus/${id}`)
  }
  getaddproductcountall() {
    return this.webservice.get(`jesssu/products/last30daysaddproductstatus/alluser/123`)
  }

  //Brand Component

  getbrands(payload: Object, offset: number, count: number) {
    return this.webservice.post(`brand/getbrandlist/${offset}/${count}`, payload)
  }
  updatebrand(payload: Object) {
    return this.webservice.post(`brand/brands/statusupdate`, payload)
  }
  getbrandsearch(payload: Object) {
    return this.webservice.post(`brand/search/getbrandlist/0/100`, payload)
  }

  // Brand-edit Component

  getbranddeatils(payload: Object) {
    return this.webservice.post(`brand/brands/getbranddetails`, payload)
  }

  updatesinglebranddetails(payload: Object) {
    return this.webservice.post(`brand/updatesinglebranddetails`, payload)
  }

  // Category Component
  getCategoryList(payload: Object, offset: number, count: number) {
    return this.webservice.post(`category/getcategorylist/${offset}/${count}`, payload)
  }

  getCategorySearch(payload: Object) {
    return this.webservice.post(`category/search/getcategorylist/0/20`, payload)
  }

  // Edit-category component

  getcategorydeatils(payload: Object) {
    return this.webservice.post(`category/category/getcategorydetails`, payload)
  }

  // Add product component

  getAmazonScrapingProduct(asin: String, payload: any) {
    return this.webservice.post(`api/scrape?asin=${asin}&type=seller`, payload)
  }

  getSubcategory(payload: Object) {
    return this.webservice.post(`category/getsubcategory/singlecategory`, payload)
  }

  getproductdetailsbyid(id: String, payload: Object) {
    return this.webservice.post(`jesssu/products/products/singleproduct/${id}`, payload)
  }

  getproductdetailsbySKU(id: String, payload: Object) {
    return this.webservice.post(`jesssu/products/products/code/${id}`, payload)
  }

  editproduct(id: String, productModel: ProductModel) {
    return this.webservice.patch(`jesssu/products/products/update/${id}`, productModel)
  }

  editproductvaritation(id: String, payload: Object) {
    return this.webservice.patch(`jesssu/products/products/updatevaritation/${id}`, payload)
  }

  addProductSync(payload: any) {
    return this.webservice.post('jesssu/products/addscrapedata', payload);
  }

  createproduct(productModel: ProductModel) {
    return this.webservice.post('jesssu/products/addproduct', productModel);
  }

  getcategoriesbyId(id: any) {
    return this.webservice.get(`api/usercategories/${id}`);
  }

  getsingleproductdetails(id: String, payload: Object) {
    return this.webservice.post(`jesssu/products/products/details/${id}`, payload)
  }

  // Product import export component
  getuploadproductdetails(offset: any, count: any) {
    return this.webservice.get(`api/seller/uploadproductdetails/${offset}/${count}`)
  }
  getimportproductdetails(payload: any) {
    return this.webservice.post(`jesssu/products/getuploadstatus`, payload)
  }
  getsaledataallforexport(payload: Object) {
    return this.webservice.post(`api/sales/getsaleforexport/manufacturerwise`, payload)
  }


  brandSync(payload: Object) {
    return this.webservice.post('brand/updatebrandlist', payload)
  }

  getproductmaindata(payload: any) {
    return this.webservice.post('jesssu/products/getproductmaindata', payload)
  }

  getproductvariationdata(payload: any) {
    return this.webservice.post('jesssu/products/getproductvaritationdata', payload)
  }

  // Product-list component

  getproductbysupplierwise(payload: Object) {
    return this.webservice.post(`api/purchases/supplierwise/productlist`, payload)
  }

  getnewmodelproductlist(payload: Object, offset: number, count: number) {
    return this.webservice.post(`jesssu/products/getproductforportal/${offset}/${count}`, payload)
  }
  getmissingurlforchannels(payload: any, offset: number, count: number) {
    return this.webservice.get(`jesssu/products/missingurl/${offset}/${count}/${payload}`)
  }

  getnewmodelproductlistparentskucount(payload: Object) {
    return this.webservice.post(`jesssu/products/getproductcountbyparentskuforportal`, payload)
  }

  getnewmodelproductcount(payload: Object) {
    return this.webservice.post(`jesssu/products/totalproductcountnewview`, payload)
  }

  getnewmodelofferproductlist(payload: Object, offset: any, count: any) {
    return this.webservice.post(`jesssu/products/activeofferproducts/${offset}/${count}`, payload)
  }

  getnewmodelwithoutofferproductlist(payload: Object, offset: any, count: any) {
    return this.webservice.post(`jesssu/products/activewithoutofferproducts/${offset}/${count}`, payload)
  }

  getsearchproductfulldetails(id: any, searchvalue: any) {
    return this.webservice.get(`jesssu/products/getproductdetails/${id}/${searchvalue}`)
  }

  getactiveproductlist(id: any, offset: number, count: number, payload: Object) {
    return this.webservice.post(`jesssu/products/activeproducts/${id}/${offset}/${count}`, payload)
  }

  getinactiveproductlist(id: any, offset: number, count: number, payload: Object) {
    return this.webservice.post(`jesssu/products/inactiveproducts/${id}/${offset}/${count}`, payload)
  }

  inactiveproduct(id: String, payload: Object) {
    return this.webservice.post(`jesssu/products/products/inactive/${id}`, payload)
  }

  activeproduct(id: String, payload: Object) {
    return this.webservice.post(`jesssu/products/products/active/${id}`, payload)
  }

  deleteproduct(id: String) {
    return this.webservice.delete(`jesssu/products/deleteproduct/${id}`)
  }

  addProducttoShopify(payload: Object) {
    return this.webservice.post(`api/shopify/addproduct`, payload)
  }

  deleteProductfromShopify(payload: Object) {
    return this.webservice.post(`api/shopify/deleteproduct`, payload)
  }

  updateProductpriceonShopify(payload: Object) {
    return this.webservice.post(`api/shopify/updateproductprice`, payload)
  }

  // Saleorder component

  getsalebysupplierwise(payload: Object) {
    return this.webservice.post(`api/purchases/supplierwise/salelist`, payload)
  }
  getsalesorderdetailsbyId(id: String, payload: Object) {
    return this.webservice.post(`api/salesorder/single/details/${id}`, payload);
  }

  getsalescountbyId(id: any, payload: Object) {
    return this.webservice.post(`api/salesorder/user/wise/count/main/${id}`, payload);
  }

  getsalescountbyIdwithoutDate(id: StringConstructor, post: Object) {
    return this.webservice.post(`api/salesorder/user/wise/count/main/withoutdate/${id}`, post);
  }


  getsalesorderbyId(id: StringConstructor) {
    return this.webservice.get(`api/salesorder/user/view/${id}`);
  }

  getsalesorder() {
    return this.webservice.get('api/salesorder');
  }

  setCompleted(userModel: UserModel) {
    console.log("create user service" + userModel);
    return this.webservice.patch(`users/${userModel._id}`, { 'FirstName': userModel.firstname, 'LastName': userModel.lastname });
  }

  deletevendor(userModel: UserModel) {
    return this.webservice.delete(`users/${userModel._id}`);

  }

  getsalesorderbydateofuser(date1: string, date2: string, id: any, payload: Object) {
    return this.webservice.post(`api/salesorder/user/${date1}/${date2}/${id}`, payload);
  }

  getsalesorderbydate(date1: string, date2: string) {
    return this.webservice.get(`api/salesorder/${date1}/${date2}`);
  }

  getsalesorderbyuserinput(userid: StringConstructor, payload: Object) {
    return this.webservice.post(`api/salesorder/user/selectionwisedata/${userid}`, payload);
  }
  getsearchsalesorder(userid: string, search: string, payload: Object) {
    return this.webservice.post(`api/salesorder/user/view/${userid}/${search}`, payload);
  }

  deletepurchaseorder(id: string) {
    return this.webservice.get(`api/delete/purchaseorder/${id}`)
  }


  // Saleordertosale component
  createsale(payload: Saleorder) {
    return this.webservice.post('api/addsale', payload)
  }

  updatesaleorder(id: any, payload: Saleorder) {
    return this.webservice.patch(`api/salesorder/single/update/${id}`, payload)
  }
  updatesaleorderpaymentstatus(id: any, payload: Sale_Status) {
    return this.webservice.patch(`api/salesorder/single/updatepaymentforreturn/${id}`, payload)
  }
  updatesaleorderstatus(id: any, payload: Sale_Status) {
    return this.webservice.patch(`api/salesorder/single/updatestatus/${id}`, payload)
  }

  // Supplier component

  getsuppliersbyId(payload: Object, offset: number, count: number) {
    return this.webservice.post(`api/suppliers/${offset}/${count}`, payload);
  }

  getboxbyId(payload: Object) {
    return this.webservice.post(`jesssu/products/getbox`, payload);
  }
  getboxskuproduct(payload: Object, offset: number, count: number) {
    return this.webservice.post(`jesssu/products/getboxskuproduct/${offset}/${count}`, payload);
  }
  getproductstatusactiveinactive(payload: Object) {
    return this.webservice.post(`api/saleproduct/addstatus`, payload)

  }

  updateproductstatusbytoggle(payload: Object) {
    return this.webservice.post(`jesssu/products/updateproductstatus`, payload)

  }

  getpurchaseordersearch(userid: string, search: string, payload: Object) {
    return this.webservice.post(`api/purchasesorder/user/view/search/${userid}/${search}`, payload);
  }
  getpurchaselistsearch(userid: string, search: string, payload: Object) {
    return this.webservice.post(`api/purchases/user/view/search/${userid}/${search}`, payload);
  }
  // Add Sale Order Component


  // user-seller component

  searchseller(payload: Object) {
    return this.webservice.post(`api/sellers/searchseller`, payload)
  }
  searchuser(payload: Object) {
    return this.webservice.post(`api/users/search/user`, payload)
  }


  getcontactusdata(offset: any, count: any) {
    return this.webservice.get(`api/seller/jesssu/contactus/getdata/${offset}/${count}`)
  }


  getsellerscrapeData(payload: Object) {
    return this.webservice.post(`jesssu/products/getscrapestatus`, payload)
  }
  getChannels() {
    return this.webservice.get(`jesssu/products/getchannels`)
  }
  updateChannelKeywords(payload: Object) {
    return this.webservice.post(`jesssu/products/updatekeywords`, payload)
  }
  updateChannelURLS(payload: Object) {
    return this.webservice.post(`jesssu/products/updateurls`, payload)
  }
  getpopularproduct(payload: Object) {
    return this.webservice.post(`jesssu/products/getpopularproduct`, payload)
  }
  getsharedproduct(payload: Object) {
    return this.webservice.post(`jesssu/products/getsharedproduct`, payload)
  }
  getuserviewddata(payload: Object) {
    return this.webservice.post(`jesssu/products/getuserviewddata`, payload)
  }

}
