<!-- <h4>BRANDS LIST</h4> -->

<div class="jesssu-seller-banner-wrap">
  <div class="jesssu-seller-img">
    <img src="../../../../assets/images/Rectangle 1493.svg" />
  </div>
  <div class="jesssu-seller-contant">
    <div class="jesssu-header">
      <div class="jesssu-header-row">
        <div>
        </div>
        <div class="rightside-profile">

        </div>

      </div>
    </div>
    <div class="jesssu-heading">
      <div class="heading-row">
        <div class="jesssu-heading-name">
          <h4>Brands</h4>
        </div>
        <div class="contant-row">
          <h6>Home - Brands</h6>
        </div>
      </div>

    </div>


    <div class="brand-box">
      <div class="brand-list">
        <input type="text" class="btn-form-control" id="myInput" (keyup)="myFunction1()" placeholder="Search for Brands"
          autofocus>
        <div class="brand-upload-row" infiniteScroll [infiniteScrollDistance]="3" [infiniteScrollThrottle]="3000"
          (scrolled)="onScroll()">

          <div class="brand-applications-box" *ngFor="let brand of brandlist">
            <div class="applications-table-row">
              <div class="brand-img">
                <img src={{brand.image}} alt="" />
              </div>
              <div class="brand-edit">
                <img src="../../../../assets/images/dashboard/Ellipse 370.svg" alt=""
                  (click)="onEdit(brand.brandname)" />
              </div>
              <div class="brand-edit-btn">
                <img src="../../../../assets/images/dashboard/Icon material-edit.svg" alt=""
                  (click)="onEdit(brand.brandname)" />
              </div>
              <div class="brand-img-name-row">
                <div class="brand-name">
                  <h6>{{brand.brandname}}</h6>
                </div>
                <div>
                  <button type="button" class="feed-action-link btn-action-link" value="Submit"
                    (click)="changeStatus(brand)">{{brand.status==true?"Deactivate":"Activate"}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>