import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomCookieService {
  setCookie(name: string, value: string, days: number) {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = '; expires=' + date.toUTCString();
    }
    const domain = this.getDomain();
    document.cookie = name + '=' + (value || '') + expires + '; path=/; domain=' + domain;
  }

  getCookie(name: string): string | null {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  deleteCookie(name: string) {
    this.setCookie(name, '', -1);
  }

  private getDomain(): string {
    if (window.location.hostname === 'localhost') {
      return 'localhost';
    } else {
      return window.location.hostname;
    }
  }
}
