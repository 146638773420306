
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Category, ProductModel, User, Accounts, ProductVariationModel } from 'src/app/shared/models/datamodel';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/shared/service/api.service';
import { ProductimageService } from 'src/app/shared/service/fileupload.service';

@Component({
  selector: 'app-addvariation',
  templateUrl: './addvariation.component.html',
  styleUrls: ['./addvariation.component.scss']
})
export class AddvariationComponent implements OnInit {
  public errortext = ""
  public successtext = ""
  public error = false
  public success = false
  selectedFiles: FileList;
  public presentpriceforRetail: Number = 0
  public presentpriceforSell: Number = 0
  currentFileUpload: any;
  FileUploadStatus: Boolean;
  progress: { percentage: number } = { percentage: 0 };
  ID: String;
  public subcategorybox: Boolean
  public categorybox: Boolean
  public Id = String;
  public user = [];
  selectedFile: File = null;
  productModel = new ProductModel();
  productVariationModel = new ProductVariationModel()
  isVisible: boolean = false;
  loading: boolean = true;
  public admindata: any;
  public sellerdata = [];
  public date1: Date;
  public types: Category[] = [];
  public selectedSubCategory: String = "0"
  checkboxesDataList = [];
  public finaldata: any;
  public selectedItemsList: any;
  public checkedIDs: any;
  public checkedIDswithURL: any;
  public categories: [];
  public offset: number = 0;
  public count: number = 100;
  public waiting = false;
  public waitingtext = ""
  public productvariationstatuspopup: Boolean = false
  Categoryname: any;
  category: Object;
  Subcategories: [];
  Manufacturer: any;
  subscriptionplan: any;
  channels = [
    { id: "Amazon", name: "Amazon" },
    { id: "Pradip Fabrics", name: "Pradip Fabrics" },
    { id: "Jesssu", name: "Jesssu" }
  ];
  token: any;
  variationboxlength = 0
  productvariationdata: ProductVariationModel[] = []
  constructor(private fb: FormBuilder, private apiService: ApiService, private router: Router, private productimageservice: ProductimageService, private activeroute: ActivatedRoute) {
    this.token = JSON.parse(window.localStorage.getItem("TOKEN"))
    this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"));
    this.admindata = JSON.parse(window.localStorage.getItem("admin-info"))
    this.subscriptionplan = JSON.parse(window.localStorage.getItem("SUBSCRIPTIONPLAN"));
    if (this.admindata == null && this.sellerdata == null) {
      this.router.navigate(["'/auth/login'"])
    }
    else {
      if (this.sellerdata != null) {
        if (this.sellerdata[0].accountstatus == "Secondery") {
          let status = this.sellerdata[0].roleaccess.includes("Product")
          if (status == false) {
            this.router.navigate(['/page-not-found'])
          } else {
            this.subcategorybox = false;
          }
        }
        else if (this.subscriptionplan == "Free") {
          this.subcategorybox = false;
        }
        else if (this.subscriptionplan == "Starter") {
          this.subcategorybox = false;
        }
        else if (this.subscriptionplan == "Professional") {
          this.subcategorybox = false;
        }
        else if (this.subscriptionplan == "Enterprise") {
          this.subcategorybox = false;
        }
        else {
          this.router.navigate(['/page-not-found'])
        }
      }
      else {
        this.errortext = "Please select a seller"
        this.error = true
        this.router.navigate(['/all/users-sellers'])
      }
    }
  }

  ngOnInit(): void {
    var id = this.activeroute.snapshot.url[2].path;
    this.ID = JSON.parse(window.localStorage.getItem("url"));
    if (id.length > 0 && id != '0') {
      const payload = {
        email: this.sellerdata[0].email,
        token: this.token
      }
      this.apiService.getsingleproductdetails(id, payload)
        .subscribe((products: ProductModel) => {
          this.productModel = products;
          if (this.productModel.variations != undefined) {
            this.productvariationdata = this.productModel.variations
          }
          else {
            this.productvariationdata = []
          }
        });
    } else if (this.ID != null) {
      const payload = {
        email: this.sellerdata[0].email,
        token: this.token
      }
      this.apiService.getsingleproductdetails(this.ID, payload)
        .subscribe((products: ProductModel) => {
          this.productModel = products;
          if (this.productModel.variations != undefined) {
            this.productvariationdata = this.productModel.variations
          }
          else {
            this.productvariationdata = []
          }
        });
      localStorage.removeItem("url");
    } else {
      this.router.navigate(['/products/physical/product-list'])
    }


  }

  addvariantionbox() {
    this.productvariationdata.push(new ProductVariationModel)
  }
  removevariantionbox(index) {
    if (index == 0) {
      this.productvariationdata = []
    } else {
      this.productvariationdata.splice(index, (this.productvariationdata.length - 1))
      console.log(this.productvariationdata)
    }
  }


  upload(type, index) {
    this.FileUploadStatus = true;
    this.currentFileUpload = this.selectedFiles.item(0);
    this.productimageservice.pushFileToStorage(this.currentFileUpload).subscribe(res => {

      this.FileUploadStatus = false;
      if (type == 'main') {
        this.productvariationdata[index].ProductImage = res.toString()
      }
      else if (type == 'other1') {
        this.productvariationdata[index].other_image_url = res.toString()
      }
      else if (type == 'other2') {
        this.productvariationdata[index].other_image_url1 = res.toString()
      }
      else if (type == 'other3') {
        this.productvariationdata[index].other_image_url2 = res.toString()
      }
    });
  }
  selectFile(event, type, index) {
    this.selectedFiles = event.target.files;
    this.upload(type, index);
  }

  test(index){
    const payload = {
      email: this.sellerdata[0].email,
      token: this.sellerdata[0].token
    }
    this.apiService.getproductdetailsbySKU(this.productvariationdata[index].SKU, payload)
    .subscribe((products:any) => {
      this.productvariationdata[index].Name = products.Name
      this.productvariationdata[index].SalePrice = products.SalePrice
      this.productvariationdata[index].RetailPrice = products.RetailPrice
      this.productvariationdata[index].ProductImage = products.ProductImage
      this.productvariationdata[index].Size = products.Size
      this.productvariationdata[index].Color = products.Color
      this.productvariationdata[index].number_of_items = products.number_of_items
    });
  }

  onSubmit(form: NgForm) {

    console.log(this.productModel)

    if (this.FileUploadStatus == true) {

      this.waitingtext = "File is uploading. Please wait"
      this.waiting = true

    }
    else {

      var id = this.activeroute.snapshot.url[2].path;
      if (id.length > 1 && id != '0') {
        const payload = {
          "variation": this.productvariationdata
        }
        if (!this.productvariationdata[0].Name) {
          this.errortext = "Please fillup product name";
          this.error = true
        }
        else if (this.productvariationdata[0].RetailPrice == undefined) {
          this.errortext = "Please fillup maximum retail price";
          this.error = true
        }
        else if (this.productvariationdata[0].SalePrice == undefined) {
          this.errortext = "Please fillup product sell price";
          this.error = true
        }
        else if (this.productvariationdata[0].Color == undefined) {
          this.errortext = "Please fillup product color";
          this.error = true
        }
        else if (this.productvariationdata[0].Size == undefined) {
          this.errortext = "Please fillup product size";
          this.error = true
        }
        else if (this.productvariationdata[0].ProductImage == undefined) {
          this.errortext = "Please upload product image";
          this.error = true
        }
        else {
          const productvariationdata = {
            email: this.sellerdata[0].email,
            token: this.sellerdata[0].token,
            variation: this.productvariationdata

          }
          
          this.apiService.editproductvaritation(id, productvariationdata)
            .subscribe(res => {
              this.productvariationstatuspopup = true
              // this.successtext = "Product varitation has been updated successfully."
              // this.error = false
              // this.success = true
              this.productVariationModel = new ProductVariationModel

            });
        }
      }
    }

  }
  onSelectedFile(event) {
    this.selectedFile = <File>event.target.files[0]
  }

  onActivate(e) {

  };
  onproductvariationstatuspopupsucess() {
    this.productvariationstatuspopup = false
    this.router.navigate(['/products/physical/add-variation/'])

  }


}

