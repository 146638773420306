import { Component, HostListener, OnInit, ChangeDetectorRef } from '@angular/core';
import { ApiService } from '../../shared/service/api.service';

@Component({
  selector: 'app-productsync',
  templateUrl: './productsync.component.html',
  styleUrls: ['./productsync.component.scss']
})

export class ProductsyncComponent implements OnInit {
  public sellerscrapedata = [];
  isScrapeLoading: boolean = false;
  sellertoken: any;
  public limit = 50;
  public pageNo = 1;
  channelList = [];
  categories: any[] = [];
  public subcategorybox: Boolean = false;
  public categorybox: Boolean = true;
  public genderbox: Boolean = false;
  Primary: string = '';
  InputPrimary: string = '';
  Subcategories: string[] = [];
  Categoryid: string = '';
  Secondary: string = "";
  InputSecondary: string = "";
  Gender: string = '';
  seller: any;
  errortext: string = '';
  successtext: string = '';
  error: boolean = false;
  success: boolean = false;
  channelURL: string = '';
  inputText: string = '';
  textArray: string[] = [];
  selectedStatus: boolean = true;
  selectedChannel: string = '--Your channel is not available --';
  keywords: any[] = []
  URLs: any[] = []
  addSection: Boolean = false
  sellerChannelData: any = {}
  addEditType: String = ''
  addEditIndex: number = 0;
  keywordURL: String = ''
  constructor(private apiService: ApiService, private cdr: ChangeDetectorRef) {
    this.seller = JSON.parse(window.localStorage.getItem("seller-info"));
    this.sellertoken = JSON.parse(window.localStorage.getItem("TOKEN"));
    this.selectedChannel = this.seller[0].manufacturer
  }

  ngOnInit(): void {
    this.getCategories()
    this.getsellerscrapeData();
    this.getChannels()

  }
  openCloseBox(type: string, data: any, index: number, addtype: String) {
    debugger;
    this.addEditType = type
    this.addEditIndex = index
    this.keywordURL = addtype
    if (type === 'add') {
      this.inputText = ''
      this.selectedStatus = true
      this.InputPrimary = ''
      this.InputSecondary = ''
      this.Gender = ''
      this.cdr.detectChanges();
      this.addSection = !this.addSection
    }
    else if (type === 'edit') {
      this.inputText = data?.keyword
      this.selectedStatus = data?.sync
      this.InputPrimary = data?.Primary
      this.InputSecondary = data?.Secondary
      this.Gender = data?.Gender
      this.cdr.detectChanges(); // Manually trigger change detection
      this.addSection = true
    };


  }
  getChannels() {
    this.apiService.getChannels().subscribe((res: any) => {
      this.channelList = res;
      const currentSelectedChannel = this.channelList.find(sel => sel.channelName === this.selectedChannel);
      this.sellerChannelData = currentSelectedChannel
      this.keywords = this.sellerChannelData?.keywords || [];
      this.URLs = this.sellerChannelData?.URLs || []
      this.channelURL = currentSelectedChannel.mainURL
    });
  }
  removeTextFromList(text: string) {
    this.textArray = this.textArray.filter((item) => item !== text);
  }
  selectChange() {
    this.subcategorybox = true;
    this.genderbox = true;
    this.categories.forEach((i: any) => {
      if (i.categoryname == this.Primary) {
        this.Primary = i.categoryname;
        this.InputPrimary = i.categoryname;
        this.Categoryid = i._id;
      }
    });
    var payload = {
      id: this.Categoryid,
      token: this.sellertoken
    };
    this.apiService.getSubcategory(payload).subscribe((res: []) => {
      this.Subcategories = res;
      this.subcategorybox = true;
    });
  }
  selectsubChange() {
    this.InputSecondary = this.Secondary;
    console.log(this.Secondary);
  }

  onGenderSelect(event: any) {
    this.closealert();
    this.Gender = event.target.value;
  }
  closealert() {
    this.error = false;
    this.success = false;
  }
  async verifyInput() {
    if (this.InputPrimary === '' || this.InputPrimary === '') {
      this.error = true;
      this.errortext = 'Please submit category.';
      return false;
    } else if (this.InputSecondary === '' || this.InputSecondary === "--Select subcategory--") {
      this.error = true;
      this.errortext = 'Please submit subcategory.';
      return false;
    } else if (this.Gender === '') {
      this.error = true;
      this.errortext = 'Please select gender.';
      return false;
    }
    return true;
  }
  async addSync() {
    // debugger;
    this.closealert();
    let result = await this.verifyInput();
    if (result === true) {
      const currentDate = new Date();
      for (const i of this.textArray) {
        const payload = {
          useremail: this.seller[0].email,
          search: i,
          sync: this.selectedStatus,
          url: this.channelURL,
          channel: this.seller[0].manufacturer,
          scrapperactivityid: currentDate.getTime().toString(),
          Primary: this.InputPrimary,
          Secondary: this.InputSecondary,
          Gender: this.Gender,
          token: this.sellertoken
        };
        this.success = true;
        this.successtext = `Request has been submitted successfully for scrape for ${i} keyword`;
        this.apiService
          .addProductSync(payload)
          .subscribe((res: any) => { });
        this.getsellerscrapeData();
      }
    }
  }

  async addKeyword() {
    this.closealert();
    let result = await this.verifyInput();
    if (result === true) {
      let token = this.sellertoken

      let finalData = null
      if (this.keywordURL === "keyword") {
        finalData = {
          keyword: this.inputText,
          sync: this.selectedStatus,
          Primary: this.InputPrimary,
          Secondary: this.InputSecondary,
          Gender: this.Gender,
        };
      }
      else {
        finalData = {
          URL: this.inputText,
          sync: this.selectedStatus,
          Primary: this.InputPrimary,
          Secondary: this.InputSecondary,
          Gender: this.Gender,
        };
      }
      console.log(finalData)
      if (this.addEditType === "add") {
        if (this.keywordURL === "URL") {
          this.URLs.push(finalData)
        }
        else {
          this.keywords.push(finalData)
        }
      }
      else if (this.addEditType === "edit") {
        if (this.keywordURL === "URL") {
          this.URLs[this.addEditIndex] = finalData
        }
        else {
          this.keywords[this.addEditIndex] = finalData
        }
      }

      if (this.keywordURL === "URL") {
        const payload = {
          "keywords": this.URLs,
          "channel": this.selectedChannel
        }
        this.apiService.updateChannelURLS(payload).subscribe((res: any) => {
          this.addSection = false
        });
      } else {
        const payload = {
          "keywords": this.keywords,
          "channel": this.selectedChannel
        }
        this.apiService.updateChannelKeywords(payload).subscribe((res: any) => {
          this.addSection = false
        });
      }
    }
  }

  deleteKeywordURL(index: number, type: string) {
    if (type === "URL") {
      this.URLs.splice(index, 1);
      const payload = {
        "keywords": this.URLs,
        "channel": this.selectedChannel
      }
      this.apiService.updateChannelURLS(payload).subscribe((res: any) => {
        this.addSection = false
      });
    } else {
      this.keywords.splice(index, 1);
      const payload = {
        "keywords": this.keywords,
        "channel": this.selectedChannel
      }
      this.apiService.updateChannelKeywords(payload).subscribe((res: any) => {
        this.addSection = false
      });
    }

  }


  getCategories() {
    let payload = {
      token: this.sellertoken
    };
    this.apiService.getCategoryList(payload, 0, 1000).subscribe((res: []) => {
      this.categories = res;
    });
  }
  addTextToList() {
    this.closealert();
    if (this.inputText.trim() !== '') {
      this.textArray.push(this.inputText.trim());
      this.inputText = ''; // Clear the input after adding to the array
    }
  }

  @HostListener('document:keydown.enter', ['$event'])
  handleEnterKey(event: KeyboardEvent) {
    // Trigger the addTextToList function on Enter key press
    this.addTextToList();
  }
  onCategoryChange() {
    this.subcategorybox = true;
    this.genderbox = true;
    let status: Boolean = false;
    this.Primary = this.InputPrimary;
    this.categories.forEach((i: any) => {
      if (i.categoryname == this.Primary) {
        this.Primary = i.categoryname;
        this.Categoryid = i._id;
        status = true;
      }
    });

    if (status === true) {
      var payload = {
        id: this.Categoryid,
        token: this.sellertoken
      };
      this.apiService.getSubcategory(payload).subscribe((res: []) => {
        this.Subcategories = res;
      });
    }
  }
  onSubCategoryChange() {
    this.Secondary = this.InputSecondary;
  }
  getsellerscrapeData() {
    this.isScrapeLoading = true;
    let payload = {
      "pageSize ": this.limit,
      "page": this.pageNo,
      "channel": this.seller[0].manufacturer,
      "token": this.sellertoken
    }
    this.apiService.getsellerscrapeData(payload).subscribe((result: any[]) => {
      this.sellerscrapedata = [...result, ...this.sellerscrapedata];
      this.isScrapeLoading = false;
    });
  }
  onStatusSelect(event: any) {
    this.closealert();
    this.selectedStatus = event.target.value;
  }

}
