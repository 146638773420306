import { Component, OnInit } from '@angular/core';
import { categoryDB } from '../../../../shared/tables/category';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../../shared/service/api.service';



@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  [x: string]: any;
  public categories: any;
  public offset: number = 0;
  public count: number = 20;
  token: any;


  constructor(private modalService: NgbModal, private apiService: ApiService, private router: Router) {
    this.token = JSON.parse(window.localStorage.getItem("TOKEN"))
  }

  ngOnInit() {
    this.getCategories();
  }

  onScroll() {
    this.offset++;
    this.getCategories();
  }

  getCategories() {
    let payload = {
      token: this.token
    }

    this.apiService.getCategoryList(payload, this.offset, this.count)
      .subscribe((res: any[]) => {
        if (this.categories != null) {
          this.categories = this.categories.concat(res)
        }
        else {
          this.categories = res
        }
      });
  }


  searchCategories() {
    var input, filter;
    input = document.getElementById("myInput");
    filter = input.value;
    var payload = { "search": input.value, "token": this.token }
    if (filter.length > 1) {
      this.apiService.getCategorySearch(payload)
        .subscribe((res: any[]) => {
          if (res.length >= 0) {
            this.categories = res
          }
        })
    }
    else {
      this.getCategories()
    }
  }

  onEdit(categoryname) {
    localStorage.removeItem("categoryname");
    localStorage.setItem("categoryname", JSON.stringify(categoryname));
    if (confirm("Are you want to edit the Category details")) {
      this.router.navigate(['/products/physical/category/' + categoryname]);
    }
  }

  onEdit2(categoryname) {
    localStorage.removeItem("categoryname");
    localStorage.setItem("categoryname", JSON.stringify(categoryname));
    if (confirm("Are you want to edit the Sub-Category details")) {
      this.router.navigate(['/products/physical/category/subcategory/' + categoryname]);
    }
  }

}
