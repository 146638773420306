<nav class="navbar-main navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="/home"><img src="../../../assets/images/jesssu-seller-logo.svg" alt="" /></a>
    <button class="navbar-toggler" type="button" (click)=toggglebtn()>
        <span class="navbar-toggler-icon">Test</span>
    </button>


</nav>
<!-- <div class="mission-box"> -->
<!-- <div class="contactus_area"> -->
<div class="container mt-4">
    <div class="row">
        <!-- <div class="col-md-1">
                    <div class="mission-back">
                    </div>
                </div> -->
        <div class="col-md-10 mx-auto">
            <div class="mission-section">
                <h2>Jesssu Seller- Account Deletion Request</h2>
                <p>Thank you for using Jesssu seller application! We respect your privacy and offer a simple process for
                    account deletion. Follow the steps below to request the deletion of your account:</p>

                <div class="request-list">
                    <ol>
                        <li>
                            <p>Open the app on your device.</p>
                        </li>
                        <li>
                            <p>Go to the "ME" section.</p>
                        </li>
                        <li>
                            <p>Find the "Close Account" option.</p>
                        </li>
                        <li>
                            <p>Follow the on-screen instructions to confirm the deletion.</p>
                        </li>
                    </ol>
                </div>
            </div>
            <div class="mission-section">
                <h2>Data Retention</h2>
                <p>We will delete the following types of data associated with your account:</p>
                <ol>
                    <li>
                        <p>Your personal information.</p>
                    </li>
                    <li>
                        <p>Transaction history.</p>
                    </li>
                    <li>
                        <p>Product list.</p>
                    </li>
                </ol>
                <p>Any additional retention period for specific data will be communicated during the account deletion
                    process.</p>
            </div>
            <div class="mission-section">
                <h2>Contact</h2>
                <p> For more information about our privacy practices, if you have questions, or if you would like to
                    make a
                    complaint, please contact us by e-mail at <b>support@jesssu.com</b> or directly submit a ticket <a
                        href="/contact-us" target="_blank" rel="noopener noreferrer">here.</a>
                    <br />Last updated: 27-Apr-2023
                </p>
            </div>
        </div>
    </div>





<!-- </div> -->
</div>
<!-- </div> -->
<!-- </div> -->

<div class="seller-business-wrap">
    <div class="seller-heading-wrap">
        <div class="grow-seller-row">
            <div class="grow-heading-section">
                <h3>Jesssu supplier support is <br>available 24/7</h3>
            </div>
            <div class="support-row">
                <h6>Our supplier support is available to solve all your doubts and issues <br>before and after you
                    start your online selling business.</h6>
                <div class="mail-row">
                    <img src="../../../assets/images/Icon ionic-ios-mail.svg" alt="" />
                    <h6>Get in touch with us at <strong><a
                                href="mailto:support@jesssu.com">support@jesssu.com</a></strong></h6>
                </div>
            </div>
        </div>
    </div>
</div>