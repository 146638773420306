<div class="jesssu-seller-banner-wrap">
    <div class="jesssu-seller-img">
        <img src="../../../../assets/images/Rectangle 1493.svg" />
    </div>
    <div class="jesssu-seller-contant">
        <div class="jesssu-header">
            <div class="jesssu-header-row">
                <div>
                </div>
                <div class="rightside-profile">

                </div>

            </div>
        </div>
        <div class="jesssu-heading">
            <button class="heading-row" (click)="navigateToProduct()"
                style="background: transparent;border: 0;">
                <svg id="vuesax_outline_arrow-left"
                    data-name="vuesax/outline/arrow-left"
                    xmlns="http://www.w3.org/2000/svg" width="30"
                    height="30" viewBox="0 0 38.134 38.134">
                    <g id="arrow-left">
                        <path id="Vector"
                            d="M10.833,21.669a1.179,1.179,0,0,1-.842-.35L.346,11.675a1.2,1.2,0,0,1,0-1.684L9.99.346A1.191,1.191,0,0,1,11.675,2.03l-8.8,8.8,8.8,8.8a1.2,1.2,0,0,1,0,1.684A1.153,1.153,0,0,1,10.833,21.669Z"
                            transform="translate(4.374 8.235)" fill="#fff" />
                        <path id="Vector-2" data-name="Vector"
                            d="M27.933,2.383H1.192A1.2,1.2,0,0,1,0,1.192,1.2,1.2,0,0,1,1.192,0H27.933a1.2,1.2,0,0,1,1.192,1.192A1.2,1.2,0,0,1,27.933,2.383Z"
                            transform="translate(4.64 17.875)" fill="#fff" />
                        <path id="Vector-3" data-name="Vector"
                            d="M0,0H38.134V38.134H0Z" fill="none" opacity="0" />
                    </g>
                </svg>
            </button>

        </div>

        <div class="import-applications-box">
            <!-- <div class="applications-table-row"> -->
            <div>

                <div class="row">
                    <div class="col-md-10">
                        <!-- <div class="form-group">
                            <input type="file" accept=".csv" class="product-form-control"
                                (change)="onSelectedFile($event)" (OnCancel)="handleCancel()">
                        </div> -->

                        <label class="form-group choose_files">
                            <!-- <label class="choose_file m-0"> -->
                            <img
                                src="../../../../assets/images/product-list/download.png" />
                            <span>{{ selectedFileName ? selectedFileName :
                                'Choose files' }}</span>
                            <input type="file" accept=".csv"
                                class="d-none product-form-control"
                                (change)="onSelectedFile($event)"
                                (OnCancel)="handleCancel()" />
                            <!-- </label> -->
                        </label>
                    </div>

                    <div class="col-md-2 d-flex justify-content-between"
                        *ngIf="loadingfordataimport">
                        <div class="product-feed-action">
                            <button (click)="Productaddthroughexcelupload()"
                                type="button" class="btn-action-link"
                                value="Submit">Upload

                                <div class="d-flex justify-content-center"
                                    *ngIf=!loadingfordataimport>
                                    <div class="spinner-border" role="status">
                                        <span class="sr-only"
                                            id="loading"></span>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <!-- <div class="product-feed-action"> -->
                        <!-- <a href="../../../../assets/Product.csv"
                                download="product.csv"
                                class="btn-action-link">
                                Download -->
                        <!-- <span> -->
                        <!-- <svg xmlns="http://www.w3.org/2000/svg"
                                    height="15" viewBox="10 0 10 20"
                                    width="24">
                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                    <path
                                        d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z" />
                                </svg> -->
                        <!-- </span> -->
                        <!-- </a>
                        </div> -->

                    </div>
                    <!-- <div class="col-md-2 p-0"> -->
                    <!-- <div class="download-file"> -->

                    <!-- </div> -->
                    <!-- </div> -->

                </div>

                <!-- <div class="upload-file-row"> -->
                <div class="upload-file-heading">
                    <div (click)="downloadCSV()"
                        style="width: 100%;display: block;text-align: center; cursor: pointer;">
                        <h5
                            style="background: #e4e4e4; padding: 9px; border-radius: 10px;">Click
                            here to download blank .csv
                            file format for
                            upload products.</h5>
                    </div>
                </div>

                <!-- <div class="download-file-row">
                        <input class='input-switch' type="checkbox" id="demo"/>
                        <label class="label-switch" for="demo"></label>
                        <span class="info-text"></span>
                    </div> -->
                <!-- </div> -->
            </div>
            <!-- </div> -->
        </div>

        <div class="catagory-applications-box">
            <!-- <div class="applications-table-row"> -->

            <div class="product-box">
                <p>When multiple sellers sell the same product through a
                    single page, we combine and present
                    the best product.</p>
            </div>
            <br>
            <div class="product-ui-form">
                <div class="row" *ngIf="categorybox">
                    <div class="col-md-3 text-right">
                        <label class="add-product-lable">
                            Category Selection*</label>
                    </div>
                    <div class="col-md-7">
                        <div class="form-group">
                            <select name="acc_name"
                                [(ngModel)]="productModel.Primary"
                                class="product-form-control"
                                (change)="selectChange()">
                                <option [ngValue]="0" copy>--Select
                                    category--
                                </option>
                                <option *ngFor="let category of categories"
                                    [ngValue]="category.categoryname">
                                    {{category.gender}} :
                                    {{category.categoryname}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="subcategorybox">
                    <div class="col-md-3 text-right">
                        <label class="add-product-lable">
                            Subcategory Selection*</label>
                    </div>
                    <div class="col-md-7">
                        <div class="form-group">
                            <select name="acc_name"
                                [(ngModel)]="productModel.Secondary"
                                class="product-form-control"
                                (change)="selectsubChange()">
                                <option [ngValue]="0" copy>--Select
                                    subcategory--
                                </option>
                                <option
                                    *ngFor="let subcategory of Subcategories"
                                    [ngValue]="subcategory.subcategoryname">
                                    {{subcategory.subcategoryname}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="subcategorybox">
                    <div class="col-md-3 text-right">
                        <label class="add-product-lable">Gender</label>
                    </div>
                    <div class="col-md-7">
                        <div class="form-group">
                            <input type="text" class="product-form-control"
                                [(ngModel)]="productModel.Gender"
                                name="gender">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 text-right">
                        <label class="add-product-lable">Product SKU
                            No</label>
                    </div>
                    <div class="col-md-7">
                        <div class="form-group">
                            <input type="text" class="product-form-control"
                                [(ngModel)]="productModel.SKU"
                                name="parentsku"
                                (ngModelChange)="getAmazonProduct($event)">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 text-right">
                        <label class="add-product-lable">Product Barcode
                            No</label>
                    </div>
                    <div class="col-md-7">
                        <div class="form-group">
                            <input type="text" class="product-form-control"
                                [(ngModel)]="productModel.Barcode"
                                name="parentsku">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 text-right">
                        <label
                            class="add-product-lable">Manufacturer</label>
                    </div>
                    <div class="col-md-7">
                        <div class="form-group">
                            <input type="text" class="product-form-control"
                                [(ngModel)]="productModel.Manufacturer"
                                name="manufacture_part_number" readonly>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 text-right">
                        <label class="add-product-lable">Item Name (Product
                            Title)</label>
                    </div>
                    <div class="col-md-7">
                        <div class="form-group">
                            <input type="text" class="product-form-control"
                                [(ngModel)]="productModel.Name"
                                name="item_name">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 text-right">
                        <label class="add-product-lable">Maximum Retail
                            Price</label>
                    </div>
                    <div class="col-md-7">
                        <div class="form-group">
                            <input type="number" onwheel="this.blur()"
                                class="product-form-control"
                                [(ngModel)]="productModel.RetailPrice"
                                name="standard_price">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 text-right">
                        <label class="add-product-lable">Product Sell
                            Price</label>
                    </div>
                    <div class="col-md-7">
                        <div class="form-group">
                            <input type="number" onwheel="this.blur()"
                                class="product-form-control"
                                [(ngModel)]="productModel.SalePrice"
                                name="maximum_retail_price">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 text-right">
                        <label class="add-product-lable">Brand Name</label>
                    </div>
                    <div class="col-md-7">
                        <div class="form-group">
                            <input type="text" class="product-form-control"
                                [(ngModel)]="productModel.Brand"
                                name="brand_name">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 text-right">
                        <label class="add-product-lable">Design
                            Details</label>
                    </div>
                    <div class="col-md-7">
                        <div class="form-group">
                            <input type="text" class="product-form-control"
                                type="text"
                                [(ngModel)]="productModel.design_name"
                                name="design_name">
                            <!-- <small>Once submited this fields cannot be changed</small> -->
                        </div>

                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 text-right">
                        <label class="add-product-lable">Colour</label>
                    </div>
                    <div class="col-md-7">
                        <div class="form-group">
                            <input type="text" class="product-form-control"
                                type="text"
                                [(ngModel)]="productModel.Color"
                                name="color_map">
                            <!-- <small>Once submited this fields cannot be changed</small> -->
                        </div>

                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 text-right">
                        <label class="add-product-lable">Material
                            Type</label>
                    </div>
                    <div class="col-md-7">
                        <div class="form-group">
                            <input type="text" class="product-form-control"
                                type="text"
                                [(ngModel)]="productModel.material_type"
                                name="material_type">
                            <!-- <small>Once submited this fields cannot be changed</small> -->
                        </div>

                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 text-right">
                        <label class="add-product-lable">Wave Type</label>
                    </div>
                    <div class="col-md-7">
                        <div class="form-group">
                            <input type="text" class="product-form-control"
                                type="text"
                                [(ngModel)]="productModel.weave_type"
                                name="weave_type">
                            <!-- <small>Once submited this fields cannot be changed</small> -->
                        </div>

                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 text-right">
                        <label class="add-product-lable">Product
                            Size</label>
                    </div>
                    <div class="col-md-7">
                        <div class="form-group">
                            <input type="text" onwheel="this.blur()"
                                class="product-form-control"
                                [(ngModel)]="productModel.Size" name="size">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-3 text-right">
                        <label class="add-product-lable">Item
                            Condition</label>
                    </div>
                    <div class="col-md-7">
                        <div class="form-group">
                            <input type="text" class="product-form-control"
                                type="text"
                                [(ngModel)]="productModel.condition_type"
                                name="condition_type">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 text-right">
                        <label class="add-product-lable">Variations
                            Theme</label>
                    </div>
                    <div class="col-md-7">
                        <div class="form-group">
                            <input type="text" class="product-form-control"
                                type="text"
                                [(ngModel)]="productModel.variation_theme"
                                name="condition_type">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 text-right">
                        <label class="add-product-lable">Product
                            Description</label>
                    </div>
                    <div class="col-md-7">
                        <div class="form-group">
                            <textarea class="product-form-control1"
                                [(ngModel)]="productModel.Long"
                                name="product_description"
                                rows="4"></textarea>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 text-right">
                        <label class="add-product-lable">Key Product
                            Description</label>
                    </div>
                    <div class="col-md-7">
                        <div class="form-group">
                            <input type="text" class="product-form-control"
                                [(ngModel)]="productModel.Short"
                                name="key_product_description">
                        </div>

                    </div>
                </div>
                <!-- <div class="row">
                        <div class="col-md-3 text-right">
                            <label class="add-product-lable">Shipping Weight(in gms)</label>
                        </div>
                        <div class="col-md-7">
                            <div class="form-group">
                                <input type="number" onwheel="this.blur()" class="product-form-control"
                                    [(ngModel)]="productModel.shipping_weight" name="condition_type">
                            </div>
                        </div>
                    </div> -->
                <div class="row">
                    <div class="col-md-3 text-right">
                        <label class="add-product-lable">Weight(in
                            gms)</label>
                    </div>
                    <div class="col-md-7">
                        <div class="form-group">
                            <input type="number" onwheel="this.blur()"
                                class="product-form-control"
                                [(ngModel)]="productModel.item_weight"
                                name="condition_type">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 text-right">
                        <label class="add-product-lable">Height(in
                            Cms)</label>
                    </div>
                    <div class="col-md-7">
                        <div class="form-group">
                            <input type="number" onwheel="this.blur()"
                                class="product-form-control"
                                [(ngModel)]="productModel.height"
                                name="condition_type">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 text-right">
                        <label class="add-product-lable">Length(in
                            Cms)</label>
                    </div>
                    <div class="col-md-7">
                        <div class="form-group">
                            <input type="number" onwheel="this.blur()"
                                class="product-form-control"
                                [(ngModel)]="productModel.length"
                                name="condition_type">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 text-right">
                        <label class="add-product-lable">Bredth(in
                            Cms)</label>
                    </div>
                    <div class="col-md-7">
                        <div class="form-group">
                            <input type="number" onwheel="this.blur()"
                                class="product-form-control"
                                [(ngModel)]="productModel.breadth"
                                name="condition_type">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 text-right">
                        <label class="add-product-lable">Number of
                            Items</label>
                    </div>
                    <div class="col-md-7">
                        <div class="form-group">
                            <input type="number" onwheel="this.blur()"
                                class="product-form-control"
                                [(ngModel)]="productModel.number_of_items"
                                name="condition_type">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 text-right">
                        <label class="add-product-lable">Country of
                            Origin</label>
                    </div>
                    <div class="col-md-7">
                        <div class="origin-form">
                            <select class="origin-form-control"
                                [(ngModel)]="productModel.COO"
                                name="country_region_of_origin">
                                <option value="INDIA">INDIA</option>
                                <option value="USA">USA</option>
                                <option value="OTHER">Others</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 text-right">
                        <label class="add-product-lable">Safely
                            Warning</label>
                    </div>
                    <div class="col-md-7">
                        <div class="form-group">
                            <input type="text" class="product-form-control"
                                type="text"
                                [(ngModel)]="productModel.safely_warning"
                                name="condition_type">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 text-right">
                        <label class="add-product-lable">Box No</label>
                    </div>
                    <div class="col-md-7">
                        <div class="form-group">
                            <input type="number"
                                class="product-form-control"
                                onwheel="this.blur()"
                                [(ngModel)]="productModel.positionplace"
                                name="condition_type">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 text-right">
                        <label
                            class="add-product-lable">Product-Link</label>
                    </div>
                    <div class="col-md-7">
                        <div class="form-group">
                            <input type="text" class="product-form-control"
                                onwheel="this.blur()"
                                [(ngModel)]="productModel.Product"
                                name="Product">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 text-right">
                        <label class="add-product-lable">M1</label>
                    </div>
                    <div class="col-md-7">
                        <div class="form-group">
                            <input type="text" class="product-form-control"
                                onwheel="this.blur()"
                                [(ngModel)]="productModel.M1" name="M1">
                        </div>
                    </div>
                </div>
            </div>
            <div class="sub-catagory-applications-box">
                <div class="channel-applications-table-row">
                    <div class="channel-name">
                        <h6>Channel List</h6>
                    </div>
                    <div class="col-md-8">
                        <ul class="checkbox-items">
                            <div class="channel-row">
                                <li *ngFor="let item of checkboxesDataList"
                                    class="channel-section">
                                    <input type="checkbox" name id
                                        [(ngModel)]="item.isChecked"
                                        (change)="changeSelection()">
                                    {{item.label}}
                                    <br>
                                    <ng-container *ngIf="item.isChecked">
                                        <label
                                            class="label-field">URL:</label>
                                        <div class="form-group">
                                            <input type="text"
                                                class="url-form-control"
                                                [(ngModel)]="item.url"
                                                name="url">
                                        </div>
                                    </ng-container>

                                </li>
                            </div>

                        </ul>
                    </div>
                </div>
            </div>
            <div class="sub-catagory-applications-box">
                <div class="channel-applications-table-row">
                    <div class="channel-name">
                        <h6>Your image recommendations</h6>
                    </div>

                    <div class="row">
                        <div class="col-md-3">
                            <div class="image-upload-box">
                                <input type="file" id="img01"
                                    (change)="selectFile($event,'main')" />
                                <label class="upload-imgbox" for="img01">
                                    <img src={{productModel.ProductImage}}
                                        onerror="this.onerror==null; this.src='../../../../../assets/images/dashboard/upload.svg'"
                                        alt />

                                </label>
                            </div>
                            <span class="img-bottom-heading">Product Main
                                Image</span>
                        </div>
                        <div class="col-md-3">
                            <div class="image-upload-box">
                                <input type="file" id="pt01"
                                    (change)="selectFile($event,'other0')" />
                                <label class="upload-imgbox" for="pt01">
                                    <img
                                        src={{productModel.other_image_url}}
                                        onerror="this.onerror==null; this.src='../../../../../assets/images/dashboard/upload.svg'"
                                        alt />

                                </label>
                            </div>
                            <span class="img-bottom-heading">Product Image
                                2</span>
                        </div>
                        <div class="col-md-3">
                            <div class="image-upload-box">
                                <input type="file" id="pt02"
                                    (change)="selectFile($event,'other1')" />
                                <label class="upload-imgbox" for="pt02"
                                    (change)="selectFile($event,'other1')">
                                    <img
                                        src={{productModel.other_image_url2}}
                                        onerror="this.onerror==null; this.src='../../../../../assets/images/dashboard/upload.svg'"
                                        alt />

                                </label>
                            </div>
                            <span class="img-bottom-heading">Product Image
                                3</span>
                        </div>
                        <div class="col-md-3">
                            <div class="image-upload-box">
                                <input type="file" id="pt03"
                                    (change)="selectFile($event,'other2')" />
                                <label class="upload-imgbox" for="pt03">
                                    <img
                                        src={{productModel.other_image_url3}}
                                        onerror="this.onerror==null; this.src='../../../../../assets/images/dashboard/upload.svg'"
                                        alt />

                                </label>
                            </div>
                            <span class="img-bottom-heading">Product Image
                                4</span>
                        </div>
                        <div class="col-md-3">
                            <div class="image-upload-box">
                                <input type="file" id="pt04"
                                    (change)="selectFile($event,'other3')" />
                                <label class="upload-imgbox" for="pt04">
                                    <img
                                        src={{productModel.other_image_url4}}
                                        onerror="this.onerror==null; this.src='../../../../../assets/images/dashboard/upload.svg'"
                                        alt />

                                </label>
                            </div>
                            <span class="img-bottom-heading">Product Image
                                5</span>
                        </div>
                        <div class="col-md-3">
                            <div class="image-upload-box">
                                <input type="file" id="pt05"
                                    (change)="selectFile($event,'other4')" />
                                <label class="upload-imgbox" for="pt05">
                                    <img
                                        src={{productModel.other_image_url5}}
                                        onerror="this.onerror==null; this.src='../../../../../assets/images/dashboard/upload.svg'"
                                        alt />

                                </label>
                            </div>
                            <span class="img-bottom-heading">Product Image
                                6</span>
                        </div>
                        <div class="col-md-3">
                            <div class="image-upload-box">
                                <input type="file" id="pt06"
                                    (change)="selectFile($event,'other5')" />
                                <label class="upload-imgbox" for="pt06">
                                    <img
                                        src={{productModel.other_image_url6}}
                                        onerror="this.onerror==null; this.src='../../../../../assets/images/dashboard/upload.svg'"
                                        alt />

                                </label>
                            </div>
                            <span class="img-bottom-heading">Product Image
                                7</span>
                        </div>
                        <div class="col-md-3">
                            <div class="image-upload-box">
                                <input type="file" id="pt07"
                                    (change)="selectFile($event,'other6')" />
                                <label class="upload-imgbox" for="pt07">
                                    <img
                                        src={{productModel.other_image_url7}}
                                        onerror="this.onerror==null; this.src='../../../../../assets/images/dashboard/upload.svg'"
                                        alt />

                                </label>
                            </div>
                            <span class="img-bottom-heading">Product Image
                                8</span>
                        </div>

                    </div>

                </div>
            </div>

            <div class="sub-catagory-applications-box">
                <div class="channel-applications-table-row">
                    <div class="channel-name">
                        <h6>Upload product raw Image</h6>
                    </div>
                    <div class="row">
                        <!-- <div class="image-upload-row"> -->
                        <div class="col-md-3">
                            <div class="image-upload-box">
                                <input type="file" id="pt08"
                                    (change)="selectFile($event,'other7')" />
                                <label class="upload-imgbox" for="pt08">
                                    <img
                                        src={{productModel.raw_main_image_url}}
                                        onerror="this.onerror==null; this.src='../../../../../assets/images/dashboard/upload.svg'"
                                        alt />

                                </label>
                            </div>
                            <span class="img-bottom-heading">Raw Image
                                01</span>
                        </div>
                        <div class="col-md-3">
                            <div class="image-upload-box">
                                <input type="file" id="pt09"
                                    (change)="selectFile($event,'other8')" />
                                <label class="upload-imgbox" for="pt09">
                                    <img
                                        src={{productModel.raw_other_image_url}}
                                        onerror="this.onerror==null; this.src='../../../../../assets/images/dashboard/upload.svg'"
                                        alt />

                                </label>
                            </div>
                            <span class="img-bottom-heading">Raw Image
                                02</span>
                        </div>
                        <!-- </div> -->
                    </div>
                </div>
            </div>

            <div class="submit-row">
                <div class="product-feed-action">
                    <form #productForm="ngForm"
                        (ngSubmit)="onSubmit(productForm)" id="productForm"
                        class="needs-validation product-add" novalidate>
                        <router-outlet
                            (activate)="onActivate($event)"></router-outlet>
                        <button type="submit" class="btn-action-link"
                            value="Submit">Submit</button>
                    </form>
                </div>

                <div class="alert" *ngIf="error">
                    <span class="closebtn" (click)="close()">&times;</span>
                    <strong>Error!</strong> {{errortext}}
                </div>

                <div class="response" *ngIf="success">
                    <span class="closebtn" (click)="close()">&times;</span>
                    <strong>Success!</strong> {{successtext}}
                </div>

            </div>

            <!-- </div> -->
        </div>
        <!-- <div class="jesssu-seller-contant"> -->
        <!-- <div class="import-applications-box recent_data_uploads">
            <div>
                <h3>Recent Uploads</h3>
                <div class="table-responsive">
                    <table>
                        <tr>
                            <th>Uploaded By</th>
                            <th>Uploaded Date</th>
                            <th>Upload Details</th>
                        </tr>
                        <tr>
                            <td>
                                <div class="name_email">
                                    <p class="text-bold">Pritam
                                        Nandi</p>
                                    <p>pritam.nandi88@gmail.com</p>
                                </div>
                            </td>
                            <td>
                                <div class="name_email">
                                    <p>20/03/2020</p>
                                </div>
                            </td>
                            <td>
                                <div class="name_email">
                                    <p>Main Data: <span
                                            class="text-bold">91</span></p>
                                    <p>Variation Data: 0</p>
                                </div>
                            </td>
                        </tr>

                    </table>
                </div>
                <nav class="bl_pager">
                    <ul class="bl_pager_inner">
                        <li>
                            <a class="bl_pager_link" href="#"><img
                                    src="../../../../assets/images/product-list/left.png" /></a>
                        </li>
                        <li>
                            <span
                                class="bl_pager_link is_active">1</span>
                        </li>
                        <li>
                            <a class="bl_pager_link" href="#">2</a>
                        </li>
                        <li>
                            <a class="bl_pager_link" href="#">3</a>
                        </li>
                        <li>
                            <a class="bl_pager_link" href="#">4</a>
                        </li>
                        <li>
                            <a class="bl_pager_link" href="#"><img
                                    src="../../../../assets/images/product-list/right.png" /></a>
                        </li>
                    </ul>
                </nav>

            </div>
        </div> -->
        <!-- </div> -->
    </div>
</div>

<div *ngIf="productstatuspopup" class="loginbox">
    <div class="paste-source">
        <div class="paste-source-box">
            <h4>{{productstatuspopuptext}}</h4>
            <div class="save-btn">
                <div class="featured-action">
                    <button type="button" class="save-upload-cli-primary-btn"
                        (click)="onproductstatuspopupsucess()">
                        Ok
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="productstatuspopupforupdate" class="loginbox">
    <div class="paste-source">
        <div class="paste-source-box">
            <h4>Product has been updated successfully</h4>
            <div class="save-btn">
                <div class="featured-action">
                    <button type="button" class="save-upload-cli-primary-btn"
                        (click)="onproductstatuspopupsucess()">
                        Ok
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="blankproductstatuspopup" class="loginbox">
    <div class="paste-source">
        <div class="paste-source-box">
            <h4>Please upload a file for Import your product's</h4>
            <div class="save-btn">
                <div class="featured-action">
                    <button type="button" class="save-upload-cli-primary-btn"
                        (click)="onblankproductstatuspopupsucess()">
                        Ok
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="popup-overlay" *ngIf="showPopup">
    <div class="popup-content">
        <div class="loading-spinner" *ngIf="loading"></div>
        <div class="popup-text" *ngIf="!loading">
            <p>{{ popupText }}</p>
            <button class="close-button" (click)="closePopup()">Close</button>
        </div>
    </div>
</div>