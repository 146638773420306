import { Component, OnInit } from '@angular/core';
import { productDB } from 'src/app/shared/tables/product-list';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from 'src/app/shared/service/api.service';
import { ProductimageService } from 'src/app/shared/service/fileupload.service';
import { ProductlistModel, ProductModel, Productactivestatus } from 'src/app/shared/models/datamodel';
import * as $ from 'jquery';


@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit {
  public activeinactiveerrortext = ''
  public errortext = ""
  public validation = true
  public successtext = ""
  public error = false
  public success = false
  public parentskucount: number
  public presentpriceforRetail: number = 0
  public presentpriceforSell: number = 0
  public activeproduct_list = [];
  public payload: any;
  public expandContent: Boolean = false;
  public newviewproductlist = [];
  public channelname: String = "No Channel Selected"
  public activecountchannelwise: number = 0
  public inactivecountchannelwise: number = 0
  public totalcountchannelwise: number = 0
  public inactiveproduct_list = [];
  public channelwiseproductlist = [];
  public channelwiseproductlistcount: number = 0
  public activelist = [];
  public productmaindata: any;
  public productvaritationdata: any;
  public inactivelist = [];
  public totalcount: any;
  public activeproductcount: any;
  public inactiveproductcount: any;
  public searchproduct_list = [];
  public productlist = null;
  public ID = String;
  public type: any;
  public searchtext: any;
  public searchtext1: any;
  public isLoading: boolean = true;
  public activecount = String;
  public inactivecount = String;
  public orderform: FormGroup;
  public id: any;
  public popupshow: Boolean = false;
  public activepopupshow: Boolean = false;
  public deactivepopupshow: Boolean = false;
  public editpopup: Boolean = false;
  public copypopup: Boolean = false;
  public shopifypopupforadd: Boolean = false;
  public shopifypopupforremove: Boolean = false;
  public variationpopup: Boolean = false;
  public Manufacturer: any
  public sellerdata = [];
  public product: ProductlistModel
  public status: any;
  public productsSearchList;
  public searchfilter1: String;
  public offset: number = 0;
  public count: number = 10;
  public productdetails = [];
  public showstatus: string;
  public main: any;
  public product_list = [];
  public shopifyactivation = false;
  public SKU: any;
  public buttonforexport = false;
  public productforexports = [];
  public productforexportsshopify = [];
  public selectproductcount: any;
  public idforproductdelete: any;
  public deletesuccess: Boolean = false;
  public idforproductedit: any;
  public idforproductcopy: any;
  public idforproductvariation: any;
  public idforproductshopify: any;
  public sellertoken: any;
  public apiStatus: boolean = false;

  public idforactivepopupshow: any;
  public idfordeactivepopupshow: any;
  public productlistselectiontype: Boolean = false;
  public supplierbox: Boolean = false;
  reactionpopupshow: boolean = false;
  reactionData: string = '';
  index: number = 0;
  reactionDataList: { type: string; items: string[]; icon: string; }[];


  showReactionData(reactionType: string, productIndex: number) {
    if (productIndex >= 0 && productIndex < this.newviewproductlist.length) {
      const product = this.newviewproductlist[productIndex];
      let reactionDataList: { type: string, icon: string, items: string[] }[] = [];

      if (product && product.data && Array.isArray(product.data) && product.data.length > 0) {
        const productData = product.data[0];

        switch (reactionType) {
          case 'likes':
            reactionDataList.push({ type: 'Likes', icon: '&#128077;', items: productData.likes || [] });
            break;
          case 'dislikes':
            reactionDataList.push({ type: 'Dislikes', icon: '&#128078;', items: productData.dislikes || [] });
            break;
          case 'comments':
            const comments = productData.comments || [];
            reactionDataList.push({ type: 'Comments', icon: '&#128172;', items: comments.map(comment => `${comment.user}: ${comment.comment}`) });
            break;
          default:
            break;
        }
      }

      this.reactionDataList = reactionDataList;
      this.reactionpopupshow = true;
    } else {
      console.error('Invalid product index');
    }
  }

  navigateToAddProduct() {
    this.router.navigate(['/products/physical/add-product/0']);
  }
  navigateToImportExport() {
    this.router.navigate(['/products/physical/product-import-export']);
  }

  onReactionConfirm() {
    this.reactionpopupshow = false;
  }


  channels = [
    { id: null, name: "All" },
    { id: "Amazon", name: "Amazon" },
    { id: "Meesho", name: "Meesho" },
    { id: "Snapdeal", name: "Snapdeal" },
    { id: "Flipkart", name: "Flipkart" },
    { id: "Jesssu", name: "Jesssu" },
    { id: "Limeroad", name: "Limeroad" },
    { id: "Myntra", name: "Myntra" },
    { id: "Supplier", name: "Supplier wise" },
    { id: "Box", name: "Box" }
  ];
  productstatus = [
    { id: true, name: "Active" },
    { id: false, name: "Inactive" },
    { id: null, name: "All" },
    { id: "Channel_URL_Missing", name: "Channel_URL_Missing" }
  ];
  activetotalcount: number = 0;
  inactivetotalcount: number = 0;
  admindata: any;
  subscriptionplan: any;
  productexportoption: Boolean = false;
  productModel: ProductModel;
  user: any;
  Id: any;
  supplier_list: any;
  box_list: any;
  newproductactivestatus = new Productactivestatus();
  getmissingurlforchannels: any;
  positionplace: String;
  private _id: {};
  scroll: boolean = true;
  scrollend: boolean = true;

  constructor(private apiService: ApiService, private modalService: NgbModal, public fb: FormBuilder, public router: Router) {
    this.sellertoken = JSON.parse(window.localStorage.getItem("TOKEN"));
    this.user = JSON.parse(window.localStorage.getItem("seller-info"));
    this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"));
    this.admindata = JSON.parse(window.localStorage.getItem("admin-info"))
    this.subscriptionplan = JSON.parse(window.localStorage.getItem("SUBSCRIPTIONPLAN"));
    // if (this.subscriptionplan == "Free") {
    //   this.productexportoption = true
    // } else if (this.subscriptionplan == "Starter") {
    //   this.productexportoption = true
    // }
    // else if (this.subscriptionplan == "Professional") {
    //   this.productexportoption = true
    // }
    // else if (this.subscriptionplan == "Enterprise") {
    //   this.productexportoption = true
    // }
    this.productexportoption = true
    if (this.admindata == null && this.sellerdata == null) {
      this.router.navigate(["'/auth/login'"])
    }
    else {
      if (this.sellerdata != null) {
        if (this.sellerdata[0].accountstatus == "Secondery") {
          let status = this.sellerdata[0].roleaccess.includes("Product")
          if (status == false) {
            this.router.navigate(['/page-not-found'])
          } else {

          }
        }
        else if (this.subscriptionplan == "Free") {

        }
        else if (this.subscriptionplan == "Starter") {

        }
        else if (this.subscriptionplan == "Professional") {

        }
        else if (this.subscriptionplan == "Enterprise") {

        }
        else {
          this.router.navigate(['/page-not-found'])
        }
      }
      else {
        this.errortext = "Please select a seller"
        this.error = true
        this.router.navigate(['/all/users-sellers'])
      }
    }
  }

  ngOnInit() {
    this.totalcount = 0
    this.activeproductcount = 0;
    this.inactiveproductcount = 0;
    this.orderform = this.fb.group({
      channel: null,
      status: null,
      box: null,
      supplier: null
    });
    this.Manufacturer = this.sellerdata[0].manufacturer
    if (this.Manufacturer == "Pradip Fabrics") {
      this.shopifyactivation = true
    }
    this.newviewproductlist = [];
    this.status = null
    this.payload = {
      Manufacturer: this.Manufacturer,
      channel: null,
      status: null,
      search: null,
      userid: this.sellerdata[0]._id,
      token: this.sellertoken,
      supplierid: null,
      box: null,
      email: this.sellerdata[0].email
    }
    this.searchtext = "All Product List";
    this.getnewviewproductlist(this.payload);

    this.getnewmodelproductcount(this.payload)
    // 
    // 
    this.getallboxes()
    // this.getAllsuppliers();

  }
  onSelect(event, productid) {
    console.log(productid)
    this.productforexports.forEach((i) => {
      if (i._id == productid) {
        this.validation = false
        this.productforexports = this.productforexports.filter(item => item._id !== productid);
      }
    })
    if (this.validation != false) {
      const payload = {
        email: this.sellerdata[0].email,
        token: this.sellertoken
      }
      this.apiService.getsingleproductdetails(productid, payload)
        .subscribe((products: ProductModel) => {
          this.productModel = products;
          console.log(this.productModel)
          if (this.productModel.MainImage != null || this.productModel.MainImage != undefined) {
            const data = { "Name": this.productModel.Name, "Variant_Requeires_Shipping": "true", "Variant_Fulfillment": "manual", "Variant_Inventory_Policy": "deny", "Variant_Inventory_Tracker": "shopify", "Short": this.productModel.Short, "Published": "TRUE", "Standardized_Product_Type": "Apparel & Accessories > Clothing Accessories", "SKU": this.productModel.SKU, "Long": this.productModel.Long, "MainImage": `https://cdn.shopify.com/s/files/1/0624/9811/1736/files/${this.productModel.SKU}_a.jpg`, "Manufacturer": this.productModel.Manufacturer, "Primary": this.productModel.Primary, "SalePrice": this.productModel.SalePrice, "RetailPrice": this.productModel.RetailPrice, "Number": 1, "Gender": this.productModel.Gender, "status": this.productModel.status }
            this.productforexportsshopify.push(data)
          }
          if (this.productModel.other_image_url != null || this.productModel.other_image_url != undefined) {
            const data = { "Name": "", "SKU": "", "Long": "", "Tags": "", "Variant_Requeires_Shipping": "", "Variant_Fulfillment": "", "Variant_Inventory_Policy": "", "Variant_Inventory_Tracker": "", "Short": "", "Published": "", "Standardized_Product_Type": "", "MainImage": `https://cdn.shopify.com/s/files/1/0624/9811/1736/files/${this.productModel.SKU}_b.jpg`, "Manufacturer": "", "Primary": "", "SalePrice": "", "RetailPrice": "", "Number": 2, "Gender": "", "status": "" }
            this.productforexportsshopify.push(data)
          }
          if (this.productModel.other_image_url1 != null || this.productModel.other_image_url1 != undefined) {
            const data = { "Name": "", "SKU": "", "Long": "", "Tags": "", "Variant_Requeires_Shipping": "", "Variant_Fulfillment": "", "Variant_Inventory_Policy": "", "Variant_Inventory_Tracker": "", "Short": "", "Published": "", "Standardized_Product_Type": "", "MainImage": `https://cdn.shopify.com/s/files/1/0624/9811/1736/files/${this.productModel.SKU}_c.jpg`, "Manufacturer": "", "Primary": "", "SalePrice": "", "RetailPrice": "", "Number": 3, "Gender": "", "status": "" }
            this.productforexportsshopify.push(data)
          }
          if (this.productModel.other_image_url2 != null || this.productModel.other_image_url2 != undefined) {
            const data = { "Name": "", "SKU": "", "Long": "", "Tags": "", "Variant_Requeires_Shipping": "", "Variant_Fulfillment": "", "Variant_Inventory_Policy": "", "Variant_Inventory_Tracker": "", "Short": "", "Published": "", "Standardized_Product_Type": "", "MainImage": `https://cdn.shopify.com/s/files/1/0624/9811/1736/files/${this.productModel.SKU}_d.jpg`, "Manufacturer": "", "Primary": "", "SalePrice": "", "RetailPrice": "", "Number": 4, "Gender": "", "status": "" }
            this.productforexportsshopify.push(data)
          }
          if (this.productModel.other_image_url2 != null || this.productModel.other_image_url2 != undefined) {
            const data = { "Name": "", "SKU": "", "Long": "", "Tags": "", "Variant_Requeires_Shipping": "", "Variant_Fulfillment": "", "Variant_Inventory_Policy": "", "Variant_Inventory_Tracker": "", "Short": "", "Published": "", "Standardized_Product_Type": "", "MainImage": `https://cdn.shopify.com/s/files/1/0624/9811/1736/files/${this.productModel.SKU}_e.jpg`, "Manufacturer": "", "Primary": "", "SalePrice": "", "RetailPrice": "", "Number": 5, "Gender": "", "status": "" }
            this.productforexportsshopify.push(data)
          }
          if (this.productModel.other_image_url3 != null || this.productModel.other_image_url3 != undefined) {
            const data = { "Name": "", "SKU": "", "Long": "", "Tags": "", "Variant_Requeires_Shipping": "", "Variant_Fulfillment": "", "Variant_Inventory_Policy": "", "Variant_Inventory_Tracker": "", "Short": "", "Published": "", "Standardized_Product_Type": "", "MainImage": `https://cdn.shopify.com/s/files/1/0624/9811/1736/files/${this.productModel.SKU}_f.jpg`, "Manufacturer": "", "Primary": "", "SalePrice": "", "RetailPrice": "", "Number": 6, "Gender": "", "status": "" }
            this.productforexportsshopify.push(data)
          }
          if (this.productModel.other_image_url4 != null || this.productModel.other_image_url4 != undefined) {
            const data = { "Name": "", "SKU": "", "Long": "", "Tags": "", "Variant_Requeires_Shipping": "", "Variant_Fulfillment": "", "Variant_Inventory_Policy": "", "Variant_Inventory_Tracker": "", "Short": "", "Published": "", "Standardized_Product_Type": "", "MainImage": `https://cdn.shopify.com/s/files/1/0624/9811/1736/files/${this.productModel.SKU}_g.jpg`, "Manufacturer": "", "Primary": "", "SalePrice": "", "RetailPrice": "", "Number": 7, "Gender": "", "status": "" }
            this.productforexportsshopify.push(data)
          }
          this.productforexports.push(this.productModel)
          console.log(this.productforexports)
          if (this.productforexports.length == 0) {
            this.buttonforexport = false
          } else {
            this.buttonforexport = true
          }
          this.selectproductcount = this.productforexports.length
          console.log(this.productforexports)
          localStorage.setItem("MainData", JSON.stringify(this.productforexports));
          localStorage.setItem("MainDataSHOPIFY", JSON.stringify(this.productforexportsshopify));
          if (this.productforexports.length == 0) {
            this.buttonforexport = false
          }
        })
    } else {
      this.validation = true
      this.selectproductcount = this.productforexports.length
      console.log(this.productforexports)
      localStorage.setItem("MainData", JSON.stringify(this.productforexports))
      if (this.productforexports.length == 0) {
        this.buttonforexport = false
      }
    }


  }
  getnewviewproductlist(payload) {

    this.apiService.getnewmodelproductlist(payload, this.offset, this.count)
      .subscribe((productlist: any[]) => {
        if (productlist.length < 1) {
          this.searchtext = "Enter Correct Product SKU or Select a Field and Submit or Click Reset"
        }
        if (this.newviewproductlist.length > 0) {
          this.newviewproductlist = this.newviewproductlist.concat(productlist)
        }
        else {
          this.newviewproductlist = productlist
        }
        this.isLoading = false;
        this.apiStatus = false;
      });

  }
  getnewviewproductlistcount(payload) {
    console.log(payload)
    this.apiService.getnewmodelproductlistparentskucount(payload)
      .subscribe((res: any) => {
        this.parentskucount = res.length;
      });


    this.totalcount = 0
    this.activeproductcount = 0;
    this.inactiveproductcount = 0;
  }
  getnewmodelproductcount(payload) {
    this.totalcount = 0
    this.apiService.getnewmodelproductcount(payload)
      .subscribe((res: any) => {
        this.totalcount = res;
      });
  }
  getnewmodelactiveproductcount(payload) {
    // this.apiService.getnewmodelactiveproductcount(payload)
    //   .subscribe((res: any) => {
    //     this.activetotalcount = res;
    //   });
  }
  // getnewmodelinactiveproductcount(payload) {
  // this.apiService.getnewmodelinactiveproductcount(payload)
  //   .subscribe((res: any) => {
  //     this.inactivetotalcount = res;
  //   });
  // }
  reset() {
    this.scrollend = true;
    this.offset = 0, this.count = 10
    this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"));
    this.newviewproductlist = [];
    this.status = null
    this.payload = {
      Manufacturer: this.Manufacturer,
      channel: null,
      status: null,
      search: null,
      userid: this.sellerdata[0]._id,
      token: this.sellertoken,
      supplierid: null,
      box: null,
      email: this.sellerdata[0].email
    }
    this.searchtext = "All Product List";
    this.getnewviewproductlist(this.payload);
    // 
    this.getnewmodelproductcount(this.payload)


  }
  expand() {
    console.log(this.expandContent)
    this.expandContent = !this.expandContent
  }
  myFunction() {

    this.newviewproductlist = []
    this.scrollend = true;
    this.status = null
    this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"));
    this.id = this.sellerdata[0]._id;
    this.Manufacturer = this.sellerdata[0].manufacturer
    // Declare variables
    var input, filter, table, tr, td, i, txtValue;
    input = document.getElementById("myInput");
    console.log(input.value)
    // this.text = input.value;
    if (input.value.length > 1) {
      this.offset = 0;
      this.count = 10;
      this.newviewproductlist = [];
      this.payload = {
        Manufacturer: this.Manufacturer,
        channel: null,
        status: null,
        search: input.value,
        userid: this.sellerdata[0]._id,
        token: this.sellertoken,
        supplierid: null,
        box: null,
        email: this.sellerdata[0].email
      }
      this.searchtext = `Product List By Search. Searched By ${input.value}`;
      this.newviewproductlist = []
      if(this.apiStatus == false){
        this.apiStatus = true;
      this.getnewviewproductlist(this.payload);
      this.getnewmodelproductcount(this.payload)
      }


    }
    else {
      this.searchtext = "Please Enter Your Search Keyword";
      this.status = null
    this.payload = {
      Manufacturer: this.Manufacturer,
      channel: null,
      status: null,
      search: null,
      userid: this.sellerdata[0]._id,
      token: this.sellertoken,
      supplierid: null,
      box: null,
      email: this.sellerdata[0].email
    }
    this.searchtext = "All Product List";
    this.getnewviewproductlist(this.payload);

    this.getnewmodelproductcount(this.payload)
    // 
    // 
    this.getallboxes()
    }
  }
  getuserselectionforproductlist(orderform) {
    this.orderform = this.fb.group({
      channel: orderform.value.channel,
      status: null,
      box: null,
      supplier: null
    });
    this.payload.status = null;
    this.payload.box = null;
    this.payload.supplier = null;
    this.channelname = orderform.value.channel;
    if (this.channelname == "Supplier" || this.channelname == "Box") {
      this.productlistselectiontype = true
      if (this.channelname == "Supplier") {
        this.supplierbox = true
      }
      else {
        this.supplierbox = false
      }
    }
    else {
      this.productlistselectiontype = false
    }
  }
  getallboxes() {
    const sellertoken = JSON.parse(window.localStorage.getItem("TOKEN"));
    const payload = { "manufacturer": this.user[0].manufacturer, "token": sellertoken }
    this.apiService.getboxbyId(payload).subscribe((boxex: any[]) => {
      this.box_list = boxex;
    })
  }
  // getAllsuppliers() {
  //   this.user = JSON.parse(window.localStorage.getItem("seller-info"));
  //   if (this.user != null && this.user.length > 0) {
  //     this.Id = this.user[0].manufacturer;
  //     const payload = {
  //       "manufacturer": this.user[0].manufacturer
  //     }
  //     this.apiService.getsuppliersbyId(payload, 0, 1000)
  //       .subscribe((suppliers: any[]) => {

  //         this.supplier_list = suppliers
  //       })
  //   }

  // }
  dataSubmit(orderform) {
    this.scrollend = true
    this.offset = 0
    this.count = 10
    this.Manufacturer = this.sellerdata[0].manufacturer
    this.payload = {
      Manufacturer: this.Manufacturer,
      channel: orderform.value.channel,
      status: orderform.value.status,
      box: orderform.value.box,
      supplierid: orderform.value.supplier,
      search: null,
      userid: this.sellerdata[0]._id,
      email: this.sellerdata[0].email,
      token: this.sellerdata[0].token
    }
    this.newviewproductlist = []
    this.apiService.getnewmodelproductlist(this.payload, this.offset, this.count)
      .subscribe((productlist: any[]) => {
        if (productlist.length < 1) {
          this.searchtext = "Enter Correct Product SKU or Select a Field and Submit or Click Reset"
        }
        if (this.newviewproductlist.length > 0) {
          this.newviewproductlist = this.newviewproductlist.concat(productlist)
        }
        else {
          this.newviewproductlist = productlist
        }
        this.isLoading = false;
      });

    this.getnewmodelproductcount(this.payload)



  }

  openproductdetails(content, item) {
    this.apiService.getsearchproductfulldetails(this.id, item.item_sku)
      .subscribe((productlist: any[]) => {
        if (productlist.length < 1) {
          this.searchtext1 = "Please enter correct SKU code"
        }

        this.productdetails = productlist
      });
    this.modalService.open(content, {
      size: "xl",
      backdrop: "static",
      keyboard: false,
      windowClass: "product-module",
      centered: true,
    });
  }
  getcount() {
    this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"));
    this.id = this.sellerdata[0]._id;
    this.Manufacturer = this.sellerdata[0].manufacturer
    // this.apiService.getactiveproductcount(this.Manufacturer)
    //   .subscribe((res: any) => this.activecount = res);
    // this.apiService.getinactiveproductcount(this.Manufacturer)
    //   .subscribe((res: any) => this.inactivecount = res);
  }

  changeStatus(product) {
      product.status = !product.status
      this.isLoading = true;

    this.idforactivepopupshow = product
    
    const payload = {
      SKU: product.SKU, // Assuming product has a property called 'sku'
      status: product.status,
      token: this.sellertoken
    };

    this.apiService.updateproductstatusbytoggle(payload)
    .subscribe((res: any) => {
      console.log('Product status updated successfully');

    },
    (error) => {
      console.error('Error updating product status:', error);
    }
  
  )

    // if (product.status == true) {
    // }
    // else {
    // }

  }

  onactiveconfirm() {

    this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"));
    this.id = this.sellerdata[0]._id;
    this.Manufacturer = this.sellerdata[0].manufacturer
    this.product = this.idforactivepopupshow

    if (this.newproductactivestatus.relevent_details == undefined) {
      this.activeinactiveerrortext = "Please submit relevant details"
    }
    else {
      this.newproductactivestatus.manufacturer = this.Manufacturer;
      this.newproductactivestatus.sku = this.idforactivepopupshow.SKU;
      this.newproductactivestatus.user_id = this.id;
      this.newproductactivestatus.token = this.sellerdata[0].token
      this.newproductactivestatus.email = this.sellerdata[0].email



      this.apiService.getproductstatusactiveinactive(this.newproductactivestatus)
        .subscribe((res: any) => {

          this.newproductactivestatus = new Productactivestatus()
          this.activepopupshow = false
          this.deactivepopupshow = false

          this.product = this.idforactivepopupshow
          console.log(this.product);
          if (this.product.status == true) {

            const payload = {
              firstname: this.sellerdata[0].firstname,
              lastname: this.sellerdata[0].lastname,
              email: this.sellerdata[0].email,
              token: this.sellerdata[0].token,
              userid: this.sellerdata[0]._id
            }




            this.apiService.inactiveproduct(this.product._id, payload)
              .subscribe((res) => {
                if (res) {
                  this.newviewproductlist = [];
                  this.offset = 0;
                  this.count = 10;
                  this.payload = {
                    Manufacturer: this.Manufacturer,
                    channel: null,
                    status: null,
                    search: null,
                    userid: this.sellerdata[0]._id,
                    token: this.sellertoken,
                    supplierid: null,
                    box: null,
                    email: this.sellerdata[0].email
                  }
                  this.getnewviewproductlist(this.payload);

                  this.getnewmodelproductcount(this.payload)


                }
              })


          }

          else {
            this.deactivepopupshow = false
            const payload = {
              firstname: this.sellerdata[0].firstname,
              lastname: this.sellerdata[0].lastname,
              email: this.sellerdata[0].email,
              token: this.sellerdata[0].token,
              userid: this.sellerdata[0]._id
            }
            this.apiService.activeproduct(this.product._id, payload)
              .subscribe((res) => {
                if (res) {
                  this.newviewproductlist = [];
                  this.offset = 0;
                  this.count = 10;
                  this.payload = {
                    Manufacturer: this.Manufacturer,
                    channel: null,
                    status: null,
                    search: null,
                    userid: this.sellerdata[0]._id,
                    token: this.sellertoken,
                    supplierid: null,
                    box: null,
                    email: this.sellerdata[0].email
                  }
                  this.getnewviewproductlist(this.payload);

                  this.getnewmodelproductcount(this.payload)

                }
              })
          }

        })
    }




  }
  settozero() {

    this.activeinactiveerrortext = ''
  }
  onactivecancel() {
    this.activepopupshow = false
    this.idforactivepopupshow = null
    this.activeinactiveerrortext = ''
    this.newproductactivestatus = new Productactivestatus()

  }



  ondeactivecancel() {
    this.activeinactiveerrortext = ''
    this.deactivepopupshow = false
    this.idfordeactivepopupshow = null

    this.newproductactivestatus = new Productactivestatus()


  }


  onScroll1() {

    if (this.scroll == true && this.scrollend == true) {
      this.scroll = false

      this.offset++;
      this.isLoading = true;
      this.apiService.getnewmodelproductlist(this.payload, this.offset, this.count)
        .subscribe((productlist: any[]) => {
          if (productlist.length == 0) {
            this.scrollend = false
          }
          if (productlist.length < 1) {
            this.searchtext = "Enter Correct Product SKU or Select a Field and Submit or Click Reset"
          }
          if (this.newviewproductlist.length > 0) {
            this.newviewproductlist = this.newviewproductlist.concat(productlist)
          }
          else {
            this.newviewproductlist = productlist
          }
          this.isLoading = false;
          this.scroll = true
        });
    }
  }


  onAddVariatation(id) {
    this.variationpopup = true
    this.idforproductvariation = id

  }
  onVariationconfirm() {
    this.variationpopup = false
    this.router.navigate(['/products/physical/add-variation/' + this.idforproductvariation]);

  }
  onVariationcancel() {
    this.variationpopup = false
    this.idforproductvariation = null
  }

  onEdit(id) {
    this.editpopup = true
    this.idforproductedit = id


  }
  onEditconfirm() {
    this.editpopup = false
    this.router.navigate(['/products/physical/add-product/' + this.idforproductedit]);
  }
  onEditcancel() {
    this.editpopup = false
    this.idforproductedit = null
  }

  onCopy(id) {
    this.copypopup = true
    this.idforproductcopy = id

  }
  onCopyconfirm() {
    this.copypopup = false
    this.ID = this.id;
    localStorage.setItem("url", JSON.stringify(this.idforproductcopy));
    this.router.navigate(['/products/physical/add-product/0']);
  }
  onCopycancel() {
    this.copypopup = false
    this.idforproductcopy = null
  }

  onDelete(event) {

    this.popupshow = true
    this.idforproductdelete = event


  }
  onDeleteconfirm() {
    this.popupshow = false
    this.apiService.deleteproduct(this.idforproductdelete)
      .subscribe(res => {

        this.deletesuccess = true
        this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"));
        this.id = this.sellerdata[0]._id;
        this.getcount();
        const payload = {
          token: this.sellerdata[0].token,
          email: this.sellerdata[0].email

        }
        this.apiService.getactiveproductlist(this.id, this.offset, this.count, payload)
          .subscribe((productlist: any[]) => this.activeproduct_list = productlist);

        this.apiService.getinactiveproductlist(this.id, this.offset, this.count, payload)
          .subscribe((productlist: any[]) => this.inactiveproduct_list = productlist);
        this.router.navigate(['/products/physical/productlist']);

      });
  }
  onDeletecancel() {
    this.popupshow = false
    this.idforproductdelete = null
  }
  Deletesuccess() {
    this.deletesuccess = false

    this.popupshow = false
    location.reload()
  }

  offer(id) {
    if (confirm("Are you sure to change the offer type")) {
      localStorage.removeItem("url");
      this.ID = id;
      localStorage.setItem("url", JSON.stringify(this.ID));
      this.router.navigate(['/offers']);
    }
  }




  productdetailsfunction() {
    this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"));
    this.id = this.sellerdata[0]._id;
    // Declare variables
    var input, filter, table, tr, td, i, txtValue;
    input = document.getElementById("myInput");

    console.log(input.value)
    this.searchtext1 = input.value;
    if (input.value.length > 1) {
      this.apiService.getsearchproductfulldetails(this.id, input.value)
        .subscribe((productlist: any[]) => {
          if (productlist.length < 1) {
            this.searchtext1 = "Please enter correct SKU code"
          }

          this.productdetails = productlist
        });
    }
    else {
      this.searchtext1 = "Please enter full SKU code";
      this.searchproduct_list = [];
    }
  }


  addProducttoShopify(product) {
    if (product.Shopifyproductid == "" || product.Shopifyproductid == undefined) {
      this.shopifypopupforadd = true
      this.idforproductshopify = product
    }



    else {
      this.shopifypopupforremove = true
      this.idforproductshopify = product
    }



  }

  onAddshopifyconfirm() {
    this.shopifypopupforadd = false
    {
      const payload = {
        "product": this.idforproductshopify.SKU,
        email: this.sellerdata[0].email,
        token: this.sellerdata[0].token,
        // invoiceno: this.sellerdata[0].invoiceno
      }
      this.apiService.addProducttoShopify(payload).subscribe((res) => {
        console.log(res);
        wait(10000)
        // alert("product has been added to Shopify")
        $("#addedtoshopify").show()
        $("#Loginmessage").show()
        $("#ok").click(function () {
          $("#Loginmessage").hide()
          $("#addedtoshopify").hide()
        });


        this.offset = 0;
        this.count = 10;
        this.newviewproductlist = [];
        this.payload = {
          Manufacturer: this.Manufacturer,
          channel: null,
          status: null,
          search: null,
          userid: this.sellerdata[0]._id,
          token: this.sellertoken,
          supplierid: null,
          box: null,
          email: this.sellerdata[0].email
        }
        this.getnewviewproductlist(this.payload);

        this.getnewmodelproductcount(this.payload)



      })
    }
  }
  onAddshopifycancel() {
    this.shopifypopupforadd = false
    this.idforproductshopify = null
  }
  onRemoveshopifyconfirm() {
    this.shopifypopupforremove = false
    {

      const payload = {
        "Shopifyproductvarientid": this.idforproductshopify.Shopifyproductvarientid,
        "Shopifyproductid": this.idforproductshopify.Shopifyproductid
      }

      this.apiService.deleteProductfromShopify(payload).subscribe((res) => {

        // alert("product has been removed from Shopify")
        $("#removeshopify").show()
        $("#Loginmessage").show()
        $("#ok").click(function () {
          $("#Loginmessage").hide()
          $("#removeshopify").hide()
        });
        console.log(res)
        this.offset = 0;
        this.count = 10;
        this.newviewproductlist = [];
        this.payload = {
          Manufacturer: this.Manufacturer,
          channel: null,
          status: null,
          search: null,
          userid: this.sellerdata[0]._id,
          token: this.sellertoken,
          supplierid: null,
          box: null,
          email: this.sellerdata[0].email
        }
        this.getnewviewproductlist(this.payload);

        this.getnewmodelproductcount(this.payload)



      })
    }
  }
  onRemoveshopifycancel() {
    this.shopifypopupforremove = false
    this.idforproductshopify = null
  }





  async exportforflipkart() {
    const objectToCsv = async function (data) {
      const csvRows = [];
      const headers = Object.keys(data[0]);
      csvRows.push(headers.join(','));
      for (const row of data) {
        const values = headers.map(header => {
          const val = row[header];
          const escaped = ('' + row[header]).replace(/"/g, '\\"');
          return `"${escaped}"`;
        });
        csvRows.push(values.join(','));
      }
      return csvRows.join('\n');
    };
    const download = async function (data) {
      const blob = new Blob([data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('hidden', '');
      a.setAttribute('href', url);
      a.setAttribute('download', 'flipkart.csv');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };

    const res = JSON.parse(window.localStorage.getItem("MainData"));
    if (res.length > 0) {
      const json = res;
      const data = json.map(row => ({
        "Seller SKU ID": row.SKU,
        "Listing ID": "",
        "Reason of Deactivation": "",
        "Listing Status": "",
        "MRP (INR)": row.RetailPrice,
        "Your selling price (INR)": row.SalePrice,
        "Fullfilment by": row.Manufacturer,
        "Procurement type": "",
        "Procurement SLA (DAY)": "",
        "Stock": row.number_of_items,
        "Local delivery charge (INR)": "",
        "Zonal delivery charge (INR)": "",
        "National delivery charge (INR)": "",
        "Package Weight (KG)": "",
        "Package Length (CM)": "",
        "Package Breadth (CM)": "",
        "Package Height (CM)": "",
        "HSN": "",
        "Luxury Cess": "",
        "Country of Origin": row.COO,
        "Manufacturer Details": row.Manufacturer,
        "Packer Details": row.Manufacturer,
        "Importer Details": row.Manufacturer,
        "Tax Code": "",
        "Brand": row.Brand,
        "Size": row.Size,
        "Size - Measuring Unit": "",
        "Style Code": "",
        "Occasion": "",
        "Fabric": "",
        "Pattern": "",
        "Type": "",
        "Sari Purity": "",
        "Color": row.Color,
        "Brand Color": "",
        "Ideal For": "",
        "Pack of": "",
        "Blouse Piece Type": "",
        "Fabric Care": "",
        "Sari Length": "",
        "Blouse Piece Length (m)": "",
        "Sari Style": "",
        "Main Image URL": row.MainImage,
        "Other Image URL 1": row.other_image_url2,
        "Other Image URL 2": row.other_image_url3,
        "Other Image URL 3": row.other_image_url4,
        "Other Image URL 4": row.other_image_url5,
        "Other Image URL 5": row.other_image_url6,
        "Other Image URL 6": row.other_image_url7,
        "Other Image URL 7": "",
        "Main Palette Image URL": row.MainImage,
        "Group ID": "",
        "EAN/UPC": "",
        "EAN/UPC - Measuring Unit": "",
        "Weight (kg)": "",
        "Other Details": "",
        "Sales Package": "",
        "Description": row.Long,
        "Search Keywords": "",
        "Video URL": "",
        "Domestic Warranty": "",
        "Domestic Warranty - Measuring Unit": "",
        "International Warranty": "",
        "International Warranty - Measuring Unit": "",
        "Warranty Service Type": "",
        "Covered in Warranty": "",
        "Not Covered in Warranty": "",
        "Uniform": "",
        "Product Title": row.Name,
        "Trend": "",
        "Construction Type": "",
        "Handloom Product": "",
        "Hand Embroidery": "",
        "Pattern/Print Type": "",
        "Border Length": "",
        "Border Details": "",
        "Blouse Pattern": "",
        "Decorative Material": "",
        "Blouse Fabric": "",
        "Type of Embroidery": "",
        "Embroidery Method": "",
        "Secondary Color": "",
        "Trend AW 16": ""
      }))

      console.log(data);

      const csvData = await objectToCsv(data)
      await download(csvData);
    }
    else {
      alert("No data found")
    }

  }

  async exportforamazon() {
    const objectToCsv = async function (data) {
      const csvRows = [];
      const headers = Object.keys(data[0]);
      csvRows.push(headers.join(','));
      for (const row of data) {
        const values = headers.map(header => {
          const val = row[header];
          const escaped = ('' + row[header]).replace(/"/g, '\\"');
          return `"${escaped}"`;
        });
        csvRows.push(values.join(','));
      }
      return csvRows.join('\n');
    };
    const download = async function (data) {
      const blob = new Blob([data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('hidden', '');
      a.setAttribute('href', url);
      a.setAttribute('download', 'amazon.csv');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };

    const res = JSON.parse(window.localStorage.getItem("MainData"));
    if (res.length > 0) {
      const json = res;
      const data = json.map(row => ({

        "feed_product_type": "",
        "item_sku": row.SKU,
        "brand_name": row.Brand,
        "part_number": "",
        "external_product_id": "",
        "external_product_id_type": "",
        "item_name": row.Name,
        "recommended_browse_nodes": "",
        "care_instructions": "",
        "design_name": "",
        "occasion_type1": "",
        "occasion_type2": "",
        "occasion_type3": "",
        "occasion_type4": "",
        "occasion_type5": "",
        "color_map": row.Color,
        "pattern_type": "",
        "material_type": "",
        "color_name": row.Color,
        "weave_type": "",
        "department_name": row.Primary,
        "included_components": "",
        "item_length_description": "",
        "country_of_origin": row.COO,
        "fabric_type": "",
        "external_product_information": "",
        "standard_price": row.SalePrice,
        "quantity": row.number_of_items,
        "condition_type": "New",
        "maximum_retail_price": row.RetailPrice,
        "main_image_url": row.ProductImage,
        "age_range_description": "",
        "target_gender": row.Gender,
        "other_image_url1": row.other_image_url1,
        "other_image_url2": row.other_image_url2,
        "other_image_url3": row.other_image_url3,
        "other_image_url4": row.other_image_url4,
        "other_image_url5": row.other_image_url5,
        "other_image_url6": row.other_image_url6,
        "other_image_url7": row.other_image_url7,
        "other_image_url8": "",
        "swatch_image_url": "",
        "parent_child": "",
        "parent_sku": row.UPC,
        "relationship_type": "",
        "variation_theme": "",
        "update_delete": "",
        "product_description": "",
        "manufacturer": row.Manufacturer,
        "closure_type": "",
        "model_name": "",
        "model": "",
        "bullet_point1": "",
        "bullet_point2": "",
        "bullet_point3": "",
        "bullet_point4": "",
        "bullet_point5": "",
        "generic_keywords": "",
        "style_name": "",
        "is_autographed": "",
        "packer_contact_information": "",
        "item_type_name": "",
        "duration_unit_of_measure": "",
        "sport_type1": "",
        "sport_type2": "",
        "subject_character": "",
        "seasons": "",
        "athlete": "",
        "team_name": "",
        "collection_name": "",
        "importer_contact_information": "",
        "duration": "",
        "lifestyle": "",
        "manufacturer_contact_information": "",
        "fur_description": "",
        "fit_type": "",
        "theme": "",
        "league_name": "",
        "special_size_type": "",
        "sleeve_type": "",
        "size_name": "",
        "lifecycle_supply_type": "",
        "item_booking_date": "",
        "sub_brand_name": "",
        "fabric_wash": "",
        "item_width_unit_of_measure": "",
        "item_width": row.breadth,
        "item_height": row.height,
        "unit_count": row.number_of_items,
        "item_height_unit_of_measure": "",
        "item_length_unit_of_measure": "",
        "item_length": row.length,
        "unit_count_type": "",
        "fulfillment_center_id": "",
        "package_weight": "",
        "package_weight_unit_of_measure": "",
        "package_height": "",
        "package_height_unit_of_measure": "",
        "package_length": "",
        "package_length_unit_of_measure": "",
        "package_width": "",
        "package_width_unit_of_measure": "",
        "item_weight": "",
        "item_weight_unit_of_measure": "",
        "batteries_required": "",
        "are_batteries_included": "",
        "battery_cell_composition": "",
        "battery_type1": "",
        "battery_type2": "",
        "battery_type3": "",
        "number_of_batteries1": "",
        "number_of_batteries2": "",
        "number_of_batteries3": "",
        "battery_weight": "",
        "battery_weight_unit_of_measure": "",
        "number_of_lithium_metal_cells": "",
        "number_of_lithium_ion_cells": "",
        "lithium_battery_packaging": "",
        "lithium_battery_energy_content": "",
        "lithium_battery_energy_content_unit_of_measure": "",
        "lithium_battery_weight": "",
        "lithium_battery_weight_unit_of_measure": "",
        "supplier_declared_dg_hz_regulation1": "",
        "supplier_declared_dg_hz_regulation2": "",
        "supplier_declared_dg_hz_regulation3": "",
        "supplier_declared_dg_hz_regulation4": "",
        "supplier_declared_dg_hz_regulation5": "",
        "hazmat_united_nations_regulatory_id": "",
        "safety_data_sheet_url": "",
        "item_volume": "",
        "item_volume_unit_of_measure": "",
        "flash_point": "",
        "supplier_declared_material_regulation1": "",
        "supplier_declared_material_regulation2": "",
        "supplier_declared_material_regulation3": "",
        "ghs_classification_class1": "",
        "ghs_classification_class2": "",
        "ghs_classification_class3": "",
        "legal_disclaimer_description": "",
        "safety_warning": "",
        "condition_note": "",
        "fulfillment_latency": "",
        "merchant_release_date": "",
        "restock_date": "",
        "product_tax_code": "",
        "sale_price": "",
        "sale_from_date": "",
        "sale_end_date": "",
        "max_order_quantity": "",
        "number_of_items": "",
        "offering_can_be_gift_messaged": "",
        "offering_can_be_giftwrapped": "",
        "map_price": "",
        "list_price_with_tax": "",
        "currency": "",
        "offering_end_date": "",
        "merchant_shipping_group_name": "",
        "offering_start_date": "",
        "business_price": "",
        "quantity_price_type": "",
        "quantity_price1": "",
        "quantity_lower_bound1": "",
        "quantity_price2": "",
        "quantity_lower_bound2": "",
        "quantity_price3": "",
        "quantity_lower_bound3": "",
        "quantity_price4": "",
        "quantity_lower_bound4": "",
        "quantity_price5": "",
        "quantity_lower_bound5": "",
        "pricing_action": "",
        "unspsc_code": "",
        "national_stock_number": "",
      }))

      console.log(data);

      const csvData = await objectToCsv(data)
      await download(csvData);
    }
    else {
      alert("No data found")
    }

  }

  async exportformeesho() {
    const objectToCsv = async function (data) {
      const csvRows = [];
      const headers = Object.keys(data[0]);
      csvRows.push(headers.join(','));
      for (const row of data) {
        const values = headers.map(header => {
          const val = row[header];
          const escaped = ('' + row[header]).replace(/"/g, '\\"');
          return `"${escaped}"`;
        });
        csvRows.push(values.join(','));
      }
      return csvRows.join('\n');
    };
    const download = async function (data) {
      const blob = new Blob([data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('hidden', '');
      a.setAttribute('href', url);
      a.setAttribute('download', 'meesho.csv');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };

    const res = JSON.parse(window.localStorage.getItem("MainData"));
    if (res.length > 0) {
      const json = res;
      const data = json.map(row => ({
        "Product Name": row.Name,
        "Variation": "",
        "Meesho Price": row.SalePrice,
        "Wrong/Defective Returns Price": "",
        "MRP": row.RetailPrice,
        "GST%": "",
        "HSN ID": "",
        "Net Weight (gms)": row.weight,
        "Inventory": row.number_of_items,
        "Country of Origin": row.COO,
        "Manufacturer Details": row.Manufacturer,
        "Packer Details": row.Manufacturer,
        "Blouse": "",
        "Blouse Fabric": "",
        "Border": "",
        "Color": row.Color,
        "Net Quantity (N)": row.number_of_items,
        "Saree Fabric": "",
        "Transparency": "",
        "Type": "",
        "Image 1 (Front)": row.ProductImage,
        "Image 2": row.other_image_url2,
        "Image 3": row.other_image_url3,
        "Image 4": row.other_image_url4,
        "Product ID / Style ID": "",
        "SKU ID": row.SKU,
        "Brand Name": row.Brand,
        "Group ID": "",
        "Product Description": row.Long,
        "Importer Details": "",
        "Blouse Color": "",
        "Blouse Pattern": "",
        "Border Width": "",
        "Brand": row.Brand,
        "Loom Type": "",
        "Occasion": "",
        "Ornamentation": "",
        "Pallu Details": "",
        "Pattern": "",
        "Print or Pattern Type": "",
        "Blouse Length Size": "",
        "Saree Length Size": "",

      }))

      console.log(data);

      const csvData = await objectToCsv(data)
      await download(csvData);
    }
    else {
      alert("No data found")
    }

  }

  async exportforshopify() {
    const objectToCsv = async function (data) {
      const csvRows = [];
      const headers = Object.keys(data[0]);
      csvRows.push(headers.join(','));
      for (const row of data) {
        const values = headers.map(header => {
          const val = row[header];
          const escaped = ('' + row[header]).replace(/"/g, '\\"');
          return `"${escaped}"`;
        });
        csvRows.push(values.join(','));
      }
      return csvRows.join('\n');
    };
    const download = async function (data) {
      const blob = new Blob([data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('hidden', '');
      a.setAttribute('href', url);
      a.setAttribute('download', 'shopify.csv');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };
    const res = JSON.parse(window.localStorage.getItem("MainDataSHOPIFY"));
    if (res.length > 0) {
      const json = res;
      const data = json.map(row => ({
        "Handle": "",
        "Title": row.Name,
        "Body (HTML)": row.Long,
        "Vendor": row.Manufacturer,
        "Standardized Product Type": row.Standardized_Product_Type,
        "Custom Product Type": row.Primary,
        "Tags": row.Short,
        "Published": row.Published,
        "Option1 Name": row.Name,
        "Option1 Value": "",
        "Option2 Name": "",
        "Option2 Value": "",
        "Option3 Name": "",
        "Option3 Value": "",
        "Variant SKU": row.SKU,
        "Variant Grams": "",
        "Variant Inventory Tracker": row.Variant_Inventory_Tracker,
        "Variant Inventory Policy": row.Variant_Inventory_Policy,
        "Variant Fulfillment Service": row.Variant_Fulfillment,
        "Variant Price": row.SalePrice,
        "Variant Compare At Price": row.RetailPrice,
        "Variant Requires Shipping": row.Variant_Requeires_Shipping,
        "Variant Taxable": "",
        "Variant Barcode": "",
        "Image Src": row.MainImage,
        "Image Position": row.Number,
        "Image Alt Text": "",
        "Gift Card": "",
        "SEO Title": row.Name,
        "SEO Description": row.Long,
        "Google Shopping / Google Product Category": row.Primary,
        "Google Shopping / Gender": row.Gender,
        "Google Shopping / Age Group": "",
        "Google Shopping / MPN": "",
        "Google Shopping / AdWords Grouping": "",
        "Google Shopping / AdWords Labels": "",
        "Google Shopping / Condition": "",
        "Google Shopping / Custom Product": row.status,
        "Google Shopping / Custom Label 0": "",
        "Google Shopping / Custom Label 1": "",
        "Google Shopping / Custom Label 2": "",
        "Google Shopping / Custom Label 3": "",
        "Variant Image": "",
        "Variant Weight Unit": "",
        "Variant Tax Code": "",
        "Cost per item": "",
        "Price / International": "",
        "Compare At Price / International": "",
        "Status": row.status

      }))

      console.log(data);

      const csvData = await objectToCsv(data)
      await download(csvData);
    }
    else {
      alert("No data found")
    }
  }
}
function wait(ms: number) {

  var start = new Date().getTime();
  var end = start;
  while (end < start + ms) {
    end = new Date().getTime();
  }

}

function getproductstatusactiveinactive() {
  throw new Error('Function not implemented.');
}



function onDeleteconfirm() {
  throw new Error('Function not implemented.');
}


function onDeletecancel() {
  throw new Error('Function not implemented.');
}
function onReactionconfirm() {
  throw new Error('Function not implemented.');
}

