<div class="priceing-plan-wrap">
    <script src="https://ajax.googleapis.com/ajax/libs/angularjs/1.8.2/angular.min.js"></script>
    <script src="https://checkout.razorpay.com/v1/checkout.js"></script>

    <div class="container">
        <div class="plan-row">
            <!-- <img src="../../../assets/images/vuesax-outline-arrow-left.png" alt="" /> -->
            <div class="plan-heading">
                <h3>A pricing plan for every need</h3>
                <p>Start free. Go enterprise when you are ready</p>
            </div>
        </div>

        <div class="row">
            <div class="col-md-3" *ngFor="let plan of pricingPlans">
                <div class="plan-box">
                    <div class="plan-contant">
                        <h5>{{plan.title}}</h5>
                        <div class="plan-section-row" *ngFor="let feature of plan.features">
                            <img src="../../../assets/images/tick-circle.svg" alt="" />
                            <p>{{feature}}</p>
                        </div>
                        <!-- <div class="plan-section-row ">
                            <img src="../../../assets/images/tick-circle.svg" alt="" />
                            <p>1 Single brand/categories</p>
                        </div>
                        <div class="plan-section-row ">
                            <img src="../../../assets/images/tick-circle.svg" alt="" />
                            <p>2 product images</p>
                        </div>
                        <div class="plan-section-row ">
                            <img src="../../../assets/images/tick-circle.svg" alt="" />
                            <p>Order download</p>
                        </div>
                        <div class="plan-section-row ">
                            <img src="../../../assets/images/tick-circle.svg" alt="" />
                            <p>Start buisness</p>
                        </div> -->
                        <hr>
                        <div class="plan-price-section-row">
                            <h5>Rs. {{plan.amount}}</h5>
                            <p>/ month</p>
                        </div>
                        <div class="plan-feed-action">
                            <button type="button" class="plan-action-link"
                                (click)="getsubscription(plan)">Subscribe</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-md-3">
                <div class="plan-box">
                    <div class="plan-contant">
                        <h5>Starter</h5>
                        <div class="plan-section-row ">
                            <img src="../../../assets/images/tick-circle.svg" alt="" />
                            <p>Free plans included</p>
                        </div>
                        <div class="plan-section-row ">
                            <img src="../../../assets/images/tick-circle.svg" alt="" />
                            <p>Product listing upto 100 products</p>
                        </div>
                        <div class="plan-section-row ">
                            <img src="../../../assets/images/tick-circle.svg" alt="" />
                            <p>5 different brand/1 categories</p>
                        </div>
                        <div class="plan-section-row ">
                            <img src="../../../assets/images/tick-circle.svg" alt="" />
                            <p>5 product images</p>
                        </div>
                        <div class="plan-section-row ">
                            <img src="../../../assets/images/tick-circle.svg" alt="" />
                            <p>Dashboard View</p>
                        </div>
                        <hr>
                        <div class="plan-price-section-row">
                            <h5>Rs. 100</h5>
                            <p>/ month</p>
                        </div>
                        <div class="plan-feed-action">
                            <button type="button" class="plan-action-link"
                                (click)="getsubscription('Starter')">Subscribe</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="plan-box">
                    <div class="plan-contant">
                        <h5>Professional</h5>
                        <div class="plan-section-row ">
                            <img src="../../../assets/images/tick-circle.svg" alt="" />
                            <p>Starter plans included</p>
                        </div>
                        <div class="plan-section-row ">
                            <img src="../../../assets/images/tick-circle.svg" alt="" />
                            <p>Product listing upto 800 products</p>
                        </div>
                        <div class="plan-section-row ">
                            <img src="../../../assets/images/tick-circle.svg" alt="" />
                            <p>10 different brand/5 categories</p>
                        </div>
                        <div class="plan-section-row ">
                            <img src="../../../assets/images/tick-circle.svg" alt="" />
                            <p>Member add facility</p>
                        </div>
                        <div class="plan-section-row ">
                            <img src="../../../assets/images/tick-circle.svg" alt="" />
                            <p>Listing on Shopify</p>
                        </div>
                        <hr>
                        <div class="plan-price-section-row">
                            <h5>Rs. 500</h5>
                            <p>/ month</p>

                        </div>
                        <div class="plan-feed-action">
                            <button type="button" class="plan-action-link"
                                (click)="getsubscription('Professional')">Subscribe</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="plan-box">
                    <div class="plan-contant">
                        <h5>Enterprise</h5>
                        <div class="plan-section-row ">
                            <img src="../../../assets/images/tick-circle.svg" alt="" />
                            <p>Professional plans included</p>
                        </div>
                        <div class="plan-section-row ">
                            <img src="../../../assets/images/tick-circle.svg" alt="" />
                            <p>Product listing unlimited</p>
                        </div>
                        <div class="plan-section-row ">
                            <img src="../../../assets/images/tick-circle.svg" alt="" />
                            <p>10 different brand/categories</p>
                        </div>
                        <div class="plan-section-row ">
                            <img src="../../../assets/images/tick-circle.svg" alt="" />
                            <p>8 product images</p>
                        </div>
                        <div class="plan-section-row ">
                            <img src="../../../assets/images/tick-circle.svg" alt="" />
                            <p>Listing on Shopify + Amazon</p>
                        </div>
                        <hr>
                        <div class="plan-price-section-row">
                            <h5>Rs. 1500</h5>
                            <p>/ month</p>

                        </div>
                        <div class="plan-feed-action">
                            <button type="button" class="plan-action-link"
                                (click)="getsubscription('Enterprise')">Subscribe</button>
                        </div>
                    </div>
                </div>
            </div> -->

        </div>
        <div class="message" [ngClass]="messageColor">
            {{message}}
          </div>
          
    </div>
</div>
