<div class="jesssu-seller-banner-wrap">
    <div class="jesssu-seller-img">
        <img src="../../../../assets/images/Rectangle 1493.svg" />
    </div>
    <div class="jesssu-seller-contant">
        <div class="jesssu-header">
            <div class="jesssu-header-row">
                <div>
                </div>
                <div class="rightside-profile">

                </div>

            </div>
        </div>
        <div class="jesssu-heading">
            <div class="heading-row">
                <div class="jesssu-heading-name">
                    <h4>Category List</h4>
                </div>
                <div class="contant-row">
                    <h6>Home - Category List</h6>
                </div>
            </div>

        </div>


        <div class="category-heading">
            <h4>CATEGORY LIST</h4>
        </div>

        <div class="category-list-box">

            <div class="category-list">
                <input type="text" class="btn-form-control" id="myInput" (keyup)="searchCategories()"
                    placeholder="Search for categories" autofocus>

                <div class="brand-upload-row" infiniteScroll [infiniteScrollDistance]="3"
                    [infiniteScrollThrottle]="3000" (scrolled)="onScroll()">

                    <div class="brand-applications-box" *ngFor="let user of categories">
                        <div class="applications-table-row">
                            <div class="brand-img">
                                <img src={{user.categoryimage}} alt="" />
                            </div>
                            <div class="brand-img-name-row">
                                <div class="brand-name">
                                    <h6>{{user.categoryname}}</h6>
                                    <p class="gender">{{user.gender}}</p>
                                </div>
                                <div>
                                    <button type="button" class="btn-action-link1" value="Submit"
                                        (click)="onEdit(user._id)">Edit
                                        Category</button>
                                    <br>
                                    <button type="button" class="btn-action-link" value="Submit"
                                        (click)="onEdit2(user._id)">Edit
                                        Subcatgory</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>





























      
    </div>
</div>