import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Category, ProductModel, User, Accounts } from 'src/app/shared/models/datamodel';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/shared/service/api.service';
import { ProductimageService } from 'src/app/shared/service/fileupload.service';



@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent implements OnInit {
  showPopup = false;
  loading = false;
  popupText = '';
  public errortext = ""
  public successtext = ""
  public error = false
  public success = false
  selectedFiles: FileList;
  public presentpriceforRetail: Number = 0
  public presentpriceforSell: Number = 0
  currentFileUpload: any;
  FileUploadStatus: Boolean;
  progress: { percentage: number } = { percentage: 0 };
  ID: String;
  public subcategorybox: Boolean
  public categorybox: Boolean
  public Id = String;
  public user = [];
  selectedFile: File = null;
  productModel = new ProductModel();
  isVisible: boolean = false;
  public admindata: any;
  public sellerdata = [];
  public date1: Date;
  public loadingfordataimport = true
  public blankproductstatuspopup: boolean = false;
  public productstatuspopup: boolean = false;
  public types: Category[] = [];
  public selectedSubCategory: String = "0"
  checkboxesDataList = [];
  public finaldata: any;
  public selectedItemsList: any;
  public checkedIDs: any;
  public checkedIDswithURL: any;
  public categories: [];
  public offset: number = 0;
  public count: number = 100;
  Categoryname: any;
  category: Object;
  Subcategories: [];
  Manufacturer: any;
  subscriptionplan: any;
  channels = [
    { id: "Jesssu", name: "Jesssu" }
  ];
  token: any;
  email: any;
  productstatuspopuptext: String = '';
  productstatuspopupforupdate: boolean;
  selectedFileName: string | null = null;

  data: any[] = [
    {
      Availability: '',
      Brand: '',
      COO: '',
      ClassId: '',
      Color: '',
      CreatedDate: '',
      DiscountCurrency: '',
      DiscountType: '',
      Gender: '',
      Keywords: '',
      Long: '',
      M1: '',
      MainImage: '',
      Manufacturer: 'Initial Manufacturer',
      Misc: '',
      Name: '',
      PartNumber: '',
      Pixel: '',
      PriceCurrency: 'INR',
      Primary: '',
      Product: '',
      ProductCurrency: 'INR',
      ProductId: '',
      ProductImage: '',
      ProductType: '',
      RetailPrice: '',
      SKU: '',
      SalePrice: '',
      Secondary: '',
      Secondary1: '',
      number_of_items: '',
      ShippingCurrency: 'INR',
      Short: '',
      Size: '',
      UPC: '',
      userid: '',
      firstname: '',
      lastname: '',
      status: ''
    }
  ];

  constructor(private fb: FormBuilder, private apiService: ApiService, private router: Router, private productimageservice: ProductimageService, private activeroute: ActivatedRoute) {
    this.subcategorybox = false;
    this.token = JSON.parse(window.localStorage.getItem("TOKEN"))
    this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"));
    this.admindata = JSON.parse(window.localStorage.getItem("admin-info"))
    this.subscriptionplan = JSON.parse(window.localStorage.getItem("SUBSCRIPTIONPLAN"));

    if (this.admindata == null && this.sellerdata == null) {
      this.router.navigate(["'/auth/login'"])
    }
    else {
      if (this.sellerdata != null) {
        if (this.sellerdata[0].accountstatus == "Secondery") {
          let status = this.sellerdata[0].roleaccess.includes("Product")
          if (status == false) {
            this.router.navigate(['/page-not-found'])
          } else {
            this.subcategorybox = false;
          }
        }
        else if (this.subscriptionplan == "Free") {
          this.subcategorybox = false;
        }
        else if (this.subscriptionplan == "Starter") {
          this.subcategorybox = false;
        }
        else if (this.subscriptionplan == "Professional") {
          this.subcategorybox = false;
        }
        else if (this.subscriptionplan == "Enterprise") {
          this.subcategorybox = false;
        }
        else {
          this.router.navigate(['/page-not-found'])
        }
      }
      else {
        this.errortext = "Please select a seller"
        this.error = true
        this.router.navigate(['/all/users-sellers'])
      }
    }
  }



  ngOnInit() {
    this.categorybox = true
    this.getCategories();
    this.checkboxesDataList = [
      {
        id: 'Amazon',
        label: 'Amazon',
        isChecked: false,
        url: ""
      },
      {
        id: 'Flipkart',
        label: 'Flipkart',
        isChecked: false,
        url: ""
      },
      {
        id: 'Meesho',
        label: 'Meesho',
        isChecked: false,
        url: ""
      },
      {
        id: 'Snapdeal',
        label: 'Snapdeal',
        isChecked: false,
        url: ""
      },
      {
        id: 'Shopee',
        label: 'Shopee',
        isChecked: false,
        url: ""
      },
      {
        id: 'Jesssu',
        label: 'Jesssu',
        isChecked: false,
        url: ""
      }
    ]
    this.admindata = JSON.parse(window.localStorage.getItem("admin-info"));
    this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"));
    this.Manufacturer = this.sellerdata[0].manufacturer;

    if (this.sellerdata == null || this.sellerdata == undefined) {
      alert("Please select one seller from list.");
      this.router.navigate(['/all/users-sellers'])
    }
    else if (this.sellerdata[0].IsActive == false) {
      alert("Please activate this user");
      this.router.navigate(['/all/users-sellers'])
    }
    var id = this.activeroute.snapshot.url[2].path;
    this.ID = JSON.parse(window.localStorage.getItem("url"));

    if (id.length > 0 && id != '0') {
      const payload = {
        email: this.sellerdata[0].email,
        token: this.token
      }
      this.apiService.getsingleproductdetails(id, payload)
        .subscribe((products: ProductModel) => {
          this.productModel = products;
          this.getselectdata(this.productModel);
          this.categorybox = false;
          this.presentpriceforRetail = this.productModel.RetailPrice
          this.presentpriceforSell = this.productModel.SalePrice
          this.selectedItemsList = this.productModel.channellist
          this.checkboxesDataList.forEach(i => {
            this.productModel.channels.forEach(j => {
              if (i.id == j.channel_name) {
                i.isChecked = true;
                i.url = j.url

              }
            });
          });
        });
    }
    else if (this.ID != null) {
      const payload = {
        email: this.sellerdata[0].email,
        token: this.token
      }
      this.apiService.getsingleproductdetails(this.ID, payload)
        .subscribe((products: ProductModel) => {
          this.categorybox = false
          this.productModel = products;
        });
      localStorage.removeItem("url");

    } else {
      this.productModel.Manufacturer = this.Manufacturer;
      this.productModel.manufacturer = this.Manufacturer;
    }
  }


  // Function to generate and download CSV
  downloadCSV(): void {
    this.updateManufacturer();
    const csvData = this.convertToCSV(this.data);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'products.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  // Convert JSON to CSV format
  convertToCSV(data: any[]): string {
    const header = Object.keys(this.data[0]).join(',') + '\n';
    const csv = data.map(row => Object.values(row).join(',')).join('\n');
    return header + csv;
  }

  // Example function to update Manufacturer value
  updateManufacturer(): void {
    this.data[0].Manufacturer = this.sellerdata[0].manufacturer;
    this.data[0].userid = this.sellerdata[0]._id;
    this.data[0].firstname = this.sellerdata[0].firstname;
    this.data[0].lastname = this.sellerdata[0].lastname;
  }

  getselectdata(productModel1) {
    if (this.checkboxesDataList.indexOf(productModel1.channellist))
      this.checkboxesDataList.filter((value, index) => {
        return value
      });
  }

  onFileSelected(event: any) {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files.length > 0) {
      this.selectedFileName = fileInput.files[0].name;
    } else {
      this.selectedFileName = null;
    }
  }

  onproductstatuspopupsucess() {
    this.productstatuspopup = false;
    this.router.navigate(['/products/physical/product-list'])

  }

  onblankproductstatuspopupsucess() {
    this.blankproductstatuspopup = false;
  }

  Productaddthroughexcelupload() {
    this.currentFileUpload = this.selectedFile;
    if (this.currentFileUpload === null) {
      this.blankproductstatuspopup = true;
    }
    else {

      this.loadingfordataimport = false
      const wait = function (ms) {
        var start = new Date().getTime();
        var end = start;
        while (end < start + ms) {
          end = new Date().getTime();
        }
      }
      console.log(this.sellerdata)
      const userid = this.sellerdata[0].email
      const token = this.token
      this.productimageservice.ImportProduct(this.currentFileUpload, userid, token).subscribe(res => {
        // wait(15000)
        this.productstatuspopup = true;
        this.productstatuspopuptext = "Products has been added successfully!"
      },
      error => {
        this.productstatuspopup = true;
        this.productstatuspopuptext = error.error
        // console.log(error)
        // this.errortext = error.error
        // this.error = true
      });
    }
  }


  selectChange() {
    this.subcategorybox = false;
    this.categories.forEach((i: any) => {
      if (i.categoryname == this.productModel.Primary) {
        this.productModel.Primary = i.categoryname;
        this.productModel.Gender = i.gender
        this.productModel.Categoryid = i._id
      }
    })
    console.log(this.productModel.Category)
    var payload = {
      "id": this.productModel.Categoryid,
      "token": this.token
    }
    this.apiService.getSubcategory(payload)
      .subscribe((res: []) => {
        this.Subcategories = res;
        this.subcategorybox = true;
      })
  }

  selectsubChange() {
    console.log(this.productModel.Secondary)
  }

  getCategories() {
    let payload = {
      token: this.token

    }

    this.apiService.getCategoryList(payload, this.offset, this.count)
      .subscribe((res: []) => {
        this.categories = res

      });
  }




  fetchSelectedItems() {

    this.selectedItemsList = this.checkboxesDataList.filter((value, index) => {
      return value.isActive
    });
  }
  changeSelection() {
    this.fetchSelectedItems();
    this.fetchCheckedIDs();
  }

  fetchCheckedIDs() {

    this.checkedIDs = [];
    this.checkedIDswithURL = [];

    this.checkboxesDataList.forEach((value, index) => {
      if (value.isChecked) {
        var channel = {
          channel_name: value.id,
          url: value.url
        }
        this.checkedIDs.push(value.id);
        this.checkedIDswithURL.push(channel)
      }
    });
  }
  close() {
    this.error = false;
    this.success = false
  }

  upload(type) {

    this.FileUploadStatus = true;
    this.currentFileUpload = this.selectedFiles.item(0);
    this.productimageservice.pushFileToStorage(this.currentFileUpload).subscribe(res => {

      this.FileUploadStatus = false;
      if (type == 'main') {
        this.productModel.ProductImage = res.toString();
        this.productModel.MainImage = res.toString();
      }
      else if (type == 'other0') {
        this.productModel.other_image_url = res.toString();
      }
      else if (type == 'other1') {
        this.productModel.other_image_url2 = res.toString();
      }
      else if (type == 'other2') {
        this.productModel.other_image_url3 = res.toString();
      }
      else if (type == 'other3') {
        this.productModel.other_image_url4 = res.toString();
      }
      else if (type == 'other4') {
        this.productModel.other_image_url5 = res.toString();
      }
      else if (type == 'other5') {
        this.productModel.other_image_url6 = res.toString();
      }
      else if (type == 'other6') {
        this.productModel.other_image_url7 = res.toString();
      }
      else if (type == 'other7') {
        this.productModel.raw_main_image_url = res.toString();
      }
      else if (type == 'other8') {
        this.productModel.raw_other_image_url = res.toString();
      }
    });
  }
  selectFile(event, type) {
    this.selectedFiles = event.target.files;
    this.upload(type);
  }


  onSubmit(form: NgForm) {
    this.productModel.Manufacturer = this.Manufacturer;
    this.productModel.manufacturer = this.Manufacturer;

    console.log(this.productModel)

    if (this.FileUploadStatus == true) {

      this.errortext = "File is uploading. Please wait"
      this.error = true

    }
    else {
      this.fetchCheckedIDs();
      var id = this.activeroute.snapshot.url[2].path;


      if (id.length > 1 && id != '0') {

        this.productModel.launch_date = this.date1;
        this.productModel.channellist = this.checkedIDs;
        this.productModel.channels = this.checkedIDswithURL;

        this.productModel.email = this.sellerdata[0].email;
        this.productModel.token = this.token

        this.apiService.editproduct(id, this.productModel)
          .subscribe(re => {
            this.productstatuspopupforupdate = true
            if (this.productModel.Shopifyproductid != undefined) {
              if (this.presentpriceforRetail == this.productModel.RetailPrice &&
                this.presentpriceforSell == this.productModel.SalePrice) {

              }
              else {

                if (confirm("Are you sure to update the product price on Shopify")) {

                  this.apiService.updateProductpriceonShopify(this.productModel).subscribe((res) => {
                    this.successtext = "Price has been updated to Shopify"
                    this.error = false
                    this.success = true
                    this.router.navigate(['/products/physical/product-list'])
                  })

                }
                else {
                  this.router.navigate(['/products/physical/product-list'])
                }
              }
            }
          });
      }
      else {
        console.log(form.value);
        this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"));
        if (this.sellerdata[0].IsActive == false) {
          this.errortext = "Please activate this user"
          this.error = true

        }
        else {
          let userDetails = new User()
          // this.productModel.user[0] = new User();
          userDetails.user_id = this.sellerdata[0]._id;
          userDetails.firstname = this.sellerdata[0].firstname;
          userDetails.lastname = this.sellerdata[0].lastname;
          this.productModel.channellist = this.checkedIDs;
          this.productModel.channels = this.checkedIDswithURL;
          this.productModel.user = [userDetails]

          console.log(this.productModel);
          if (this.productModel.Name == null) {
            this.errortext = "Please provide Product Name"
            this.error = true

          }
          else if (this.productModel.Primary == null) {
            this.errortext = "Please select product category"
            this.error = true

          }
          else if (this.productModel.SKU == null) {
            this.errortext = "Please provide Product SKU No"
            this.error = true

          }
          else if (this.productModel.RetailPrice == null) {
            this.errortext = "Please provide Item Maximum Retail Price"
            this.error = true

          }
          else if (this.productModel.SalePrice == null) {
            this.errortext = "Please provide Item Sell Price"
            this.error = true

          }
          else if (this.productModel.Long == null) {
            this.errortext = "Please provide Product Description"
            this.error = true

          }
          else if (this.productModel.number_of_items == null) {
            this.errortext = "Please provide Number of Items"
            this.error = true

          }
          else if (this.productModel.COO == null) {
            this.errortext = "Please select country of origin"
            this.error = true

          }

          // else if (!this.productModel.MainImage && !this.productModel.other_image_url && !this.productModel.other_image_url1 && !this.productModel.other_image_url2 && !this.productModel.other_image_url3 && !this.productModel.other_image_url4 && !this.productModel.other_image_url5 && !this.productModel.other_image_url6 && !this.productModel.other_image_url7) {
          //   this.errortext = "Please select product image"
          //   this.error = true
          // }
          else {
            if (this.productModel.COO == "INDIA") {
              this.productModel.DiscountCurrency = "INR"
              this.productModel.PriceCurrency = "INR"
              this.productModel.ShippingCurrency = "INR"
              this.productModel.ProductCurrency = "INR"
            } else if (this.productModel.COO == "USA") {
              this.productModel.DiscountCurrency = "USD"
              this.productModel.PriceCurrency = "USD"
              this.productModel.ShippingCurrency = "USD"
              this.productModel.ProductCurrency = "USD"
            }
            else {
              this.productModel.DiscountCurrency = "INR"
              this.productModel.PriceCurrency = "INR"
              this.productModel.ShippingCurrency = "INR"
              this.productModel.ProductCurrency = "INR"
            }
            this.productModel.channellist = this.checkedIDs;
            this.productModel.token = this.token
            this.apiService.createproduct(this.productModel)
              .subscribe(re => {
                this.productstatuspopup = true;
                this.productModel = new ProductModel

              },
                error => {
                  // console.log(error)
                  this.errortext = error.error
                  this.error = true
                })
          }
        }



      }
    }

  }
  onSelectedFile(event) {
    this.selectedFile = <File>event.target.files[0]
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files.length > 0) {
      this.selectedFileName = fileInput.files[0].name;
    } else {
      this.selectedFileName = null;
    }
  }

  navigateToProduct() {
    this.router.navigate(['/products/physical/product-list']);
  }

  // open model
  showModal(): void {
    this.isVisible = true;
  }

  // close model
  handleCancel(): void {
    this.isVisible = false;
  }
  onActivate(e) {

  };

  getAmazonProduct(e) {
    var id = this.activeroute.snapshot.url[2].path;
    if (id == "0") {
      this.showPopup = true;
      this.loading = true;
      this.popupText = 'Searching on Amazon...';
      const payload = {
        "token": this.token
      }

      this.apiService.getAmazonScrapingProduct(e,payload).subscribe(
        (product: ProductModel) => {
          this.popupText = ''; // Clear the text
          this.loading = false;
          this.showPopup = false;
          this.productModel = product;
          this.productModel.SKU = e;
          this.productModel.number_of_items = "1"
          this.productModel.Manufacturer = this.Manufacturer;
          this.selectChange();
        },
        (error) => {
          this.loading = false;
          this.showPopup = false;
          this.subcategorybox = false;
          this.productModel = new ProductModel();
          this.productModel.Manufacturer = this.Manufacturer;
          this.productModel.SKU = e;
          // console.error('An error occurred:', error);
        }
      );
    }
  }

  closePopup() {
    this.showPopup = false;
  }

}
