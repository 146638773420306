<div class="payment-plan-wrap">
    <div class="container">
        <div class="payment-row">
            <div (click)="redirecttoplanpage()">
                <img src="../../../assets/images/vuesax-outline-arrow-left.png" alt="" />
            </div>
            <div class="payment-heading">
                <h3>Enter your payment details</h3>
            </div>
        </div>


        <div class="payment-wrap-row">
            <div class="col-md-5">
                <h3>Billing information</h3>
                <div class="billing-box">
                    <div class="billing-content">
                        <div class="name-section">
                            <h3>First name*</h3>
                            <input type="text" class="payment-input-ph" [(ngModel)]="userdata.firstname" />
                        </div>
                        <div class="name-section">
                            <h3>Last name*</h3>
                            <input type="text" class="payment-input-ph" [(ngModel)]="userdata.lastname" />
                        </div>
                        <div class="name-section">
                            <h3>Email</h3>
                            <input type="text" class="payment-input-ph" [(ngModel)]="userdata.email" />
                        </div>
                        <div class="name-section">
                            <h3>Phone number</h3>
                            <input type="text" class="payment-input-ph" [(ngModel)]="userdata.phone" />
                        </div>
                        <div class="name-section">
                            <h3>Address</h3>
                            <input type="text" class="payment-input-ph" [(ngModel)]="userpaymentaddress.Address" />
                        </div>
                        <div class="payment-row">
                            <div class="name-section">
                                <h3>Country</h3>
                                <input type="text" class="payment-input" [(ngModel)]="userpaymentaddress.Country" />
                            </div>
                            <div class="name-section">
                                <h3>State</h3>
                                <input type="text" class="payment-input" [(ngModel)]="userpaymentaddress.State" />
                            </div>
                        </div>
                        <div class="payment-row">
                            <div class="name-section">
                                <h3>City</h3>
                                <input type="text" class="payment-input" [(ngModel)]="userpaymentaddress.Town" />
                            </div>
                            <div class="name-section">
                                <h3>Pin</h3>
                                <input type="text" class="payment-input" [(ngModel)]="userpaymentaddress.Pincode" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <h3>Payment information</h3>
                <div class="payment-jesssu-box ">
                    <div class="buying-row">
                        <h5>You're buying</h5>
                        <img src="../../../assets/images/jesssu-seller-for-payment.svg" alt="" />
                    </div>
                    <div class="payment-plan-row">
                        <h5>{{planname}} Plan</h5>
                        <div class="plan-price-section-row">
                            <h5>${{planprice}}</h5>
                            <p>/ month</p>
                        </div>
                    </div>
                    <div class="payment-method-row">
                        <h5>Total </h5>
                        <div class="plan-method-section-row">
                            <h5>${{planprice}}</h5>
                            <p>/ month</p>
                        </div>
                    </div>
                </div>
                <h3>Payment method</h3>
                <div class="payment-option-box">
                    <div class="payment-type">
                        <form action="#">
                            <p>
                                <input type="radio" id="test1" name="radio-group" checked>
                                <label for="test1"> <img src="../../../assets/images/paypal.svg" alt="" /></label>
                            </p>
                            <div class="alert" *ngIf="error">
                        <span class="closebtn" (click)="close()">&times;</span>
                        <strong>Error!</strong> {{errortext}}
                    </div>
                    <div class="response" *ngIf="success">
                        <span class="closebtn" (click)="close()">&times;</span>
                        <strong>Success!</strong> {{successtext}}
                    </div>
                        </form>
                    </div>
                    <div class="plan-feed-action">
                        <button type="button" class="credit-action-link" id="rzp-button1" (click)="createRzpayOrder('data')">Make Payment</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

