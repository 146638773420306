<div class="jesssu-seller-banner-wrap">
    <div class="jesssu-seller-img">
        <img src="../../../../assets/images/Rectangle 1493.svg" />
    </div>
    <div class="jesssu-seller-contant">
        <div class="jesssu-header">
            <div class="jesssu-header-row">
                <div>
                </div>
                <div class="rightside-profile">

                </div>

            </div>
        </div>
        <div class="jesssu-heading">
            <div class="heading-row">
            </div>

        </div>

        <div class="card-body tab2-card">

            <div class="catagory-applications-box">
                <form [formGroup]="orderform"
                    (ngSubmit)="dataSubmit(orderform)">
                    <div class="applications-table-row">
                        <div class="activate-catagory">
                            <h3>{{searchtext}}</h3>
                        </div>
                        <div class="product-form-group">
                            <input type="text" id="myInput"
                                (keyup.enter)="myFunction()"
                                class="product-form-control"
                                placeholder="Search by product details..">
                        </div>
                        <div class="row">
                            <div class="col-md-5">
                                <div class="form-group">
                                    <select formControlName="channel"
                                        class="product-form-control"
                                        (change)="getuserselectionforproductlist(orderform)">
                                        <option [ngValue]="null" copy>--Select--
                                        </option>
                                        <option *ngFor="let channel of channels"
                                            [ngValue]="channel.id">{{channel.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4"
                                *ngIf="!productlistselectiontype">
                                <div class="form-group">
                                    <select formControlName="status"
                                        class="product-form-control2">
                                        <option [ngValue]="null" copy>--Select--
                                        </option>
                                        <option
                                            *ngFor="let channel of productstatus"
                                            [ngValue]="channel.id">
                                            {{channel.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4"
                                *ngIf="productlistselectiontype">
                                <div class="form-group" *ngIf="supplierbox">
                                    <select formControlName="supplier"
                                        class="product-form-control2">
                                        <option [ngValue]="null" copy>--Select--
                                        </option>
                                        <option
                                            *ngFor="let channel of supplier_list"
                                            [ngValue]="channel._id">
                                            {{channel.supplier_name}}
                                        </option>

                                    </select>
                                </div>
                                <div class="form-group" *ngIf="!supplierbox">
                                    <select formControlName="box"
                                        class="product-form-control2">
                                        <option [ngValue]="0" copy>--Select--
                                        </option>
                                        <option *ngFor="let i of box_list"
                                            [ngValue]="i._id">
                                            Box No: {{i._id}}
                                        </option>

                                    </select>
                                </div>
                            </div>

                            <div class="col-md-2">
                                <div class="product-feed-action">
                                    <button type="button"
                                        class="btn-action-link" value="Submit"
                                        (click)="dataSubmit(orderform)">Submit</button>
                                </div>
                            </div>

                        </div>

                        <div class="product-Parent-sku">
                            <!-- <h4>Total Parent SKU :- {{parentskucount}} </h4> -->
                            <h4> Total Product :- {{totalcount}}</h4>
                            <!-- <h4 *ngIf="status==null">
                                Active Product Count :- {{activetotalcount}}</h4>
                            <h4 *ngIf="status==null"> Inactive Product Count :- {{inactivetotalcount}}</h4> -->
                        </div>
                        <div class="product-feed-action1">
                            <button type="button" class="btn-action-link1"
                                value="Submit" (click)="reset()">Reset
                                Filter</button>
                        </div>
                    </div>
                </form>
            </div>
            <div *ngIf="productexportoption">
                <div class="select-product-applications-box"
                    *ngIf="buttonforexport" style="height:140px">
                    <div class="select-product-row">
                        <h4>Selected Product Count = {{selectproductcount}}</h4>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <button (click)="exportforflipkart()"
                                class="btn-action-link" id="flipkart"> Export
                                for Flipkart</button>
                        </div>
                        <div class="col-md-3">
                            <button (click)="exportforamazon()"
                                class="btn-action-link" id="amazon"> Export
                                for Amazon</button>
                        </div>
                        <div class="col-md-3">
                            <button (click)="exportformeesho()"
                                class="btn-action-link" id="meesho"> Export
                                for Meesho</button>
                        </div>
                        <div class="col-md-3">
                            <button (click)="exportforshopify()"
                                class="btn-action-link" id="shopify"> Export
                                for Shopify</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="applications-box7">
                <div class="fixTableHead">
                    <table class="table table-striped custom-table">

                        <thead>
                            <tr>
                                <th scope="col">PRODUCT SKU</th>
                                <th scope="col">PRODUCT ID</th>
                                <th scope="col">PURCHASE/SELL PRICE</th>
                                <th scope="col">STATUS</th>
                                <th scope="col">OFFER</th>
                                <th scope="col">BOX</th>
                                <th scope="col">PRODUCT Image </th>
                            </tr>
                        </thead>

                        <tbody *ngFor="let product of newviewproductlist"
                            infiniteScroll [infiniteScrollDistance]="1"
                            [infiniteScrollThrottle]="1000"
                            (scrolled)="onScroll1()">
                            <tr *ngIf="product.data[0].SKU!=undefined">
                                <td>
                                    <label class="container1">
                                        <input type="checkbox"
                                            (change)="onSelect($event, product.data[0]._id)">
                                        <span class="checkmark1"></span>
                                    </label>
                                    {{product.data[0].SKU}} <br>
                                    <span
                                        *ngIf="product.data[0]?.variations != null && product.data[0]?.variations?.length > 0">*{{product.data[0].variations.length}}
                                        Variant is available <br>
                                        under the SKU</span>
                                </td>
                                <td>
                                    {{product.data[0].SKU}}<br>
                                    Category: {{product.data[0].Primary}}<br>
                                    Subcategory: {{product.data[0].Secondary}}
                                </td>
                                <td>
                                    {{product.data[0].SalePrice}}/{{product.data[0].RetailPrice}}<br>
                                    Gender: {{product.data[0].Gender}}

                                </td>
                                <td>
                                    <h4
                                        class="no-offer">{{product.data[0].status==false?"Inactive":"Active"}}</h4>
                                    <button type="button" class=btn-action-link2
                                        (click)="changeStatus(product.data[0])">{{product.data[0].status==false?"Activate":"Deactivate"}}
                                    </button>
                                </td>
                                <td>
                                    <h4 class="no-offer">No Offer</h4>
                                    <!-- <button type="button" class=btn-action-link2>Offers</button> -->
                                </td>
                                <td>
                                    <h4 class="no-offer"
                                        *ngIf="product.data[0].positionplace!=undefined">{{product.data[0].positionplace}}</h4>
                                    <!-- <button type="button" class=btn-action-link2>Offers</button> -->
                                </td>
                                <td>

                                    <div class="edit-row">
                                        <div class="product-list-img1"
                                            *ngIf="product.data[0].MainImage!=undefined">
                                            <img
                                                src={{product.data[0].MainImage}}
                                                alt />
                                        </div>
                                        <div class="product-list-img1"
                                            *ngIf="product.data[0].MainImage==undefined">
                                            <img
                                                src={{product.data[0].ProductImage}}
                                                alt />
                                        </div>
                                        <div class="product-list-img1"
                                            *ngIf="product.data[0].ProductImage==undefined">
                                            <img src alt />
                                        </div>
                                        <div class="edit">
                                            <div
                                                (click)="onEdit(product.data[0]._id)">
                                                <img
                                                    src="../../../../../assets/images/Group 211.svg"
                                                    alt />
                                            </div>
                                            <div
                                                (click)="onCopy(product.data[0]._id)">
                                                <img
                                                    src="../../../../../assets/images/Group 209.svg"
                                                    alt />
                                            </div>
                                            <div
                                                (click)="onDelete(product.data[0]._id)">
                                                <img
                                                    src="../../../../../assets/images/Group 210.svg"
                                                    alt />
                                            </div>
                                            <div
                                                (click)="onAddVariatation(product.data[0]._id)">
                                                <img
                                                    src="../../../../../assets/images/variation (1).svg"
                                                    alt />
                                            </div>

                                        </div>
                                        <div *ngIf="shopifyactivation">
                                            <button type="button"
                                                class=btn-action-link2
                                                (click)="addProducttoShopify(product.data[0])">{{product.data[0].Shopifyproductid==""||product.data[0].Shopifyproductid==undefined?"Add
                                                to
                                                shopify":"Remove from shopify"}}</button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div>
</div>

<!-- addProducttoShopify -->
<div *ngIf="popupshow" class="loginbox">
    <div class="paste-source">
        <div class="paste-source-box">
            <h4>Are you sure to delete the product</h4>
            <div class="save-btn">
                <div class="featured-action">
                    <button type="button" class="save-upload-cli-primary-btn"
                        (click)="onDeleteconfirm()">
                        Ok
                    </button>
                </div>

                <div class="featured-action">
                    <button type="button" (click)="onDeletecancel()"
                        class="save-upload-cli-primary-btn" id="ok">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="deletesuccess" class="loginbox">
    <div class="paste-source">
        <div class="paste-source-box">
            <h4>Product has been deleted successfully</h4>
            <div class="save-btn">
                <div class="featured-action">
                    <button type="button" class="save-upload-cli-primary-btn"
                        (click)="Deletesuccess()">
                        Ok
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- edit-modal -->

<div *ngIf="editpopup" class="loginbox">
    <div class="paste-source">
        <div class="paste-source-box">
            <h4>Are you want to edit the product details</h4>
            <div class="save-btn">
                <div class="featured-action">
                    <button type="button" class="save-upload-cli-primary-btn"
                        (click)="onEditconfirm()">
                        Ok
                    </button>
                </div>
                <div class="featured-action">
                    <button type="button" (click)="onEditcancel()"
                        class="save-upload-cli-primary-btn" id="ok">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- copy-modal -->

<div *ngIf="copypopup" class="loginbox">
    <div class="paste-source">
        <div class="paste-source-box">
            <h4>Are you want to copy this product details for a new listing</h4>
            <div class="save-btn">
                <div class="featured-action">
                    <button type="button" class="save-upload-cli-primary-btn"
                        (click)="onCopyconfirm()">
                        Ok
                    </button>
                </div>
                <div class="featured-action">
                    <button type="button" (click)="onCopycancel()"
                        class="save-upload-cli-primary-btn" id="ok">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- variation-modal -->

<div *ngIf="variationpopup" class="loginbox">
    <div class="paste-source">
        <div class="paste-source-box">
            <h4>Are you want to add variation the product details</h4>
            <div class="save-btn">
                <div class="featured-action">
                    <button type="button" class="save-upload-cli-primary-btn"
                        (click)="onVariationconfirm()">
                        Ok
                    </button>
                </div>
                <div class="featured-action">
                    <button type="button" (click)="onVariationcancel()"
                        class="save-upload-cli-primary-btn" id="ok">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- add-shopify-modal -->

<div *ngIf="shopifypopupforadd" class="loginbox">
    <div class="paste-source">
        <div class="paste-source-box">
            <h4>Are you sure to add this product to shopify</h4>
            <div class="save-btn">
                <div class="featured-action">
                    <button type="button" class="save-upload-cli-primary-btn"
                        (click)="onAddshopifyconfirm()">
                        Ok
                    </button>
                </div>
                <div class="featured-action">
                    <button type="button" (click)="onAddshopifycancel()"
                        class="save-upload-cli-primary-btn" id="ok">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="shopifypopupforremove" class="loginbox">
    <div class="paste-source">
        <div class="paste-source-box">
            <h4>Are you sure to remove this product from shopify</h4>
            <div class="save-btn">
                <div class="featured-action">
                    <button type="button" class="save-upload-cli-primary-btn"
                        (click)="onRemoveshopifyconfirm()">
                        Ok
                    </button>
                </div>
                <div class="featured-action">
                    <button type="button" (click)="onRemoveshopifycancel()"
                        class="save-upload-cli-primary-btn" id="ok">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- active product  -->
<div *ngIf="activepopupshow" class="loginbox">
    <div class="paste-source">
        <div class="paste-source-box">
            <h4>Are you sure to active the product?</h4>
            <h6>Please fill the details below</h6>
            <div class="form-details">
                <label><b>Sale Order Id:</b></label>
                <input type="text" class="input-details textarea-bg" required
                    [(ngModel)]="newproductactivestatus.sale_order_id" />
                <label><b>Relevent Details:</b></label>
                <textarea class="input-details textarea-bg" form="usrform"
                    required
                    [(ngModel)]="newproductactivestatus.relevent_details"
                    (change)="settozero()">


                </textarea>
            </div>
            <h6 style="color: red">{{activeinactiveerrortext}}</h6>
            <div class="save-btn">
                <div class="featured-action">
                    <button type="button" class="save-upload-cli-primary-btn"
                        (click)="onactiveconfirm()">
                        Ok
                    </button>
                </div>

                <div class="featured-action">
                    <button type="button" (click)="onactivecancel()"
                        class="save-upload-cli-primary-btn" id="ok">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="deactivepopupshow" class="loginbox">
    <div class="paste-source">
        <div class="paste-source-box">
            <h4>Are you sure to deactive the product?</h4>
            <h6>Please fill the details below</h6>
            <div class="form-details">
                <label><b>Sale Order Id:</b></label>
                <input type="text" class="input-details" required
                    [(ngModel)]="newproductactivestatus.sale_order_id" />
                <label><b>Relevent Details:</b></label>
                <textarea class="input-details" form="usrform" required
                    [(ngModel)]="newproductactivestatus.relevent_details"
                    (change)="settozero()">


                </textarea>
            </div>
            <h6 style="color: red">{{activeinactiveerrortext}}</h6>
            <div class="save-btn">
                <div class="featured-action">
                    <button type="button" class="save-upload-cli-primary-btn"
                        (click)="onactiveconfirm()">
                        Ok
                    </button>
                </div>

                <div class="featured-action">
                    <button type="button" (click)="ondeactivecancel()"
                        class="save-upload-cli-primary-btn" id="ok">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="Loginmessage" class="loginbox" style="display: none;">
    <div class="paste-source">
        <div class="paste-source-box">
            <div id="note-alert" class="save-btn">
                <h4 id="User-status" style="display: none;">Please unselect
                    channel and select all for getting result
                </h4>

                <h4 id="addedtoshopify" style="display: none;">product has been
                    added to Shopify</h4>
                <h4 id="removeshopify" style="display: none;">product has been
                    removed from Shopify</h4>
                <h4 id="product-delete" style="display: none;">Product has been
                    deleted successfully</h4>
                <h4 id style="display: none;">User status changed
                    successfully</h4>
                <h4 id style="display: none;">User status changed
                    successfully</h4>
            </div>
            <div class="save-btn">
                <div class="featured-action">
                    <button type="button" class="save-upload-cli-primary-btn"
                        id="ok">
                        Ok
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>