import { Component, OnInit } from '@angular/core';
import { listCouponsDB } from 'src/app/shared/tables/list-coupon';
import { ApiService } from '../../shared/service/api.service';
// import { map } from "rxjs/operators/map";
import { ProductModel, SubscriptionModel, UserModel } from "../../shared/models/datamodel";
import { Router } from '@angular/router';
import { NotificationModel } from '../../shared/models/datamodel';
import { ProductimageService } from '../../shared/service/fileupload.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-user-seller',
  templateUrl: './user-seller.component.html',
  styleUrls: ['./user-seller.component.scss']
})

export class UserSellerComponent implements OnInit {
  [x: string]: any;
  selectedFiles: FileList;
  currentFileUpload: File;
  FileUploadStatus: Boolean;
  public id: String;
  public sellers: UserModel[];
  public jesssuusers: UserModel[];
  selectedFile: File = null;
  public alltokens = []
  public coupon = null;
  public jessuplatformusers = [];
  public notificationModel = new NotificationModel()
  public selected = [];
  public ID: String;
  public useradmin: any;
  checked: boolean;
  public sellerdata: any;
  public admindata: any;
  public userdata: any;
  status: any;
  public target_user: string;
  public messsage: string;
  public notification_title: string;
  public notification_image: string;
  public pageview = true;

  public errortext = ""
  public successtext = ""
  public error = false
  public success = false

  constructor(private apiService: ApiService, public router: Router, public productimageservice: ProductimageService) {
    //localStorage.removeItem("seller-info");
    this.getAllPlatformUsers();
    this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"));
    this.admindata = JSON.parse(window.localStorage.getItem("admin-info"));
    this.token = JSON.parse(window.localStorage.getItem("TOKEN"))
    if (this.admindata == null) {
      this.router.navigate(['/page-not-found'])
    }
  }

  isAllCheckBoxChecked() {
    return this.selected.every(p => p.checked);
  }
  onSelect(event, selecteduser) {

    var checkboxes = document.getElementsByTagName('input');
    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].type == 'checkbox') {
        if (checkboxes[i].id != event.currentTarget.id) {
          checkboxes[i].checked = false;
        }
      }
    }
    // this.selected = [];
    // this.selected.push(selecteduser);
    // localStorage.removeItem("seller-info");
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    // localStorage.setItem("seller-info", JSON.stringify(this.selected));

    // const payload = {
    //   userid: selecteduser._id,
    //   token: this.admindata[0].token,
    //   manufacturer: selecteduser.manufacturer
    // }
    // this.apiService.getuserSubscriptiondetails(payload).subscribe((res: SubscriptionModel[]) => {
    //   if (res.length > 1) {
    //     this.subscriptiondata = res[res.length - 1]
    //     let subscriptiondate = new Date(this.subscriptiondata.createdAt)
    //     let subscriptionplan = this.subscriptiondata.subscriptiontype
    //     let planenddate = new Date(new Date().setDate(subscriptiondate.getDate() + 30));
    //     if (planenddate > new Date()) {
    //       localStorage.setItem("SUBSCRIPTION", JSON.stringify(true))
    //       localStorage.setItem("SUBSCRIPTIONPLAN", JSON.stringify(subscriptionplan))
    //       this.successtext = "Subscription available"
    //       this.subscriptionstatus = true
    //       this.error = false
    //       this.success = true
    //     }
    //     else {
    //       alert("user subscription not available")
    //     }
    //   }
    //   else if (res.length == 1) {
    //     this.subscriptiondata = res[0]
    //     let subscriptionplan = this.subscriptiondata.subscriptiontype
    //     let subscriptiondate = new Date(this.subscriptiondata.createdAt)
    //     let planenddate = new Date(new Date().setDate(subscriptiondate.getDate() + 30));
    //     if (planenddate > new Date()) {
    //       localStorage.setItem("SUBSCRIPTION", JSON.stringify(true))
    //       localStorage.setItem("SUBSCRIPTIONPLAN", JSON.stringify(subscriptionplan))
    //       this.successtext = "Subscription available"
    //       this.error = false
    //       this.success = true
    //       this.subscriptionstatus = true
    //     } else {
    //       alert("user subscription not available")
    //     }
    //   }
    //   else {

    //     alert("user subscription not available")

    //   }
    // })
    const payload = {
      userid: selecteduser.email,
      token: this.token

    }
    const userid = selecteduser._id
    this.apiService.getuserdetails(userid, payload).subscribe((res: UserModel) => {
      localStorage.setItem("USERDETAILS", JSON.stringify(res));
      this.selected = [];
      this.selected.push(res)
      localStorage.setItem("seller-info", JSON.stringify(this.selected));

      if (res) {
        this.userdetails = res
        this.selected = [];
        this.selected.push(res)
        localStorage.setItem("seller-info", JSON.stringify(this.selected));
        let role = this.userdetails.role;
        let accountstatus = this.userdetails.accountstatus
        localStorage.setItem("ACCOUNTSTATUS", JSON.stringify(accountstatus));
        if (this.userdetails.manufacturer == null) {
          this.errortext = "Seller still not updated the Manufacturer field. Please ask seller to update Manufacturer details or select another user"
          this.error = true
          // alert("Seller still not updated the Manufacturer field. Please ask seller to update Manufacturer details or select another user")
        } else {
          const payload = {
            userid: this.userdetails._id,
            token: this.token,
            manufacturer: this.userdetails.manufacturer
          }
          this.successtext = "Checking subscription details..."
          this.error = false
          this.success = true
          this.apiService.getuserSubscriptiondetails(payload).subscribe((res: SubscriptionModel[]) => {
            if (res.length > 1) {
              this.subscriptiondata = res[0]
              let subscriptiondate = new Date(this.subscriptiondata.startDate)
              let subscriptionplan = this.subscriptiondata.plan
              let planenddate = new Date(this.subscriptiondata.endDate);
              if (planenddate > new Date()) {
                localStorage.setItem("SUBSCRIPTION", JSON.stringify(true))
                localStorage.setItem("SUBSCRIPTIONPLAN", JSON.stringify(subscriptionplan))
                this.successtext = "Subscription available"
                this.subscriptionstatus = true
                this.error = false
                this.success = true
              } else {
                this.errortext = "Subscription ended"
                this.subscriptionstatus = false
                this.error = true
                this.success = false
                localStorage.setItem("SUBSCRIPTION", JSON.stringify(false))
              }
            }
            else if (res.length == 1) {
              this.subscriptiondata = res[0]
              let subscriptionplan = this.subscriptiondata.plan
              let subscriptiondate = new Date(this.subscriptiondata.startDate)
              let planenddate = new Date(this.subscriptiondata.endDate);
              if (planenddate > new Date()) {
                localStorage.setItem("SUBSCRIPTION", JSON.stringify(true))
                localStorage.setItem("SUBSCRIPTIONPLAN", JSON.stringify(subscriptionplan))
                this.successtext = "Subscription available"
                this.error = false
                this.success = true
                this.subscriptionstatus = true
              } else {
                this.errortext = "Subscription ended"
                this.error = true
                this.success = false
                this.subscriptionstatus = false
                localStorage.setItem("SUBSCRIPTION", JSON.stringify(false))
              }
            }
            else {
              this.errortext = "Subscription not available"
              this.error = true
              this.success = false
              localStorage.setItem("SUBSCRIPTION", JSON.stringify(false))
            }
            let manufacturer = this.userdetails.manufacturer

            if (manufacturer == null || manufacturer == undefined || manufacturer == "") {
              if (this.userdetails.gst_number == null || this.userdetails.gst_number == undefined || this.userdetails.gst_number == "") {
                localStorage.setItem("GST", JSON.stringify(false))
                localStorage.setItem("MANUFACTURER", JSON.stringify(false))
                this.router.navigateByUrl("/auth/gstupload")
              }
              else {
                localStorage.setItem("GST", JSON.stringify(true))
                localStorage.setItem("MANUFACTURER", JSON.stringify(false))
                this.router.navigateByUrl("/auth/gstupload")
              }
            }
            else if (this.userdetails.gst_number == null || this.userdetails.gst_number == undefined || this.userdetails.gst_number == "") {
              localStorage.setItem("GST", JSON.stringify(false))
              this.router.navigateByUrl("/auth/gstupload")
              localStorage.setItem("MANUFACTURER", JSON.stringify(true))
              this.router.navigateByUrl("/auth/gstupload")
            }
            else {
              localStorage.setItem("GST", JSON.stringify(true))
              localStorage.setItem("MANUFACTURER", JSON.stringify(true))
              if (this.subscriptionstatus == true) {
                // let currentUrl = this.router.url;
                // this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                //     this.router.navigate([currentUrl]);
                // });
                this.router.navigate(["/auth/gstupload"])
              }
              else {
                this.router.navigateByUrl("/subscription-selection")
              }
            }
          })
        }
      }
    })
    // this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"));
  }

  onSelect1(event, selecteduser) {
    var checkboxes = document.getElementsByTagName('input');
    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].type == 'checkbox') {
        if (checkboxes[i].id != event.currentTarget.id) {
          checkboxes[i].checked = false;
        }
      }
    }
    this.selected = [];
    this.selected.push(selecteduser);
    localStorage.removeItem("user-info");
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    localStorage.setItem("user-info", JSON.stringify(this.selected));
    this.userdata = JSON.parse(window.localStorage.getItem("user-info"));
  }

  ngOnInit() {
    this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"));
    this.admindata = JSON.parse(window.localStorage.getItem("admin-info"));
    this.userdata = JSON.parse(window.localStorage.getItem("user-info"));
    // this.id = this.sellerdata[0].role;


    if (this.admindata == null) {
      if (this.sellerdata[0].role == "basic") {
        alert("This option is not available for sellers. Please contact with admin.");
        this.router.navigate(['/dashboard/default'])
      }
    }

  }
  removeselectedseller() {
    localStorage.removeItem("seller-info");
    localStorage.removeItem("user-info");

    // this.router.navigate(['/dashboard/default'])

  }

  getAllPlatformUsers() {
    this.admindata = JSON.parse(window.localStorage.getItem("admin-info"));
    this.apiService.getUsers(this.admindata[0].token)
      .subscribe((sellers: any[]) => this.sellers = sellers);

    this.apiService.getjessuplatformUsers(this.admindata[0].token)
      .subscribe((jesssuusers: any[]) => this.jessuplatformusers = jesssuusers);



  }

  getUsers() {
    this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"));
    this.admindata = JSON.parse(window.localStorage.getItem("admin-info"));
    if (this.admindata != null) {
      const payloadforgetUsers = {
        "userid": this.admindata[0]._id,
        "token": this.admindata[0].token,
      }
      this.apiService.getadminUsers(payloadforgetUsers)
        .subscribe((res: any[]) => {
          this.useradmin = res;
        });
    }
  }

  onDelete($selected) {
    console.log(this.selected);
  }
  Onclickcheckactivitydetails(id) {

    localStorage.removeItem("url");

    this.ID = id;
    // this.ID.push(id);
    localStorage.setItem("url", JSON.stringify(this.ID));
    this.router.navigate(['/users/user-activity']);
  }

  Onclickcheckfriends(id) {
    localStorage.removeItem("url");

    this.ID = id;
    // this.ID.push(id);
    localStorage.setItem("url", JSON.stringify(this.ID));
    this.router.navigate(['/friends']);
  }

  Onclickcheckscreendetails(id) {
    localStorage.removeItem("url");

    this.ID = id;
    localStorage.setItem("url", JSON.stringify(this.ID));
    this.router.navigate(['/users/onboarding-screen']);
  }

  // Onclick3(id) {
  //   localStorage.removeItem("url");
  //   this.ID = id;
  //   localStorage.setItem("url", JSON.stringify(this.ID));
  //   this.router.navigate(['/media/activity/update']);
  // }

  changeStatus(coupon) {

    this.selected = [];
    this.selected.push(coupon);
    
    console.log(this.selected);

    if (this.selected[0].IsActive == false) {
      this.id = this.selected[0]._id;
      this.selected[0].IsActive = true;
      this.selected[0].token = this.token;
      this.apiService.updateuser(this.id, this.selected[0])
        .subscribe((res) => {
          this.success = true;
          this.successtext = "User status changed successfully"
          // alert("User status changed successfully");
          localStorage.removeItem("seller-info");

        })

    }
    else {
      this.id = this.selected[0]._id;
      this.selected[0].IsActive = false;
      this.selected[0].token = this.token;
      this.apiService.updateuser(this.id, this.selected[0])
        .subscribe((res) => {
          this.success = true;
          this.successtext = "User status changed successfully"
          // alert("User status changed Successfully");
          localStorage.removeItem("seller-info");

        })

    }

  }
  onSelectedFile(event) {
    this.selectedFile = <File>event.target.files[0]
  }

  upload(type) {
    this.currentFileUpload = this.selectedFiles.item(0);

    // this.FileUploadStatus = false;
    this.productimageservice.pushFileToStorage(this.currentFileUpload).subscribe(res => {
      this.notificationModel.notification_image = res.toString();

      if (type == 'main') {
        this.notificationModel.notification_image = res.toString();
      }
    });
  }

  selectFile(event, type) {
    this.selectedFiles = event.target.files;
    this.upload(type);
  }

  onSubmit(form: NgForm) {
    if (this.admindata != null || this.admindata != undefined) {
      this.notificationModel.token = this.admindata.token
    }
    else {
      this.notificationModel.token = this.sellerdata.token
    }

    if (this.notificationModel.target_user == "Seller") {
      this.apiService.getsellertoken()
        .subscribe((res: any[]) => {
          this.alltokens = res;
          this.notificationModel.tokens = this.alltokens
        })
    }
    else {
      this.apiService.getusertoken()
        .subscribe((res: any[]) => {
          this.alltokens = res;
          this.notificationModel.tokens = this.alltokens
        })
    }

    // console.log(this.notificationModel);
    if (this.notificationModel.target_user == null) {
      this.error = true;
      this.errortext = "Please Select the User"

      // alert("Please Select the User")
    }
    else if (this.notificationModel.message == null) {
      this.error = true;
      this.errortext = "Please Input a message"
      // alert("Please Input a message")
    }
    else if (this.notificationModel.notification_title == null) {
      this.error = true;
      this.errortext = "Please select a title"
      // alert("Please select a title")
    }
    else if (this.notificationModel.notification_image == null) {
      this.error = true;
      this.errortext = "Please selet an image"
      // alert("Please selet an image")
    }
    else if (this.notificationModel.target_user == "Seller") {

      this.apiService.sendnotification(this.notificationModel)
        .subscribe(re => {
          this.success = true;
          this.successtext = "notification send successfully."
          // alert("notification send successfully.")
        },
          error => {
            alert(this.notificationModel)
          })
    }
    else {
      this.apiService.sendnotificationuser(this.notificationModel)
        .subscribe(re => {
          this.success = true;
          this.successtext = "notification send successfully."
          // alert("notification send successfully.")
        },
          error => {
            alert(this.notificationModel)
          })
    }
  }

  searchseller() {
    this.admindata = JSON.parse(window.localStorage.getItem("admin-info"));
    var input, filter, table, tr, td, i, txtValue;
    input = document.getElementById("myInputforseller");
    if (input.value.length > 1) {
      const payload = {
        search: input.value,
        token: this.admindata[0].token
      }
      this.apiService.searchseller(payload)
        .subscribe((sellers: any[]) => this.sellers = sellers);
    } else {
      this.apiService.getUsers(this.admindata[0].token)
        .subscribe((sellers: any[]) => this.sellers = sellers);
    }
  }

  searchuser() {
    this.admindata = JSON.parse(window.localStorage.getItem("admin-info"));
    var input, filter, table, tr, td, i, txtValue;
    input = document.getElementById("myInputforuser");
    if (input.value.length > 1) {
      const payload = {
        search: input.value,
        token: this.admindata[0].token
      }
      this.apiService.searchuser(payload)
        .subscribe((jesssuusers: any[]) => this.jessuplatformusers = jesssuusers);
    } else {
      this.apiService.getjessuplatformUsers(this.admindata[0].token)
        .subscribe((jesssuusers: any[]) => this.jessuplatformusers = jesssuusers);
    }
  }
}
