import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../../../shared/service/api.service';
import { ProductimageService } from '../../../../../shared/service/fileupload.service';


@Component({
  selector: 'app-sub-category-edit',
  templateUrl: './sub-category-edit.component.html',
  styleUrls: ['./sub-category-edit.component.scss']
})
export class SubCategoryEditComponent implements OnInit {
  [x: string]: any;
  public Categoryname: string;
  public subcategory: any;

  selectedFiles: FileList;
  currentFileUpload: File;
  FileUploadStatus: Boolean;

  constructor(private apiService: ApiService, private router: Router, private productimageService: ProductimageService) { }

  ngOnInit(): void {
    this.getcategorydetails()
  }

  getcategorydetails() {
    this.Categoryname = JSON.parse(window.localStorage.getItem("categoryname"));
    console.log(this.Categoryname);
    var payload = {
      "categoryname": this.Categoryname,
    }
    this.apiService.getcategorydeatils(payload)
      .subscribe((res) => {
        this.subcategory = res;
      })
  }

  upload(type) {
    
    this.FileUploadStatus = true;
    this.currentFileUpload = this.selectedFiles.item(0);
    this.productimageService.pushFileToStorage4(this.currentFileUpload).subscribe(res => {
      this.FileUploadStatus = false;
      if (type == 'main') {
        this.user1.subcategoryimage = res.toString();
      }
    });
  }

  selectFile(event, type) {
    this.selectedFiles = event.target.files;
    this.upload(type);
  }

  onSubmit(data) {
    if (this.FileUploadStatus == true) {
      alert("File is uploading. Please wait")
    }
    else {
      console.log(data);
      const payloadforimageupdate = {
        "id": data._id,
        //"imageurl": this.user1.subcategoryimage,
        "subcategories": []
      }
      console.log(payloadforimageupdate)
      this.productimageService.updatesubcategoryimage(payloadforimageupdate)
        .subscribe(re => {
          alert("Sub-Category has been Updated successfully.")
          this.router.navigate(['/products/category'])
        },
        )
    }
  }

}
