import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from '../../shared/service/api.service';
import { ProductimageService } from '../../shared/service/fileupload.service';
import { UserModel, Accounts, AddressModel } from 'src/app/shared/models/datamodel';
import { WindowRefService } from '../../shared/service/window-ref.service';
// import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  providers: [WindowRefService]
})
export class PaymentComponent implements OnInit {
  sellerdata: any;
  admindata: any;
  public userdata = new UserModel();
  public planname: String;
  public planprice: number = 0;
  public userpaymentaddress = new AddressModel()
  finalplanpriceforcheckout: any;
  public orderid: any;
  public todaydate = new Date();
  public Manufacturer: any
  token: any;

  public errortext = ""
  public successtext = ""
  public error = false
  public success = false

  constructor(private apiService: ApiService, private router: Router, private winRef: WindowRefService, private imageuploadService: ProductimageService, public activeroute: ActivatedRoute) {
    this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"))
    this.token = JSON.parse(window.localStorage.getItem("TOKEN"))
    this.admindata = JSON.parse(window.localStorage.getItem("admin-info"))
    // this.Manufacturer = JSON.parse(window.localStorage.getItem("MANUFACTURER"))
    this.userdata = JSON.parse(window.localStorage.getItem("USERDETAILS"))
    this.token = JSON.parse(window.localStorage.getItem("TOKEN"))
    this.planname = this.activeroute.snapshot.url[1].path;
    if (this.planname == "Free") {
      this.planprice = 0
    }
    else if (this.planname == "Starter") {
      this.planprice = 0
    }
    else if (this.planname == "Professional") {
      this.planprice = 0
    }
    else if (this.planname == "Enterprise") {
      this.planprice = 0
    }
    this.orderid = this.todaydate + this.sellerdata[0].manufacturer
    if (this.userdata.manufacturer == undefined || this.userdata.manufacturer == null) {
      this.router.navigateByUrl("/auth/gstupload")
    }

  }

  ngOnInit(): void {

  }

  getuserdetails(id, token) {
    const payload = {
      token: this.userdata.token,
      email: this.userdata.email

    }
    const userid = id
    this.apiService.getuserdetails(userid, payload).subscribe((res: UserModel) => {
      this.userdata = res;
      localStorage.setItem("USERDETAILS", JSON.stringify(res));
    })
  }

  profileupdate() {
    
    const payload = {
      token: this.token,
      email: this.userdata.email

    }
    this.userdata.token = this.token;
    this.apiService.updateuser(this.userdata._id, this.userdata).subscribe((res) => {
      this.getuserdetails(this.userdata._id, payload)
    })
  }
  createRzpayOrder(data) {
    console.log(data);
    this.payWithRazor("Jesssu Order");
  }

  redirecttoplanpage() {
    this.router.navigateByUrl("/subscription-selection")
  }

  close() {
    this.error = false;
    this.success = false;
  }


  payWithRazor(val) {
    this.success=true;
      this.error = false;
      this.successtext="Payment is under process"
    if (this.planprice != 0) {
      const options: any = {
        key: 'rzp_live_8dhziW3GsUeBJ4',
        amount: this.planprice * 100, // amount should be in paise format to display Rs 1255 without decimal point
        currency: 'INR',
        name: 'Jesssu', // company name or product name
        description: val,  // product description
        modal: {
          escape: false,
        },
        notes: {
        },
        theme: {
          color: '#0c238a'
        }
      };
      options.handler = ((response, error) => {
        options.response = response;
        console.log(response);
        console.log(options);
        // call your backend api to verify payment signature & capture transaction
      });
      options.modal.ondismiss = (() => {
        // handle the case when user closes the form while transaction is in progress
        console.log('Transaction cancelled.');
      });
      const rzp = this.winRef.nativeWindow.Razorpay(options);
      rzp.open();
    }
    else {
      
      const paymentaddress = {
        Country: this.userpaymentaddress.Country,
        Address: this.userpaymentaddress.Address,
        Town: this.userpaymentaddress.Town,
        Pincode: this.userpaymentaddress.Pincode,
        State: this.userpaymentaddress.State
      }
      const payload = {
        userid: this.sellerdata[0]._id,
        token: this.sellerdata[0].token,
        paymentaddress: paymentaddress,
        email: this.sellerdata[0].email

      }

      this.apiService.updateuserpaymentaddress(payload).subscribe((res) => {
        this.getuserdetails(this.userdata._id, payload)
      })
      localStorage.removeItem("SUBSCRIPTION")
      localStorage.removeItem("SUBSCRIPTIONPLAN")
      const payloadforplan = {
        userid: this.sellerdata[0]._id,
        token: this.token,
        manufacturer: this.sellerdata[0].manufacturer,
        orderid: this.orderid,
        subscriptiontype: this.planname,
        paymentstatus: true,
        paymentid: ""
      }
      this.apiService.updateusersubscriptiondetails(payloadforplan).subscribe((res) => {
        this.success=true;
        this.error = false;
        this.successtext="Plan Activated! Redirecting to Homepage"
        
        localStorage.setItem("SUBSCRIPTION", JSON.stringify(true))
        localStorage.setItem("SUBSCRIPTIONPLAN", JSON.stringify(this.planname))
        const payload = {
          token: this.userdata.token,
          email: this.userdata.email
    
        }
        this.getuserdetails(this.userdata._id, payload)
        this.router.navigateByUrl("/dashboard/default")
      })
    }
  }

}


