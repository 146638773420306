import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-deleteaccount',
  templateUrl: './deleteaccount.component.html',
  styleUrls: ['./deleteaccount.component.scss']
})
export class DeleteaccountComponent implements OnInit {

  applestore: boolean = false;
  constructor(private router: Router) { }

  ngOnInit(): void {
  }


  Shownav = true;  
    
  toggglebtn() {  
    this.Shownav = !this.Shownav;  
  }  

}
