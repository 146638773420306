<div class="jesssu-seller-banner-wrap">
    <div class="jesssu-seller-img">
        <img src="../../../../assets/images/Rectangle 1493.svg" />
    </div>
    <div class="jesssu-seller-contant">
        <div class="jesssu-header">
            <div class="jesssu-header-row">
                <div>
                </div>
                <div class="rightside-profile">
                </div>
            </div>
        </div>
        <!-- <div class="jesssu-heading">
            <div class="heading-row">
                <div class="jesssu-heading-name">
                    <h4>Add Product</h4>
                </div>
                <div class="contant-row">
                    <img src="../../../assets/images/Home (2).svg" alt="" />
                    <h6>Home -Products-Add Variations</h6>
                </div>
            </div>
        </div> -->
 
 
        <div class="catagory-applications-box">
            <div class="applications-table-row">
                <div class="product-box">
                    <p>When multiple sellers sell the same product through a single page, we combine and present
                        the best product.</p>
                </div>
                <br>
                <div *ngFor="let data of productvariationdata; let indexOfelement=index;">
                    <div class="sub-catagory-applications-box">
                        <span class="close" (click)="removevariantionbox(indexOfelement)">x</span>
                        <div class="row">
                            <div class="col-md-3 text-right">
                                <label class="add-product-lable">SKU</label>
                            </div>
                            <div class="col-md-7">
                                <div class="form-group">
                                    <input type="text" class="product-form-control" [(ngModel)]="productvariationdata[indexOfelement].SKU"
                                        name="SKU" (blur)="test(indexOfelement)">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3 text-right">
                                <label class="add-product-lable">Item Name (Product Title)</label>
                            </div>
                            <div class="col-md-7">
                                <div class="form-group">
                                    <input type="text" class="product-form-control" [(ngModel)]="productvariationdata[indexOfelement].Name"
                                        name="item_name">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3 text-right">
                                <label class="add-product-lable">Maximum Retail Price</label>
                            </div>
                            <div class="col-md-7">
                                <div class="form-group">
                                    <input type="number" onwheel="this.blur()" class="product-form-control"
                                        [(ngModel)]="productvariationdata[indexOfelement].RetailPrice" name="standard_price">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3 text-right">
                                <label class="add-product-lable">Product Sell Price</label> 
                            </div>
                            <div class="col-md-7">
                                <div class="form-group">
                                    <input type="number" onwheel="this.blur()" class="product-form-control"
                                        [(ngModel)]="productvariationdata[indexOfelement].SalePrice" name="maximum_retail_price">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3 text-right">
                                <label class="add-product-lable">Colour</label>
                            </div>
                            <div class="col-md-7">
                                <div class="form-group">
                                    <input type="text" class="product-form-control" type="text"
                                        [(ngModel)]="productvariationdata[indexOfelement].Color" name="color_map">
                                    <!-- <small>Once submited this fields cannot be changed</small> -->
                                </div>
 
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3 text-right">
                                <label class="add-product-lable">Product Size</label>
                            </div>
                            <div class="col-md-7">
                                <div class="form-group">
                                    <input type="text" class="product-form-control" type="text"
                                        [(ngModel)]="productvariationdata[indexOfelement].Size" name="size">
                                </div>
                            </div>
                        </div>
 
 
                        <div class="channel-applications-table-row">
                            <div class="channel-name">
                                <h6>Upload product Images</h6>
                            </div>
                            <div class="row">
                                <!-- <div class="image-upload-row"> -->
                                <div class="col-md-3">
                                    <div class="image-upload-box">
                                        <input type="file" id="pt08{{indexOfelement}}" (change)="selectFile($event,'main', indexOfelement)" />
                                        <label class="upload-imgbox" for="pt08{{indexOfelement}}">
                                            <img src={{data.ProductImage}}
                                                onerror="this.onerror==null; this.src='../../../../../assets/images/dashboard/upload.svg'"
                                                alt="" />
 
                                        </label>
                                    </div>
                                    <span class="img-bottom-heading">Image 01</span>
                                </div>
                                <div class="col-md-3">
                                    <div class="image-upload-box">
                                        <input type="file" id="pt09{{indexOfelement}}" (change)="selectFile($event,'other1', indexOfelement)" />
                                        <label class="upload-imgbox" for="pt09{{indexOfelement}}">
                                            <img src={{data.other_image_url}}
                                                onerror="this.onerror==null; this.src='../../../../../assets/images/dashboard/upload.svg'"
                                                alt="" />
 
                                        </label>
                                    </div>
                                    <span class="img-bottom-heading">Image 02</span>
                                </div>
                                <div class="col-md-3">
                                    <div class="image-upload-box">
                                        <input type="file" id="pt10{{indexOfelement}}" (change)="selectFile($event,'other2', indexOfelement)" />
                                        <label class="upload-imgbox" for="pt10{{indexOfelement}}">
                                            <img src={{data.other_image_url1}}
                                                onerror="this.onerror==null; this.src='../../../../../assets/images/dashboard/upload.svg'"
                                                alt="" />
 
                                        </label>
                                    </div>
                                    <span class="img-bottom-heading">Image 03</span>
                                </div>
                                <div class="col-md-3">
                                    <div class="image-upload-box">
                                        <input type="file" id="pt11{{indexOfelement}}" (change)="selectFile($event,'other3', indexOfelement)" />
                                        <label class="upload-imgbox" for="pt11{{indexOfelement}}">
                                            <img src={{data.other_image_url2}}
                                                onerror="this.onerror==null; this.src='../../../../../assets/images/dashboard/upload.svg'"
                                                alt="" />
 
                                        </label>
                                    </div>
                                    <span class="img-bottom-heading">Image 04</span>
                                </div>
                                <!-- </div> -->
                            </div>
                        </div>
                    </div>
                </div>
 
                <div class="submit-row">
                    <div class="product-feed-action">
                    <button type="button" class="btn-action-link" value="Submit" (click)="addvariantionbox()">Add Variation Box</button>
                    </div>
                    <div class="product-feed-action">
                        <form #productForm="ngForm" (ngSubmit)="onSubmit(productForm)" id="productForm"
                            class="needs-validation product-add" novalidate="">
                            <router-outlet (activate)="onActivate($event)"></router-outlet>
                            <button type="submit" class="btn-action-link" value="Submit">Submit</button>
                        </form>
                    </div>
 
                    <div class="alert" *ngIf="error">
                        <span class="closebtn" onclick="this.parentElement.style.display='none';">&times;</span>
                        <strong>Error!</strong> {{errortext}}
                    </div>
 
                    <div class="response" *ngIf="success">
                        <span class="closebtn" onclick="this.parentElement.style.display='none';">&times;</span>
                        <strong>Success!</strong> {{successtext}}
                    </div>
 
                </div>
 
 
 
            </div>
        </div>
    </div>
</div>
<div *ngIf="productvariationstatuspopup" class="loginbox">
    <div class="paste-source">
        <div class="paste-source-box">
            <h4>Product varitation has been updated successfully</h4>
            <div class="save-btn">
                <div class="featured-action">
                    <button type="button" class="save-upload-cli-primary-btn" (click)="onproductvariationstatuspopupsucess()">
                        Ok
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>


