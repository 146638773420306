import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../shared/service/api.service';
import { ProductimageService } from '../../../shared/service/fileupload.service';
import { UserModel, Accounts } from 'src/app/shared/models/datamodel';
import { ActivatedRoute, Router } from "@angular/router";


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  sellerdata: any;
  admindata: any;
  public userdata= new UserModel()
  selectedFiles: any;
  FileUploadStatus: boolean;
  currentFileUpload: any;
  token: any;
  error: boolean;
  success: boolean;
  successtext: string;
  errortext: string;

  constructor(private apiService: ApiService, private router: Router, private imageuploadService: ProductimageService) { 
    this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"))
    this.admindata = JSON.parse(window.localStorage.getItem("admin-info"))
    this.token = JSON.parse(window.localStorage.getItem("TOKEN"))
  }

  ngOnInit() {
    this.getuserdetails()
   }

  getuserdetails(){
    const payload = {
      token: this.token,
      email: this.sellerdata[0].email

    }
    const userid = this.sellerdata[0]._id;

    this.apiService.getuserdetails(userid, payload).subscribe((res:UserModel)=>{
      this.userdata = res
      console.log(this.userdata)
      localStorage.setItem("seller-info", JSON.stringify([this.userdata]))
    })
    

  }

  selectFile(event, type) {
    this.selectedFiles = event.target.files;
    this.upload(type);
  }

  upload(type) {
    this.FileUploadStatus = true;
    this.currentFileUpload = this.selectedFiles.item(0);
    this.imageuploadService.pushFileToStorageforGST(this.currentFileUpload).subscribe(res => {
      this.FileUploadStatus = false;
      if (type == 'profile') {
        this.userdata.gst_file_link = res.toString();
        console.log(this.userdata)
      }
      else if(type == 'main'){
        this.userdata.profileimage = res.toString();
      }
    });
  }

  profileupdate(){
    this.userdata.token = this.token;
    this.apiService.updateuser(this.userdata._id, this.userdata).subscribe((res)=>{
      this.getuserdetails()
      this.successtext = "Profile has been updated successfully!"
          this.success = true
          this.error = false
    })
  }

  close() {
    this.error = false;
    this.success = false;
  }

}
