import { Injectable } from '@angular/core';
import { WebService } from './web.service';
import { ProductimageModel } from '../models/datamodel';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class ProductimageService {

  constructor(private webService: WebService, private http: HttpClient) { }

  createproductimage(productimagemodel: ProductimageModel) {
    return this.webService.post('products/create-productimage', productimagemodel);
  }

  deleteproductimage(productimagemodel: ProductimageModel) {
    return this.webService.delete(`products/${productimagemodel._id}`);

  }
  getproductimage() {
    return this.webService.get('productimage')
  }

  pushFileToStorage(file: File) {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    return this.webService.postImage('jesssu/products/upload-productimage', formdata);
  }


  pushFileToStorageforGST(file: File) {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    return this.webService.postImage('jesssu/products/upload-filesellergst', formdata);
  }
  pushFileToStorage2(file: File) {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    return this.webService.postImage('jesssu/products/upload-manifest', formdata);

  }

  pushFileToStorageforSellerFiles(file: File) {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    return this.webService.postImage('jesssu/products/upload-filesellerend', formdata);
  }

  ImportProduct(file: File, email: String, token: string) {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    return this.webService.postImage(`jesssu/products/upload-productdata/${email}/${token}`, formdata);
  }

  updatebrandimage(payload) {
    return this.webService.post(`brand/updatesinglebrandimage`, payload)
  }

  updatecategoryimage(payload) {
    return this.webService.post(`category/updatesinglecategoryimage`, payload)
  }

  updatesubcategoryimage(payload) {
    return this.webService.post(`category/update/subcategoriesimage`, payload)
  }

  pushFileToStorage4(file: File) {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    return this.webService.postImage("category/upload-categoryimage", formdata);
  }
}
