import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { UserModel } from 'src/app/shared/models/datamodel';
import { CustomCookieService } from 'src/app/shared/service/cookie.service';

@Component({
  selector: 'app-startpage',
  templateUrl: './startpage.component.html',
  styleUrls: ['./startpage.component.scss']
})
export class StartpageComponent implements OnInit {
  sellerdata: any;
  admindata: any;
  applestore: boolean = false;
  public credentialforlogin = new UserModel();
  public credentialforsignup = new UserModel();
  public selectedUserData = []

  constructor(private router: Router, public cookieService: CustomCookieService) {
    this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"))
    this.admindata = JSON.parse(window.localStorage.getItem("admin-info"))
    const UserDetails: any = JSON.parse(this.cookieService.getCookie('UserDetails'));
    this.selectedUserData.push(UserDetails)
    const SubscriptionData = JSON.parse(this.cookieService.getCookie('SubscriptionData'));
    const Subscription = JSON.parse( this.cookieService.getCookie('Subscription'));
    if (UserDetails && SubscriptionData && Subscription) {
      localStorage.setItem("seller-info", JSON.stringify(this.selectedUserData));
      localStorage.setItem("TOKEN", JSON.stringify(UserDetails?.token));
      localStorage.setItem("ACCOUNTSTATUS", JSON.stringify(UserDetails?.accountstatus));
      localStorage.setItem("USERDETAILS", JSON.stringify(UserDetails));
      localStorage.setItem("SUBSCRIPTION", JSON.stringify(Subscription))
      localStorage.setItem("SUBSCRIPTIONPLAN", JSON.stringify(SubscriptionData?.plan))
      this.router.navigateByUrl("/dashboard/default")
    }
  }

  ngOnInit(): void {

  }

  redirectedtologin() {
    this.router.navigateByUrl("/auth/login")
  }
  redirecttoplan() {
    this.router.navigateByUrl("/subscription-selection")
  }
  gotoplaystore(){
    window.open("https://play.google.com/store/apps/details?id=com.seller.jesssu")
  }
  gotoapplestore(){
    this.applestore = true
  }
  closeapplestorepopup(){
    this.applestore = false
  }

  Shownav = true;  
    
  toggglebtn() {  
    this.Shownav = !this.Shownav;  
  }  

}
